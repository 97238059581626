import React, { useState, useEffect } from "react";
import vector from '../../assets/setup-icon/Vector.png';
import Multiselect from "multiselect-react-dropdown";
import { Form, Button } from "react-bootstrap";
import "./setup.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssetLibraryCategoryList } from "../../redux/actions/actions";
import { addFaqBenefitData } from "../../redux/actions/setupActions";

const CategoryScreen = (props) => {
    const dispatch = useDispatch();
    const authToken = props.authToken;
    const category_all = useSelector((state) => state.assetLibrary.categoryList);
    const selected_value = useSelector((state) => state.setupScreen.selected_value);

    const [arrayData, setArrayData] = useState([
      { title: '', description: '', status: "1" }
    ]);
    const [renderForm, setRenderForm] = useState(false);
    const [dropdownData, setDropdownData] = useState({});
    const [placeHolder, setPlaceHolder] = useState({});
    const [selectValues, setSelectValues] = useState(null);

    const onSelectCategory = (selectedList, selectedItem) => {
      setSelectValues(selectedItem.id);
    };

    const onRemoveCategory = (selectedList, removedItem) => {
      setSelectValues(null);
    };

    const saveData = async () => {
      const payload = arrayData.map((item, index) => {
        item.cat_id = selectValues
        return item;
      })
      switch (props.subPageName) {
        case "addBenefit":
          dispatch(addFaqBenefitData(authToken, "benefit", selectValues, payload));
          break;
        case "addFaq":
          dispatch(addFaqBenefitData(authToken, "faq", selectValues, payload));
          break;
        default:
          console.log('End');
          break;
      }
      setRenderForm(true);
    };

    const handleRemoveData = (r_id) => {
      setArrayData((prevItems) => prevItems.filter((item,index) => index != r_id));
    }
  
    const handleChangeInput = (index, e) => {
      const { name, value } = e.target;
      const updatedFields = arrayData.map((field, i) =>
        i === index ? { ...field, [name]: value } : field
      );
      setArrayData(updatedFields);
    };
    
    useEffect(() => {
      dispatch(fetchAssetLibraryCategoryList(authToken));
    }, [])
    
  
    useEffect(() => {
      if (props.subPageName === "addBenefit") {
        setPlaceHolder({ name: "Title", type: "Select Category" });
        setDropdownData({ data: category_all, display: "solution_type" });
      } else {
        setPlaceHolder({ name: "Question", type: "Select Category" });
        setDropdownData({ data: category_all, display: "solution_type" });
      }
      setRenderForm(true);
    }, [authToken, props.subPageName, category_all]);

    const addMoreFields = () => {
      setArrayData([...arrayData, { title: '', description: '', status: "1" }]);
    };

    return (
      <div className="single-screen single-width-adjust">
        <span onClick={() => { window.history.back() }}>
          <img className="rotate-left" src={vector} alt="icon" />
          Back
        </span>
        <h2>{props.innerTitle}</h2>
        {renderForm && (
          <Form>
            {(props.subPageName === "addBenefit" || props.subPageName === "addFaq") && (
              <Multiselect
                className="drop-down mb-3"
                options={dropdownData?.data}
                selectedValues={selected_value}
                onSelect={onSelectCategory}
                onRemove={onRemoveCategory}
                singleSelect
                displayValue={dropdownData?.display}
                placeholder={placeHolder?.type}
              />
            )}
            {arrayData?.map((item, index) => (
              <div key={index}>
                <Form.Control
                  className="add-name my-3"
                  value={item.title}
                  type="text"
                  placeholder={placeHolder.name}
                  name="title"
                  onChange={(e) => handleChangeInput(index, e)}
                />
                <Form.Group>
                  <Form.Control
                    className="add-name mt-3"
                    rows={3}
                    value={item.description}
                    as="textarea"
                    name="description"
                    placeholder="Description"
                    onChange={(e) => handleChangeInput(index, e)}
                  />
                </Form.Group>
                <div className="mb-3 mt-2 d-flex justify-content-end">{arrayData.length > 1 && <span onClick={()=>{handleRemoveData(index)}} className="text-danger">Remove</span>}</div>
              </div>
            ))}
            <div className="d-flex gap-3">
              <Button
                variant="primary"
                className="add-more-button"
                size="lg"
                onClick={addMoreFields}
              >
                + Add More
              </Button>
              <Button
                variant="primary"
                className="save-button"
                size="lg"
                onClick={() => { saveData() }}
              >
                Save Changes
              </Button>
            </div>
          </Form>
        )}
      </div>
    );
};

export default CategoryScreen;
