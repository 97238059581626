/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Stack, Form, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { appUserActivity, addCommentForActivity, taskManager } from "../../../../../../redux/actions/crmActions";
import "./Leadtabs.css";
import { useParams } from 'react-router';
import moment from "moment";
import * as Icon from "react-bootstrap-icons";


const Activity = (props) => {

  const { id } = useParams();
  const { authToken } = props;
  const { contactId } = props

  const [commentBox, setCommentBox] = useState(false);
  const [enableCBox, setEnableCBox] = useState(0);
  const [comment, setComment] = useState("");

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.crmLeads.userActivityDetails);
  useEffect(() => {
    let idd = id !== undefined ? id : contactId.contact_ids[0].contact_id;
   // dispatch(appUserActivity(authToken, idd));
    return () => {
      // console.log("activity cleanup", props);      
    };
  }, []);


  const handleChekbox = (item) => {
    let idd = id ? id : contactId.contact_ids.contact_id;
    let obj = {
      action: "mark_as_completed",
      id: item.id
    }
    dispatch(taskManager(authToken, obj, 'mark', idd));
  }

  const getDate = (date) => {
    if (date !== "" && date !== null && date !== "0000-00-00 00:00:00") {
      let d = moment(date).format('DD');
      return d;
    } else {
      return "";
    }
  }

  const getMonth = (date) => {
    if (date !== "" && date !== null && date !== "0000-00-00 00:00:00") {
      let m = moment(date).format('MMM');
      return m.toUpperCase();
    } else {
      return "";
    }
  }

  const submitComment = (e, item) => {
    e.preventDefault();
    console.log("comment", item);
    //contact_id, id, activity_type.toLowerCase(), comment to the api request
    dispatch(addCommentForActivity(authToken, +item.contact_id, item.id, item.activity_type.toLowerCase(), comment));
    setComment("");
    setCommentBox(false);
  }

  const openCommentBox = (val) => {
    if (commentBox === true && enableCBox === val) {
      setEnableCBox(0);
      setCommentBox(false);
    } else {
      setEnableCBox(val);
      setCommentBox(true);
    }
  }

  return (
    <div className="offcanvas-body-background-child">
      {userDetails && (
        <div className="activites">
          {userDetails.response?.activity.length > 0 ?
            userDetails.response.activity.map((item, i) => {
              return (
                <Row className="m-2 p-4" key={i}>
                  <Col sm lg={2} className="p-1">
                    <div className="fs-6 fw-bold">{getDate(item.activity_date)} <br /> {getMonth(item.activity_date)}</div>
                  </Col>
                  <Col sm lg={10} style={{ backgroundColor: '#fff' }}>
                    <Row className="p-2 lh-base">
                      <Col sm md={8}>
                        {item.activity_type == 'Task' ?
                          (<div className="act-title"><Icon.ClipboardCheck />&nbsp;<strong>Task Assigned</strong>&nbsp;</div>)
                          : item.activity_type == 'Notes' ?
                            (<div className="act-title"><Icon.Sticky />&nbsp;<strong>Notes Added</strong>&nbsp;</div>)
                            : item.activity_type == 'activity' ?
                              (<div className="act-title"><Icon.Calendar2Check />&nbsp;<strong>Activity</strong>&nbsp;</div>)
                              :
                              item.activity_type == 'Call Log' ?
                                (<div className="act-title"><Icon.Telephone />&nbsp;<strong>Call Log</strong>&nbsp;</div>)
                                :
                                ""}
                        {(item.activity_type == 'Task' || item.activity_type == 'Notes' || item.activity_type == 'Call Log') && (
                          <div className="fw-bold act-title">{item.task_title}</div>
                        )}
                        {item.activity_type == 'activity' && (
                          <>
                            <div className="fw-bold act-title">{item.activity}</div>
                            <div className="mt-2 act-subhead mt-2">CONTENT NAME</div>
                            <div className="mt-2 act-notes text-decoration-underline mt-2">{item.title}</div>
                          </>
                        )}
                        {(item.activity_type == 'Task' || item.activity_type == 'Call Log') && (
                          <>
                            <div className="mt-2 act-subhead">DUE DATE</div>
                            {item.task_due_date !== "0000-00-00 00:00:00" && item.task_due_date !== ""
                              ?
                              <div className="fw-bolder act-datetime mt-1">{moment(item.task_due_date).format("hh:mm A DD MMM yyyy")}</div>
                              :
                              ""
                            }
                          </>
                        )}
                        {item.activity_type == 'Call Log' && (
                          <>
                            <div className="mt-2 act-subhead">CALL OUTCOME</div>
                            {item.calloutcome_name !== "" ? <div className="mt-2 act-notes">&quot;{item.calloutcome_name}&quot;</div> : ""}
                          </>
                        )}
                        {(item.activity_type == 'Task' || item.activity_type == 'Notes') && (
                          <>
                            {item.task_notes !== "" ? <div className="mt-3 act-notes">&quot;{item.task_notes}&quot;</div> : ""}
                          </>
                        )}
                      </Col>
                      {(item.activity_type == 'Task' || item.activity_type == 'Call Log') && (
                        <Col sm md={4} className="me-auto">
                          {item.task_status !== "Completed" && (
                            <Form.Check
                              className="mt-3 me-auto"
                              type="checkbox"
                              label="Mark as Done"
                              id="activity_mark_as_doen"
                              onChange={() => handleChekbox(item)}
                            />
                          )}
                          {item.task_status == "Completed" && (
                            <div style={{ backgroundColor: '#ecf6f8', width: 'auto' }} className="text-color  fs-6 p-1"> Completed </div>
                          )}
                        </Col>
                      )}
                    </Row>
                    {item.activity_type == 'Task' && (
                      <>
                        <Icon.ChatSquare className="d-flex ms-auto mb-1 show-pointer" style={{ color: '#F6971A' }} onClick={(e) => openCommentBox(i + 1)} />
                        {
                          enableCBox === i + 1 && commentBox === true && (
                            <Form onSubmit={(e) => submitComment(e, item)}>
                              <Form.Control name="comment" className="mb-2" required type="text" placeholder="Enter your comment" onChange={(e) => setComment(e.target.value)}></Form.Control>
                            </Form>
                          )
                        }
                      </>
                    )}
                  </Col>
                </Row>
              )
            })
            :
            <div className="no-data mt-5">
              <span>
                <Icon.Inbox />
              </span>
              <br />
              No Data
            </div>
          }
        </div >
      )}

    </div >
  )
}

export default Activity;