/* eslint-disable */
import { useContext, useEffect } from "react";
import ArcLoading from "../../ArkLoading";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthContext from "../../../context/AuthProvider";
let md5 = require("md5");
const ArkLogin = () => {
    const authCtx = useContext(AuthContext);
    const ARC_LOGIN_URL = authCtx.arcLoginUrl;
    const LOGIN_URL = authCtx.loginUrl;
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const navigate = useNavigate();
    
    useEffect(() => {
        if (code) {
            handleVerifyArkLogin(code);
        }
    }, [code])
    
    const handleVerifyArkLogin = async (code_inside) => {
        try {
            const response = await axios.post(ARC_LOGIN_URL, {
                code : code_inside,
            });
            if (response?.data?.statusCode === "200") {
                authCtx.onLogin(response?.data);
                navigate("/AssetLibrary");
            } else if (response?.data?.statusCode === "403") {
                console.log("Failure");
            }
        } catch (err) {
            console.log("Failure");
        }
    }
    
    return (
        <>
            <ArcLoading />
        </>
    );
};

export default ArkLogin;
