/* eslint-disable */
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
  Modal,
  InputGroup,
  FormGroup,
  FormCheck,
} from "react-bootstrap";

import "./AllAssetsList.css";
import QuickFilters from "./QuickFilters";
import * as Icon from "react-bootstrap-icons";
import CardViewContainer from "./CardViewContainer";
import ListViewContainer from "./ListViewContainer";
import { useState } from "react";
import Pagination from "../../../Pagination";
import black_reorder from '../../../../assets/black_reorder.png'
import blue_reorder from '../../../../assets/blue_reorder.png'
import _ from "lodash";
import {
  // FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import Editor from "../../../shared/editor";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  setAssetLibraryCategoryFilter,
  setAssetLibraryStatusFilter,
  setAssetLibrarySortByFilter,
  fetchAssetLibraryContentList,
  deleteBulkContent,
  addAssetToFolder,
  fetchAssetLibraryFolderList,
  globalFilterAssetLibraryContentList,
  fetchContent,
  fetchAssetSelectTypeList,
  setPublishType,
  setSelectedAssetType,
} from "../../../../redux/actions/actions";
import {
  fetchEmailTemplates,
  shareViaEmail,
  getEmailShortLink,
  getFBShortLink,
  getTWShortLink,
  getLNShortLink,
  getWHShortLink,
  shareViaSalesForceEmail
} from "../../../../redux/actions/crmActions";

import ShareAssetCard from "./ShareAssetCard";
import Toast from "../../../shared/Toast";
import Multiselect from "multiselect-react-dropdown";

const AllAssetsList = (props) => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const categoryList = useSelector((state) => state.assetLibrary.categoryList);
  const folderDropdown = useSelector(
    (state) => state.assetLibrary.folderDropdown
  );

  const [cardView, setCardView] = useState(true);
  const [cardListView, setCardListView] = useState(false);
  const [categoryOption, setCategoryOption] = useState("");
  const [productOption, setProductOption] = useState("");
  const [statusOption, setStatusOption] = useState("");
  const [sortByOption, setSortByOption] = useState("");

  const [checked, setChecked] = useState(0);
  const [contentList, setContentList] = useState({});
  const [folderId, setFolderId] = useState(null);

  const [show, setShow] = useState(false);

  const [shareShow, setShareShow] = useState(false);
  const [shareAsset, setShareAsset] = useState([]);
  const [emailOption, setEmailOption] = useState(false);
  const [initialEmailBody, setInitialEmailBody] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [subject, setSubject] = useState("");

  const [emailLink, setEmailLink] = useState("");
  const [fbLink, setFbLink] = useState("");
  const [twLink, setTwLink] = useState("");
  const [lnLink, setLnLink] = useState("");
  const [whLink, setWhLink] = useState("");
  const [pageCount, setPageCount] = useState(0);

  const [shortLink, setShortLink] = useState("");
  const [shareLink, setShareLink] = useState("");

  const [contactListNew, setContactList] = useState([]);
  const [selectedContact, setSelectedContact] = useState([]);
  const [deleteShow, setDeleteShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [reorderList, setReorderList] = useState(false);

  const assetContentList = useSelector(
    (state) => state.assetLibrary.contentList
  );
  
  const productListData = useSelector(
    (state) => state.assetLibrary.productListData
  );

  const globalSearchKey = useSelector(
    (state) => state.assetLibrary.globalSearchKey
  );
  const qFilterKey = useSelector((state) => state.assetLibrary.qFilterKey);
  const pFilterKey = useSelector((state) => state.assetLibrary.pFilterKey);
  const cFilterKey = useSelector((state) => state.assetLibrary.cFilterKey);
  const sFilterKey = useSelector((state) => state.assetLibrary.sFilterKey);
  const svFilterKey = useSelector((state) => state.assetLibrary.svFilterKey);
  const talic_active = useSelector((state) => state.assetLibrary.talic_active);
  const currFolderId = useSelector((state) => state.assetLibrary.folderId);
  const currFolderType = useSelector((state) => state.assetLibrary.folderType);
  const currFolderName = useSelector((state) => state.assetLibrary.folderName);

  const assetSelectTypeList = useSelector(
    (state) => state.assetUpload.selectTypeList
  );

  const contactList = useSelector((state) => state.crmLeads.contactList);
  const shareAssetDetails = useSelector((state) => state.crmLeads.shareAssetDetails);

  const emailShortLink = useSelector((state) => state.crmLeads.emailShortLink);
  const fbShortLink = useSelector((state) => state.crmLeads.fbShortLink);
  const twShortLink = useSelector((state) => state.crmLeads.twShortLink);
  const lnShortLink = useSelector((state) => state.crmLeads.lnShortLink);
  const whShortLink = useSelector((state) => state.crmLeads.whShortLink);


  const cardViewHandler = () => {
    setCardView(true);
    setCardListView(false);
    setReorderList(false);
  };

  const cardListViewHandler = () => {
    setCardListView(true);
    setCardView(false);
    setReorderList(false);
    setSelectedOption("20");
    dispatch(globalFilterAssetLibraryContentList( props.authToken, globalSearchKey, pFilterKey, qFilterKey, null, sFilterKey, svFilterKey, currFolderId, currFolderType, currFolderName, 0, "20"));
  };
  
  const pageCounter = (num) => {
    setPageCount(num);
  };
  
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    dispatch(globalFilterAssetLibraryContentList( props.authToken, globalSearchKey, pFilterKey, qFilterKey, null, sFilterKey, svFilterKey, currFolderId, currFolderType, currFolderName, 0, event.target.value));
  };
  const deleteHandleClose = () => {
    setDeleteShow(false);
  };
  const deleteHandleShow = () => setDeleteShow(true);

  // Add/Remove checked item from list
  const handleCheck = (e, id, type) => {
    if (e === null) {
      setChecked(0);
    } else {
      let _updatedContent = _.clone(contentList);
      let count = checked;
      if (e.target.checked) {
        if (_.has(contentList, [type])) {
          if (!_updatedContent[type].includes(id)) {
            _updatedContent[type].push(id);
          }
        } else {
          _updatedContent[type] = [id];
        }
        setChecked(count + 1);
      } else {
        if (_.has(contentList, [type])) {
          _updatedContent[type] = _updatedContent[type].filter(
            (item) => item !== id
          );
          if (_updatedContent[type].length === 0) {
            _updatedContent = _.omit(_updatedContent, [type]);
          }
        }
        setChecked(count - 1);
      }
      setContentList(_updatedContent);
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  
  const reorderingMicrosite = (value) => {
    setReorderList(value)
    if (value === true) {
      setCardListView(true);
      setCardView(false);
    } else {
      setSelectedOption("20");
      dispatch(globalFilterAssetLibraryContentList( props.authToken, globalSearchKey, pFilterKey, qFilterKey, null, sFilterKey, svFilterKey, currFolderId, currFolderType, currFolderName, 0, "20"));
    }
  }

  const shareHandleClose = () => {
    setShareShow(false);
    setShareAsset([]);
    setEmailOption(false);
    setSelectedContact({});
    setSubject("");
  };
  const shareHandleShow = (id, type) => {
    const filterObj = assetContentList.filter((e) => (e.content_id == id && e.content_type == type));
    setShareAsset(filterObj);
    setShareShow(true);
  };

  //console.log("assetContentList",assetContentList);

  const asHandler = () => {
    let assetTypeId;
    let assetType;
    if (Object.keys(contentList).length > 1) {
      Toast("Please select similar Asset Type..", "E");
    } else {
      assetType = Object.keys(contentList)[0];
      let typeList = assetSelectTypeList.map((s) => {
        if (s.type == Object.keys(contentList)[0]) {
          assetTypeId = s.id;
        }
        if (
          s.type == "Brochure" &&
          (Object.keys(contentList)[0] == "Brochure" ||
            Object.keys(contentList)[0] == "Document")
        ) {
          assetTypeId = s.id;
        }
        if (
          (s.type == "Social Post" || s.type == "Image") &&
          (Object.keys(contentList)[0] == "Social" ||
            Object.keys(contentList)[0] == "Creative")
        ) {
          assetTypeId = s.id;
        }
        if (
          s.type == "Banner" &&
          Object.keys(contentList)[0] == "Banner"
        ) {
          assetTypeId = s.id;
        }
      });

      // if (contentList[assetType].length > 1) {
      //   dispatch(setPublishType([1,2]))
      // } else {
      //   dispatch(setPublishType([1]))
      // }
      dispatch(setPublishType(true));
      dispatch(
        setSelectedAssetType({
          id: assetTypeId,
          article_type: Object.keys(contentList)[0],
          type: Object.keys(contentList)[0],
        })
      );
      dispatch(fetchContent(props.authToken, contentList));
      setTimeout(() => {
        navigate("/new-asset/publish-asset");
      }, 1000);
    }

    //console.log("assetTypeId",assetTypeId);
    //console.log("assetType",assetType);
    //console.log("contentList",contentList[assetType]);
  };

  let newContactList;

  const onSelectContact = (selectedList, selectedItem) => {
    setSelectedContact(selectedList);
  };

  const onRemoveContact = (selectedList, removedItem) => {
    setSelectedContact(selectedList);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log("window.location.href",window.location.href);
    // alert(window.location.href);

    let selectContactList = selectedContact.map((c) => {
      return c.value;
    });
    let emailData = {
      "action": "tracking_link",
      "app_footer_message": emailShortLink,
      "app_message": emailBody,
      "attachment_file": [],
      "attachment_link": "1",
      "button": "Send Now",
      "camp_id": "",
      "colorRadio": "mail",
      "etype": "direct",
      "from_name": "",
      "list": selectContactList,
      "lpage_id": "",
      "mailsubject": subject,
      "scheduleCheckbox": "0",
      "schedule_events": "",
      "test_mail": ""
    }
    //dispatch(shareViaEmail(props.authToken, emailData));
    if(window.name) {
      dispatch(shareViaSalesForceEmail(props.authToken, emailData));
    } else {
      dispatch(shareViaEmail(props.authToken, emailData));
    }

    shareHandleClose();
    // console.log("selectContactList",selectContactList);
    // console.log("emailBody",emailBody);
    // console.log("emailData", emailData); 
  }

  useEffect(() => {
    if (cardListView === true) {
      setReorderList(true);
    }
    dispatch(fetchAssetSelectTypeList(props.authToken));
    if (props.userType === "C") {
      dispatch(shareViaEmail(props.authToken));
    }
  }, []);


  useEffect(()=>{
    cFilterKey === null ? setCategoryOption("null") :  setCategoryOption(cFilterKey);
    pFilterKey === null ? setProductOption("null") :  setProductOption(pFilterKey);
    sFilterKey === null ? setStatusOption("null") : setStatusOption(sFilterKey);
    svFilterKey === null ? setSortByOption("null") : setSortByOption(svFilterKey);
    setFolderId(currFolderId);
  },[cFilterKey, pFilterKey,sFilterKey,svFilterKey,currFolderId]);

  useEffect(() => {

    if (props.userType === "C") {

      if (initialEmailBody == "") {
        setInitialEmailBody(`<strong>Dear -firstName-</strong>`);
      }

      if (contactList) {
        newContactList = contactList.map(
          ({ email: value, email: label }) => ({
            value,
            label,
          })
        );
      }

      setContactList(newContactList);
      let emailLinkNew;
      let fbLinkNew;
      let twLinkNew;
      let lnLinkNew;
      let whLinkNew;

      // if (shareAssetDetails && (shareAssetDetails.link !== "")) {
      //   setShareLink((shareAssetDetails.link).replace(/\s/g, ''));
      //  }

      if (shareAssetDetails && shareAssetDetails.share_track) {
        emailLinkNew = shareAssetDetails.share_track.replace('-channel_type-', 'email');
        fbLinkNew = shareAssetDetails.share_track.replace('-channel_type-', 'facebook');
        twLinkNew = shareAssetDetails.share_track.replace('-channel_type-', 'twitter');
        lnLinkNew = shareAssetDetails.share_track.replace('-channel_type-', 'linkedin');
        whLinkNew = shareAssetDetails.share_track.replace('-channel_type-', 'whatsapp');

        dispatch(getEmailShortLink(`https://web-link.co/api/v2/action/shorten?key=b7a46b6798f1933d14e278073b06af&response_type=json&url=${emailLinkNew}`));
        dispatch(getFBShortLink(`https://web-link.co/api/v2/action/shorten?key=b7a46b6798f1933d14e278073b06af&response_type=json&url=${fbLinkNew}`));
        dispatch(getTWShortLink(`https://web-link.co/api/v2/action/shorten?key=b7a46b6798f1933d14e278073b06af&response_type=json&url=${twLinkNew}`));
        dispatch(getLNShortLink(`https://web-link.co/api/v2/action/shorten?key=b7a46b6798f1933d14e278073b06af&response_type=json&url=${lnLinkNew}`));
        dispatch(getWHShortLink(`https://web-link.co/api/v2/action/shorten?key=b7a46b6798f1933d14e278073b06af&response_type=json&url=${whLinkNew}`));

        // setEmailLink(shareAssetDetails.share_track.replace('-channel_type-','email'));
        // setFbLink(shareAssetDetails.share_track.replace('-channel_type-','facebook'));
        // setTwLink(shareAssetDetails.share_track.replace('-channel_type-','twitter'));
        // setLnLink(shareAssetDetails.share_track.replace('-channel_type-','linkedin'));
        // setWhLink(shareAssetDetails.share_track.replace('-channel_type-','whatsapp'));
        // https://web-link.co/api/v2/action/shorten?key=b7a46b6798f1933d14e278073b06af&response_type=json&url=share_track_url
      } else {
        dispatch(getEmailShortLink(null));
        dispatch(getFBShortLink(null));
        dispatch(getTWShortLink(null));
        dispatch(getLNShortLink(null));
        dispatch(getWHShortLink(null));
      }

    }

    //setShortLink(`https://web-link.co/api/v2/action/shorten?key=b7a46b6798f1933d14e278073b06af&response_type=json&url=${emailLinkNew}`);

  }, [shareAssetDetails]);

  //console.log("appContextId", localStorage.getItem("appContextId"));
  // console.log("qFilterKey?.value",qFilterKey?.value);

  // console.log("shareAsset",shareAsset);
  // console.log("currFolderId",currFolderId);
  // console.log("currFolderType",currFolderType);
  // console.log("assetSelectTypeList",assetSelectTypeList);
  // console.log("assetContentList",assetContentList);
  // console.log("contactList",contactList);
  // console.log("shareAssetDetails", shareAssetDetails);
  // console.log("emailLink",emailLink);
  // console.log("lnShortLink",lnShortLink);
  // console.log("shareLink",shareLink);

  return (
    <Container>
      <Row>
        <Col xs={12} md={2} className="pe-4">
          <Row className="mb-2">
            <Col>
              <h4>{props.listTitle}</h4>
            </Col>
          </Row>
          <Row className="d-none d-md-block">
            <Col>
            <h5 className="quicky">Quick Filter</h5>

              <QuickFilters authToken={props.authToken} />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={10} className="ps-5 padding-left-0 padding-right-0">
          <Row>
            <Col xs={12} md={cardListView === true && (qFilterKey?.value === "Microsite" || qFilterKey?.value === "microsite") ? 8 : 9} className="more-filters my-2">
              <Form.Select
                aria-label="select-category" className="mx-md-1 mb-2"
                onChange={(e) => {
                  setCategoryOption(e.target.value);
                  // dispatch(
                  //   setAssetLibraryCategoryFilter(
                  //     props.authToken,
                  //     e.target.value
                  //   )
                  // );
                  let cValue;
                  if (e.target.value === "null") {
                    cValue = null;
                  } else {
                    cValue = e.target.value;
                  }
                  //console.log("cValue",cValue);
                  dispatch(
                    globalFilterAssetLibraryContentList(
                      props.authToken,
                      globalSearchKey,
                      pFilterKey,
                      qFilterKey,
                      cValue,
                      sFilterKey,
                      svFilterKey,
                      currFolderId,
                      currFolderType,
                      currFolderName
                    )
                  );
                }}
                value={categoryOption}
              >
                <option value="null">All Categories</option>
                {(categoryList && categoryList.length > 0) &&
                  categoryList.map((category) => {
                    return (
                      <>
                        <option value={category.id} key={category.id}>
                          {category.solution_type}
                        </option>
                      </>
                    );
                  })}
              </Form.Select>
              
              {talic_active === "tal" ? <Form.Select
                aria-label="select-product" className="mx-md-1 mb-2"
                onChange={(e) => {
                  setProductOption(e.target.value);
                  // dispatch(
                  //   setAssetLibraryCategoryFilter(
                  //     props.authToken,
                  //     e.target.value
                  //   )
                  // );
                  let pValue;
                  if (e.target.value === "null") {
                    pValue = null;
                  } else {
                    pValue = e.target.value;
                  }
                  //console.log("cValue",cValue);
                  dispatch(
                    globalFilterAssetLibraryContentList(
                      props.authToken,
                      globalSearchKey,
                      pValue,
                      qFilterKey,
                      cFilterKey,
                      sFilterKey,
                      svFilterKey,
                      currFolderId,
                      currFolderType,
                      currFolderName
                    )
                  );
                }}
                value={productOption}
              >
                <option value="null">All Products</option>
                {(productListData && productListData.length > 0) &&
                  productListData.map((product) => {
                    return (
                      <>
                        <option value={product.id} key={product.id}>
                          {product.sub_solution_type}
                        </option>
                      </>
                    );
                  })}
              </Form.Select> : ""}
              
              {props.userType === "C" ? (
                ""
              ) : (
                <Form.Select
                  aria-label="select-status"
                  onChange={(e) => {
                    setStatusOption(e.target.value);
                    // dispatch(
                    //   setAssetLibraryStatusFilter(props.authToken, e.target.value)
                    // );
                    let sValue;
                    if (e.target.value === "null") {
                      sValue = null;
                    } else {
                      sValue = e.target.value;
                    }
                    dispatch(
                      globalFilterAssetLibraryContentList(
                        props.authToken,
                        globalSearchKey,
                        pFilterKey,
                        qFilterKey,
                        cFilterKey,
                        sValue,
                        svFilterKey,
                        currFolderId,
                        currFolderType,
                        currFolderName
                      )
                    );
                  }}
                  value={statusOption}
                >
                  <option value="null">Draft & Published</option>
                  <option value={0}>Draft</option>
                  <option value={1}>Published</option>
                </Form.Select>
              )}
              {/* <Button variant="outline-info">
                Add More Filters
                <span className="add-more-filter">
                  <Icon.Plus />
                </span>
              </Button> */}
            </Col>
            <Col
              xs={12}
              md={2}
              className="sort-by my-2 d-none d-md-block"
              style={{ textAlign: "right" }}
            >
              <span className="title">Sort by</span>
              <Form.Select
                aria-label="select-sortby"
                onChange={(e) => {
                  setSortByOption(e.target.value);
                  // dispatch(
                  //   setAssetLibrarySortByFilter(props.authToken, e.target.value)
                  // );
                  let soValue;
                  if (e.target.value === "null") {
                    soValue = null;
                  } else {
                    soValue = e.target.value;
                  }
                  dispatch(
                    globalFilterAssetLibraryContentList(
                      props.authToken,
                      globalSearchKey,
                      pFilterKey,
                      qFilterKey,
                      cFilterKey,
                      sFilterKey,
                      soValue,
                      currFolderId,
                      currFolderType,
                      currFolderName
                    )
                  );
                }}
                value={sortByOption}
              >
                {/* <option value="null">Any</option>
                <option value="created_at">Date</option>
                <option value="title">Title</option> */}

                <option value="null">Any</option>
                <option value="1">Date (ASC)</option>
                <option value="2">Date (DESC)</option>
                <option value="3">Title (ASC)</option>
                <option value="4">Title (DESC)</option>
              </Form.Select>
            </Col>
            <Col className="my-2 d-none d-md-block" xs={12} md={cardListView === true && (qFilterKey?.value === "Microsite" || qFilterKey?.value === "microsite") ? 2 : 1 } style={{ textAlign: "right" }}>
              <ButtonGroup aria-label="Basic example" className="mx-lg-0 mx-3">
                {(cardListView === true && (qFilterKey?.value === "Microsite" || qFilterKey?.value === "microsite")) && <Button
                  className={"toggle-btn  " + (reorderList ? "active": "")}
                  variant="outline-secondary"
                  onClick={() => {
                    reorderingMicrosite(!reorderList)
                    dispatch(
                      globalFilterAssetLibraryContentList(
                        props.authToken,
                        globalSearchKey,
                        pFilterKey,
                        qFilterKey,
                        cFilterKey,
                        "1",
                        svFilterKey,
                        currFolderId,
                        currFolderType,
                        currFolderName
                      )
                    );
                  }}
                >
                  <span>
                    <img style={{width:"18px"}} src={ reorderList === true ? blue_reorder : black_reorder } alt="reorder"/>
                  </span>
                </Button>}
                <Button
                  className={"toggle-btn  " + (cardListView ? "active" : "")}
                  variant="outline-secondary"
                  onClick={cardListViewHandler}
                >
                  <span>
                    <Icon.List />
                  </span>
                </Button>
                <Button
                  className={"toggle-btn " + (cardView ? "active" : "")}
                  variant="outline-secondary"
                  onClick={cardViewHandler}
                >
                  <span>
                    <Icon.Grid />
                  </span>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="d-md-none pe-4 my-2">
            <Col className="px-4">
            <h5 className="quicky ms-1">Quick Filter</h5>

              <QuickFilters authToken={props.authToken} />
            </Col>
          </Row>
          {checked > 0 && (
            <Row>
              <Col>
                <div className="selectedAssetActions">
                  <ul>
                    <li>
                      <Button variant="light selected">
                        {checked} Selected
                      </Button>
                    </li>
                    {((props.userType === "P" || props.userType === "S" || props.userType === "N")) ? <><li>
                      <Button variant="light" onClick={asHandler}>
                        <span>
                          <Icon.Person />
                        </span>
                        Access
                      </Button>
                    </li>
                      <li>
                        <Button variant="light" onClick={asHandler}>
                          <span>
                            <Icon.Clock />
                          </span>
                          Schedule
                        </Button>
                      </li>
                      <li>
                        <Button
                          variant="light"
                          onClick={() => {
                            deleteHandleShow();
                          }}
                        >
                          <span>
                            <Icon.Trash />
                          </span>
                          Delete
                        </Button>
                      </li></> : ""}
                    <Modal show={deleteShow} onHide={deleteHandleClose}>
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body style={{ textAlign: "center" }}>
                        <div className="alert-icon">
                          <Icon.ExclamationTriangle />
                        </div>
                        Are you sure that you want to delete this Asset?
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="info"
                          onClick={() => {
                            dispatch(
                              deleteBulkContent(props.authToken, contentList)
                            );
                            setChecked(0);
                            setTimeout(() => {
                              dispatch(
                                fetchAssetLibraryContentList(props.authToken)
                              );
                            }, 2000);
                            deleteHandleClose();
                          }}
                        >
                          Yes
                        </Button>
                        <Button variant="info" onClick={deleteHandleClose}>
                          No
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    {/* <li>
                      <Button variant="light">
                        <span>
                          <Icon.Files />
                        </span>
                        Duplicate
                      </Button>
                    </li> */}
                    {/* <li>
                      <Button variant="light">
                        <span>
                          <Icon.Pencil />
                        </span>
                        Bulk Edit
                      </Button>
                    </li> */}
                    <li>
                      <Button variant="light" onClick={handleShow}>
                        <span>
                          <Icon.Folder />
                        </span>
                        Add to Folder
                      </Button>
                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Add to Folder</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Select folder into which the content need to be added.
                          <br />
                          <br />
                          <Form.Select
                            aria-label="select-folder"
                            onChange={(e) => {
                              setFolderId(e.target.value);
                            }}
                            value={folderId}
                          >
                            <option value="empty">Select folder here...</option>
                            {folderDropdown &&
                              folderDropdown.map((folder) => {
                                return (
                                  <>
                                    <option value={folder.id}>
                                      {folder.folder_name}
                                    </option>
                                  </>
                                );
                              })}
                          </Form.Select>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="info"
                            onClick={async () => {
                              dispatch(
                                addAssetToFolder(
                                  props.authToken,
                                  folderId,
                                  contentList
                                )
                              );
                              setChecked(0);
                              setContentList({});
                              setTimeout(() => {
                                dispatch(
                                  fetchAssetLibraryFolderList(props.authToken)
                                );
                              }, 2000);
                              handleClose();
                            }}
                          >
                            OK
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </li>
                  </ul>
                  <ul className="float-right">
                    {/* <li>
                      <Button variant="light selected">Select All</Button>
                    </li> */}
                    {/* <li>
                      <Button
                        variant="light selected"
                        onClick={() => {
                          setContentList({});
                          setChecked(0);
                        }}
                      >
                        Unselect
                      </Button>
                    </li> */}
                  </ul>
                </div>
              </Col>
            </Row>
          )}
          {cardView && (
            <CardViewContainer
              authToken={props.authToken}
              userType={props.userType}
              contentList={assetContentList}
              handleCheck={handleCheck}
              assetChecked={checked}
              publishAssetContent={props.publishAssetContent}
              shareHandleShow={shareHandleShow}
              shareAsset={shareAsset}
            />
          )}
          {cardListView && (
            <ListViewContainer
              authToken={props.authToken}
              userType={props.userType}
              handleCheck={handleCheck}
              reorderList={reorderList}
              cardListView={cardListView}
              assetChecked={checked}
              publishAssetContent={props.publishAssetContent}
              shareHandleShow={shareHandleShow}
              pageCount={pageCount}
            />
          )}
          <hr></hr>
          {reorderList !== false && (qFilterKey?.value === "Microsite" || qFilterKey?.value === "microsite") && <div className="show-entry mb-3">
            <span>Show</span>
            <select value={selectedOption} onChange={handleOptionChange}>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="250">250</option>
              <option value="500">500</option>
            </select>
            <span>Entries</span>
          </div>}
          {reorderList !== true && <Pagination authToken={props.authToken} pageCounter={pageCounter} currPage="AllAssetList" />}
          {
            <>
            <Modal
              show={shareShow}
              onHide={shareHandleClose}
              size={emailOption ? "lg" : "md"}
              className="d-none d-md-block"
            >
              <Modal.Header closeButton />
              <Modal.Body>
                <Row>
                  <Col className={emailOption ? "mb-4 col-4" : "mb-4 col-6"}>
                    <ShareAssetCard authToken={props.authToken} content={shareAsset} />
                  </Col>
                  <Col className={emailOption ? "mb-4 col-8" : "mb-4 col-6"}>
                    <br />
                    <h5>Share via {emailOption ? <>Email</> : ""} </h5>
                    {emailOption ? "" : <br />}
                    {emailOption ? (
                      <>
                        <Form className="pt-3"
                          onSubmit={handleSubmit}>
                          {/* <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Email Subject"
                              aria-label="Email Subject"
                              aria-describedby="basic-addon2"
                              //value={subject}
                              //onChange={(e) => setSubject(e.target.value)}
                              readOnly
                            />
                            <Button
                              type="button"
                              className="submit-btn"
                              id="button-addon2"
                              //onClick={(e) => setModal(true)}
                            >
                              Select Template
                            </Button>
                          </InputGroup> */}
                          <div className="smartFolder-multiSelect mb-3">
                            <Multiselect
                              options={contactListNew}
                              displayValue="label"
                              showCheckbox={true}
                              placeholder="To"
                              selectedValues={selectedContact}
                              onSelect={onSelectContact}
                              onRemove={onRemoveContact}
                            />
                          </div>
                          <Form.Control
                            className="mb-3"
                            placeholder="Subject"
                            aria-label="Subject"
                            aria-describedby="basic-addon2"
                            value={subject}
                            required
                            onChange={(e) => setSubject(e.target.value)}
                          />
                          <div
                            className="mb-3"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <Editor
                              initialContent={initialEmailBody}
                              setEmailBody={setEmailBody}
                            />
                          </div>
                          <Row className="mb-3">
                            {/* <Form.Label className="fw-bold">
                              Send Test Mail
                            </Form.Label> */}
                            {/* <Row>
                              <Col>
                                <Form.Control
                                  type="email"setSubject
                                  placeholder="Enter mail id seperate multiple test ids using comma(,)"
                                  name="testmail"
                                  //onChange={(e) => { setTestMails(e.target.value) }}
                                />
                              </Col>
                            </Row> */}
                            <Row style={{ paddingRight: "0" }}>
                              {/* <Col>
                                <br />
                                <Button
                                  variant="primary"
                                  type="button"
                                  className="submit-btn"
                                  //onClick={(e) => handleSubmit('sendTestMail')}
                                >
                                  Send Test Mail
                                </Button>
                              </Col> */}
                              <Col>
                                <br />
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => { setEmailOption(false); setSubject(""); setSelectedContact({}); }}
                                >
                                  Go Back
                                </div>
                              </Col>
                              <Col style={{ textAlign: "right", paddingRight: "0" }}>
                                <br />
                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="submit-btn"
                                >
                                  Send Email
                                </Button>
                              </Col>
                            </Row>
                          </Row>
                        </Form>
                      </>
                    ) : (
                      <>
                        {/* <div className="share-asset-btn">
                          <FacebookShareButton
                            url={"https://udhaybhansharma.sppreprod.in/"}
                            quote={shareAssetDetails ? (`${shareAssetDetails.postdesc} \n ${shareAssetDetails.link}`) : ""}
                          >
                            <FacebookIcon size={42} round />
                          </FacebookShareButton>
                        </div> */}
                        <div className="share-asset-btn">
                          <TwitterShareButton
                            url={shareAssetDetails ? (`${twShortLink} \n`) : ""}
                            title={shareAssetDetails ? (`${shareAssetDetails.postdesc} \n ${shareAssetDetails.link}`) : ""}
                          >
                            <TwitterIcon size={42} round />
                          </TwitterShareButton>
                        </div>
                        <div className="share-asset-btn">
                          <LinkedinShareButton
                            url={shareAssetDetails ? (`${shareAssetDetails.link}`) : ""}
                            //title={shareAssetDetails ? (`\n${shareAssetDetails.postdesc} \n`) : ""}
                            summary={shareAssetDetails ? (`\n ${shareAssetDetails.postdesc} \n ${lnShortLink}`) : ""}
                          >
                            <LinkedinIcon size={42} round />
                          </LinkedinShareButton>
                        </div>
                        <div className="share-asset-btn">
                          <WhatsappShareButton
                            url={shareAssetDetails ? (`${shareAssetDetails.link} \n`) : ""}
                            title={shareAssetDetails ? (`${shareAssetDetails.postdesc} \n ${whShortLink}`) : ""}
                          >
                            <WhatsappIcon size={42} round />
                          </WhatsappShareButton>
                        </div>
                        <div
                          className="share-asset-btn"
                          onClick={() => {
                            setTimeout(() => {
                              setEmailOption(true);
                            }, 2000);
                          }}
                        >
                          {/* <EmailShareButton
                      title={"Landing Page Whatsapp Creative"}
                      url={"https://resources.spdevmfp.com/landingpage/test24356?camp_id=VGxSQk1nPT0&content=whatsapp&channel_type=-channel_type-&pid=VFZSTk1VNTZRVDA9"}
                    >
                      <EmailIcon size={42} round />
                    </EmailShareButton> */}
                          <button
                            class="react-share__ShareButton"
                            style={{
                              backgroundColor: "#999",
                              border: "none",
                              padding: "10px 10px",
                              fontSize: "22px",
                              color: "#fff",
                              cursor: "pointer",
                              lineHeight: "0px",
                              borderRadius: "50px",
                            }}
                          >
                            <Icon.Envelope />
                          </button>
                        </div>{" "}
                      </>
                    )}
                    {/* <ul className="share-options">
                    <li>
                      <div><Icon.Link /></div>
                      <div>Share with a Tracking Link</div>
                      <div><Icon.ChevronRight /></div>
                    </li>
                    <li>
                      <div><Icon.Whatsapp /></div>
                      <div>WhatsApp</div>
                      <div><Icon.ChevronRight /></div>
                    </li>
                    <li>
                      <div><Icon.Envelope /></div>
                      <div>Email</div>
                      <div><Icon.ChevronRight /></div>
                    </li>
                    <li>
                      <div><Icon.Files /></div>
                      <div>Copy Link</div>
                      <div><Icon.ChevronRight /></div>
                    </li>
                  </ul> */}
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
            <Modal
              show={shareShow}
              onHide={shareHandleClose}
              size={emailOption ? "lg" : "md"}
              className="d-md-none"
            >
              <Modal.Header closeButton />
              <Modal.Body>
                <Row>
                  <Col className={emailOption ? "mb-4 col-12" : "mb-4 col-12"}>
                    <ShareAssetCard authToken={props.authToken} content={shareAsset} />
                  </Col>
                  <Col className={emailOption ? "mb-4 col-12" : "mb-4 col-12"}>
                    <br />
                    <h5>Share via {emailOption ? <>Email</> : ""} </h5>
                    {emailOption ? "" : <br />}
                    {emailOption ? (
                      <>
                        <Form className="pt-3"
                          onSubmit={handleSubmit}>
                          {/* <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Email Subject"
                              aria-label="Email Subject"
                              aria-describedby="basic-addon2"
                              //value={subject}
                              //onChange={(e) => setSubject(e.target.value)}
                              readOnly
                            />
                            <Button
                              type="button"
                              className="submit-btn"
                              id="button-addon2"
                              //onClick={(e) => setModal(true)}
                            >
                              Select Template
                            </Button>
                          </InputGroup> */}
                          <div className="smartFolder-multiSelect mb-3">
                            <Multiselect
                              options={contactListNew}
                              displayValue="label"
                              showCheckbox={true}
                              placeholder="To"
                              selectedValues={selectedContact}
                              onSelect={onSelectContact}
                              onRemove={onRemoveContact}
                            />
                          </div>
                          <Form.Control
                            className="mb-3"
                            placeholder="Subject"
                            aria-label="Subject"
                            aria-describedby="basic-addon2"
                            value={subject}
                            required
                            onChange={(e) => setSubject(e.target.value)}
                          />
                          <div
                            className="mb-3"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <Editor
                              initialContent={initialEmailBody}
                              setEmailBody={setEmailBody}
                            />
                          </div>
                          <Row className="mb-3">
                            {/* <Form.Label className="fw-bold">
                              Send Test Mail
                            </Form.Label> */}
                            {/* <Row>
                              <Col>
                                <Form.Control
                                  type="email"setSubject
                                  placeholder="Enter mail id seperate multiple test ids using comma(,)"
                                  name="testmail"
                                  //onChange={(e) => { setTestMails(e.target.value) }}
                                />
                              </Col>
                            </Row> */}
                            <Row style={{ paddingRight: "0" }}>
                              {/* <Col>
                                <br />
                                <Button
                                  variant="primary"
                                  type="button"
                                  className="submit-btn"
                                  //onClick={(e) => handleSubmit('sendTestMail')}
                                >
                                  Send Test Mail
                                </Button>
                              </Col> */}
                              <Col>
                                <br />
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => { setEmailOption(false); setSubject(""); setSelectedContact({}); }}
                                >
                                  Go Back
                                </div>
                              </Col>
                              <Col style={{ textAlign: "right", paddingRight: "0" }}>
                                <br />
                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="submit-btn"
                                >
                                  Send Email
                                </Button>
                              </Col>
                            </Row>
                          </Row>
                        </Form>
                      </>
                    ) : (
                      <>
                        {/* <div className="share-asset-btn">
                          <FacebookShareButton
                            url={"https://udhaybhansharma.sppreprod.in/"}
                            quote={shareAssetDetails ? (`${shareAssetDetails.postdesc} \n ${shareAssetDetails.link}`) : ""}
                          >
                            <FacebookIcon size={42} round />
                          </FacebookShareButton>
                        </div> */}
                        <div className="share-asset-btn">
                          <TwitterShareButton
                            url={shareAssetDetails ? (`${twShortLink} \n`) : ""}
                            title={shareAssetDetails ? (`${shareAssetDetails.postdesc} \n ${shareAssetDetails.link}`) : ""}
                          >
                            <TwitterIcon size={42} round />
                          </TwitterShareButton>
                        </div>
                        <div className="share-asset-btn">
                          <LinkedinShareButton
                            url={shareAssetDetails ? (`${shareAssetDetails.link}`) : ""}
                            //title={shareAssetDetails ? (`\n${shareAssetDetails.postdesc} \n`) : ""}
                            summary={shareAssetDetails ? (`\n ${shareAssetDetails.postdesc} \n ${lnShortLink}`) : ""}
                          >
                            <LinkedinIcon size={42} round />
                          </LinkedinShareButton>
                        </div>
                        <div className="share-asset-btn">
                          <WhatsappShareButton
                            url={shareAssetDetails ? (`${shareAssetDetails.link} \n`) : ""}
                            title={shareAssetDetails ? (`${shareAssetDetails.postdesc} \n ${whShortLink}`) : ""}
                          >
                            <WhatsappIcon size={42} round />
                          </WhatsappShareButton>
                        </div>
                        <div
                          className="share-asset-btn"
                          onClick={() => {
                            setTimeout(() => {
                              setEmailOption(true);
                            }, 2000);
                          }}
                        >
                          {/* <EmailShareButton
                      title={"Landing Page Whatsapp Creative"}
                      url={"https://resources.spdevmfp.com/landingpage/test24356?camp_id=VGxSQk1nPT0&content=whatsapp&channel_type=-channel_type-&pid=VFZSTk1VNTZRVDA9"}
                    >
                      <EmailIcon size={42} round />
                    </EmailShareButton> */}
                          <button
                            class="react-share__ShareButton"
                            style={{
                              backgroundColor: "#999",
                              border: "none",
                              padding: "10px 10px",
                              fontSize: "22px",
                              color: "#fff",
                              cursor: "pointer",
                              lineHeight: "0px",
                              borderRadius: "50px",
                            }}
                          >
                            <Icon.Envelope />
                          </button>
                        </div>{" "}
                      </>
                    )}
                    {/* <ul className="share-options">
                    <li>
                      <div><Icon.Link /></div>
                      <div>Share with a Tracking Link</div>
                      <div><Icon.ChevronRight /></div>
                    </li>
                    <li>
                      <div><Icon.Whatsapp /></div>
                      <div>WhatsApp</div>
                      <div><Icon.ChevronRight /></div>
                    </li>
                    <li>
                      <div><Icon.Envelope /></div>
                      <div>Email</div>
                      <div><Icon.ChevronRight /></div>
                    </li>
                    <li>
                      <div><Icon.Files /></div>
                      <div>Copy Link</div>
                      <div><Icon.ChevronRight /></div>
                    </li>
                  </ul> */}
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
            </>
          }
        </Col>
      </Row>
    </Container>
  );
};

export default AllAssetsList;
