/* eslint-disable */
import { useRef, useState, useEffect } from "react";
import Loading from "../../../Loading";
import { Row, Col, Form, Card, Button, Modal, FormGroup, Image } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import FileInputComponent from "react-file-input-previews-base64";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import Toast from "../../../shared/Toast";
import Multiselect from "multiselect-react-dropdown";
import vImgUrl from "../../../../assets/video-img.png";
import pImgUrl from "../../../../assets/pdf-img.png";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  saveGlobalFieldsData,
  setBreadcrumbStatus,
  addNewHashTag,
  addNewTypePersonalize,
  deleteNewTypePersonalize,
} from "../../../../redux/actions/actions";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import CropImage from "./CropImage";
import { toast } from "react-toastify";

const Base = (props) => {
  const authToken = props.authToken;

  let navigate = useNavigate();

  const nameRef = useRef();
  const longCaptionRef = useRef();
  const shortCaptionRef = useRef();
  const cusRef = useRef();
  const msRef = useRef();
  const descRef = useRef();
  const fileInputRef = useRef(null);
  // const [title, setTitle] = useState("");
  const [platformFB, setPlatformFB] = useState(false);
  const [buyNowCheck, setBuyNowCheck] = useState(false);
  const [platformTW, setPlatformTW] = useState(false);
  const [platformLN, setPlatformLN] = useState(false);
  const [imagefile, setImagefile] = useState(null);
  const [platform, setPlatform] = useState([]);
  const [urlOption, setUrlOption] = useState("L");
  const [lpUrl, setLpUrl] = useState(false);
  const [scUrl, setScUrl] = useState(false);
  const [msUrl, setMsUrl] = useState(false);
  const [formType, setFormType] = useState("");
  const [ctaCheck, setCtaCheck] = useState(false);

  const [cusUrl, setCusUrl] = useState(false);
  const [personalizeType, setPersonalizeType] = useState("");
  const [noneUrl, setNoneUrl] = useState(false);
  const [personalizeModal, setPersonalizeModal] = useState(false);
  const [personalizeDeleteModal, setPersonalizeDeleteModal] = useState(false);
  const [personalizeName, setPersonalizeName] = useState("");
  const [selectedPersonalize, setSelectedPersonalize] = useState(null);
  const [personalizeFileData, setPersonalizeFileData] = useState(null);
  const [personalizeFileName, setPersonalizeFileName] = useState("");
  const [selectedPersonalizeCheck, setSelectedPersonalizeCheck] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const [selectedSubCategoryList, setSelectedSubCategoryList] = useState([]);
  const [selectedHashtagList, setSelectedHashtagList] = useState([]);
  const [selectedCampaignList, setSelectedCampaignList] = useState([]);
  const [selectedGreeting, setSelectedGreeting] = useState(null);
  const [selectedContentType, setSelectedContentType] = useState(null);
  const [selectedBusinessType, setSelectedBusinessType] = useState(null);
  const [selectedVideoType, setSelectedVideoType] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [audioDuration, setAudioDuration] = useState(null);
  const [audioName, setAudioName] = useState("");
  const [activeAsset, setActiveAsset] = useState(0);
  const [activeAssetData, setActiveAssetData] = useState([]);

  // microsite and mobile app thumbnail
  const [mobileAppCheck, setMobileAppCheck] = useState(true);
  const [micrositeCheck, setMicrositeCheck] = useState(false);
  const [mobileAppImage, setMobileAppImage] = useState(null);
  const [micrositeImage, setMicrositeImage] = useState(null);
  const [openMicrositeCropper, setOpenMicrositeCropper] = useState(true);
  const [micrositeCropperData, setMicrositeCropperData] = useState(null);
  const [micrositeCropperImageName, setMicrositeCropperImageName] = useState("");
  const [currentThumbnailStatus, setCurrentThumbnailStatus] = useState("mobile_app");

  const [activeAssetTitle, setActiveAssetTitle] = useState(null);
  const [activeAssetDesc, setActiveAssetDesc] = useState(null);
  const [activeAssetLongDesc, setActiveAssetLongDesc] = useState(null);
  const [activeAssetShortDesc, setActiveAssetShortDesc] = useState(null);
  const [activeAssetCatogries, setActiveAssetCatogries] = useState(null);
  const [activeAssetSubCatogries, setActiveAssetSubCatogries] = useState(null);
  const [activeAssetCampaigns, setActiveAssetCampaigns] = useState(null);
  const [activeAssetHashtags, setActiveAssetHashtags] = useState([]);
  const [activeAssetDefineUrl, setActiveAssetDefineUrl] = useState(null);
  const [leadGeneration, setLeadGeneration] = useState("");
  const [submitBtn, setSubmitBtn] = useState(false);

  // const [activeAssetData, setSelectedHashtags] = useState([]);
  const [tagName, setTagName] = useState("");
  const [tagModal, setTagModal] = useState(false);

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const hiddenFileInput = useRef(null);
  const hiddenFileInput1 = useRef(null);

  const videoImgUrl = vImgUrl;
  const pdfImgUrl = pImgUrl;

  let secureUrl = null;
  const talic_active = useSelector((state) => state.assetLibrary.talic_active);
  const isLoading = useSelector((state) => state.assetUpload.loading);
  const company_logo = useSelector((state) => state.headerTopMenus.companyLogo);
  const personalizeList = useSelector((state) => state.assetUpload.personalizeList);
  const video_cta_flag = useSelector((state) => state.conversionData.video_cta_flag);
  const business_flag = useSelector((state) => state.setupScreen.business_flag);

  const selectedAssetType = useSelector(
    (state) => state.assetUpload.selectedAssetType
  );

  const baseUploadData = useSelector(
    (state) => state.assetUpload.baseUploadData
  );

  const cta_asset_list = useSelector(
    (state) => state.conversionData.cta_asset_list
  );

  const baseGlobalFields = useSelector(
    (state) => state.assetUpload.globalFieldsData
  );

  const selectedCategories = useSelector(
    (state) => state.assetUpload.selectedCategories
  );

  const selectedSubCategories = useSelector(
    (state) => state.assetUpload.selectedSubCategories
  );

  const selectedCampaigns = useSelector(
    (state) => state.assetUpload.selectedCampaigns
  );

  const selectedHashtags = useSelector(
    (state) => state.assetUpload.selectedHashtags
  );

  const globalCategories = useSelector(
    (state) => state.assetUpload.globalCategories
  );

  const globalSubCategories = useSelector(
    (state) => state.assetUpload.globalSubCategories
  );

  const globalCampaigns = useSelector(
    (state) => state.assetUpload.globalCampaigns
  );

  const globalHashtags = useSelector(
    (state) => state.assetUpload.globalHashtags
  );

  const selectedGreetings = useSelector(
    (state) => state.assetUpload.selectedGreetings
  );

  const selectedDefineUrl = useSelector(
    (state) => state.assetUpload.selectedDefineUrl
  );

  const assetContentList = [useSelector((state) => state.assetLibrary.currentAssetDetails)];

  const uploadedData = useSelector(
    (state) => state.assetUpload.saveGlobalFieldsData
  );

  // console.log("baseGlobalFields-----", baseGlobalFields);
  //console.log("activeAssetData",activeAssetData)
  // console.log("selectedCategories-----", selectedCategories);
  // console.log("selectedCampaigns-----", selectedCampaigns);
  // console.log("selectedHashtags-----", selectedHashtags);
  //console.log("baseUploadData----", baseUploadData);
  //console.log("selectedDefineUrl",selectedDefineUrl);

  const assetLibraryCategoryList = useSelector(
    (state) => state.assetLibrary.categoryList
  );

  const assetLibrarySubCategoryList = useSelector(
    (state) => state.assetLibrary.subcategoryList
  );

  const assetLibraryLeadGenerationList = useSelector(
    (state) => state.assetLibrary.leadGenerationList
  );

  const assetLibraryHashtagList = useSelector(
    (state) => state.assetLibrary.hashtagList
  );

  const campaignList = useSelector((state) => state.assetUpload.campaignList);

  const greetingList = useSelector((state) => state.assetUpload.greetingList);

  const landingpageUrlList = useSelector(
    (state) => state.assetUpload.landingpageUrlList
  );

  const showcaseUrlList = useSelector(
    (state) => state.assetUpload.showcaseUrlList
  );

  const micrositeUrl = useSelector((state) => state.assetUpload.micrositeUrl);
  const customUrl = useSelector((state) => state.assetUpload.customUrl);
  const landingSecureUrl = useSelector((state) => state.assetUpload.landingSecureUrl);
  const showcaseSecureUrl = useSelector((state) => state.assetUpload.showcaseSecureUrl);

  const contentTypeList = useSelector(
    (state) => state.assetUpload.contentTypeList
  );

  const business_need_list = useSelector((state) => state.setupScreen.business_need_list);

  const categories = assetLibraryCategoryList.map(
    ({ id: value, solution_type: label }) => ({
      value,
      label,
    })
  );

  const subcategories = assetLibrarySubCategoryList.map(
    ({ id: value, it_subcat: label }) => ({
      value,
      label,
    })
  );

  const hashtags = assetLibraryHashtagList.map(
    ({ id: value, tag_name: label }) => ({
      value,
      label,
    })
  );

  // const camapigns = campaignList.map(({ c_id: value, camp_name: label }) => ({
  //   value,
  //   label,
  // }));

  const dispatch = useDispatch();

  useEffect(() => {
    if (_.isEmpty(baseGlobalFields || baseUploadData)) {
      Toast("Uploaded content lost, please re-upload..", "E");
      navigate("/new-asset");
    }

    setActiveAssetData(baseGlobalFields[0]);
    setActiveAssetCatogries(globalCategories);
    setActiveAssetSubCatogries(globalSubCategories);
    setActiveAssetCampaigns(globalCampaigns);
    setActiveAssetHashtags(globalHashtags);
    setBuyNowCheck(baseGlobalFields[0]?.Base?.buy_now !== 1 ? false : true)
    if (!_.isEmpty(baseGlobalFields)) {
      if (baseGlobalFields && baseGlobalFields[0].Base.url_option) {
        switch (baseGlobalFields[0].Base.url_option) {
          case "L":
            return setLpUrl(true);
          case "V":
            return setCtaCheck(true);
          case "S":
            return setScUrl(true);
          case "M":
            return setMsUrl(true);
          case "C":
            return setCusUrl(true);
          case "N":
            return setNoneUrl(true);
          default:
            return;
        }
      }
    }

  }, [authToken, dispatch]);

  props.pageTypeHandler("Global Fields");

  const selectCategoryHandler = (value, event) => {
    if (event === "select-option" && event === "*") {
      setSelectedCategoryList(categories);
      activeAssetData.Base.multi_cateSolutions = categories;
    } else if (
      event === "deselect-option" &&
      event === "*"
    ) {
      setSelectedCategoryList([]);
      activeAssetData.Base.multi_cateSolutions = [];
    } else if (event === "deselect-option") {
      value.filter((o) => o.value !== "*");
      setSelectedCategoryList(value.map(({ value }) => value));
      activeAssetData.Base.multi_cateSolutions = value.map(({ value }) => value);
    } else if (value.length === categories.length - 1) {
      setSelectedCategoryList(categories);
      activeAssetData.Base.multi_cateSolutions = categories;
    } else {
      setSelectedCategoryList(value.map(({ value }) => value));
      activeAssetData.Base.multi_cateSolutions = value.map(({ value }) => value);
    }
  };

  const selectSubCategoryHandler = (value, event) => {
    if (event === "select-option" && event === "*") {
      setSelectedSubCategoryList(subcategories);
      activeAssetData.Base.sub_category = subcategories;
    } else if (
      event === "deselect-option" &&
      event === "*"
    ) {
      setSelectedSubCategoryList([]);
      activeAssetData.Base.sub_category = [];
    } else if (event === "deselect-option") {
      value.filter((o) => o.value !== "*");
      setSelectedSubCategoryList(value.map(({ value }) => value));
      activeAssetData.Base.sub_category = value.map(({ value }) => value);
    } else if (value.length === subcategories.length - 1) {
      setSelectedSubCategoryList(subcategories);
      activeAssetData.Base.sub_category = subcategories;
    } else {
      setSelectedSubCategoryList(value.map(({ value }) => value));
      activeAssetData.Base.sub_category = value.map(({ value }) => value);
    }
  };

  const selectHashtagHandler = (value, event) => {
    if (event.action === "select-option" && event.option.value === "*") {
      setSelectedHashtagList(hashtags);
      activeAssetData.Base.intrltags = hashtags;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      setSelectedHashtagList([]);
      activeAssetData.Base.intrltags = [];
    } else if (event.action === "deselect-option") {
      value.filter((o) => o.value !== "*");
      setSelectedHashtagList(value.map(({ value }) => value));
      activeAssetData.Base.intrltags = value.map(({ value }) => value);
    } else if (value.length === hashtags.length - 1) {
      setSelectedHashtagList(hashtags);
      activeAssetData.Base.intrltags = hashtags;
    } else {
      setSelectedHashtagList(value.map(({ value }) => value));
      activeAssetData.Base.intrltags = value.map(({ value }) => value);
    }
  };

  // const selectCampaignHandler = (value, event) => {
  //   if (event.action === "select-option" && event.option.value === "*") {
  //     setSelectedCampaignList(camapigns);
  //     activeAssetData.Base.campaign = camapigns;
  //   } else if (
  //     event.action === "deselect-option" &&
  //     event.option.value === "*"
  //   ) {
  //     setSelectedCampaignList([]);
  //     activeAssetData.Base.campaign = [];
  //   } else if (event.action === "deselect-option") {
  //     value.filter((o) => o.value !== "*");
  //     setSelectedCampaignList(value.map(({ value }) => value));
  //     activeAssetData.Base.campaign = value.map(({ value }) => value);
  //   } else if (value.length === camapigns.length - 1) {
  //     setSelectedCampaignList(camapigns);
  //     activeAssetData.Base.campaign = camapigns;
  //   } else {
  //     setSelectedCampaignList(value.map(({ value }) => value));
  //     activeAssetData.Base.campaign = value.map(({ value }) => value);
  //   }
  // };
  const cropperFlag = (data) => {
    setOpenMicrositeCropper(data);
  }
  const nextCropper = (fileData) => {
    setMicrositeCropperData(fileData);
  }
  const checkboxHandler = (e, type) => {
    setCurrentThumbnailStatus(type);
    if (type === "mobile_app") {
      if (micrositeImage !== null) {
        setOpenMicrositeCropper(false);
      } else {
        setOpenMicrositeCropper(true);
      }
      setMobileAppCheck(e.target.checked);
      if (e.target.checked === false) {
        if (micrositeCheck === true) {
          setCurrentThumbnailStatus("microsite");
        }
        setMobileAppImage(null);
        setFile(null);
      }
    }
    else if (type === "microsite") {
      setMicrositeCheck(e.target.checked);
      if (e.target.checked === false) {
        if (mobileAppCheck === true) {
          setCurrentThumbnailStatus("mobile_app");
        }
        setMicrositeImage(null);
        setOpenMicrositeCropper(true);
        setMicrositeCropperData(null);
      }
    }
    else {
      console.log("else error")
    }

    if (mobileAppCheck === true && micrositeCheck === false && mobileAppImage === null) {
      setCurrentThumbnailStatus("mobile_app");
    }
  };
  
  const handlePersonalizeThumbnail = (e, count, type) => {
    if (e) {
      var fr = new FileReader;
      fr.onload = function () {
        let img = document.createElement('img');
        img.id = 'imgId';
        img.src = e.target.files[0];
        img.onload = function () {
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;
            let data = {
              base64: fr.result,
              file: {
                name: e.target.files[0].name,
                size: e.target.files[0].size,
                type: e.target.files[0].type,
              },
            }
            setPersonalizeFileData(data);
            activeAssetData.Base.personalize_template = data
            setPersonalizeFileName(e.target.files[0].name);
        };
        img.src = fr.result;
      };

      fr.readAsDataURL(e.target.files[0]);
    }
  }
  
  const handleUrlCheckboxChange = (e,obj) => {
      if (selectedPersonalizeCheck.includes(obj)) {
        setSelectedPersonalizeCheck(selectedPersonalizeCheck.filter(item => item !== obj));
      } else {
        setSelectedPersonalizeCheck([...selectedPersonalizeCheck, obj]);
      }
  };
  
  const addPersonalizeName = () => {
    if (personalizeName !== "") {
      dispatch(addNewTypePersonalize(authToken, personalizeName));
      setPersonalizeModal(false);
    } else {
      Toast("Please enter type name", "E");
    }
  }
  
  const deletePersonalizeName = () => {
    if (selectedPersonalizeCheck?.length > 0) {
      selectedPersonalizeCheck.map((key) => {
        dispatch(deleteNewTypePersonalize(authToken, key.id));
      })
      setSelectedPersonalizeCheck([]);
      setPersonalizeDeleteModal(false);
    } else {
      Toast("Please select type name", "E");
    }
  }

  const platformHandler = (e, type) => {
    let _updatedContent = _.clone(activeAssetData.Base.platform);
    if (e.target.checked) {
      if (type === "fb") {
        _updatedContent.push(1);
      } else if (type === "tw") {
        _updatedContent.push(2);
      } else if (type === "in") {
        _updatedContent.push(3);
      }
    } else {
      if (type === "fb") {
        _updatedContent = _updatedContent.filter((item) => item !== 1);
      } else if (type === "tw") {
        _updatedContent = _updatedContent.filter((item) => item !== 2);
      } else if (type === "in") {
        _updatedContent = _updatedContent.filter((item) => item !== 3);
      }
    }
    activeAssetData.Base.platform = _updatedContent;
    setPlatform(_updatedContent);
  };

  const submitHandler = async () => {
    setSubmitBtn(true);
    // e.preventDefault();
    console.log("baseGlobalFields", baseGlobalFields);

    let thumbarr = [];
    var dmarr = [];
    
    if (selectedAssetType.type === "Video") {
      activeAssetData.Base.personalize_template = activeAssetData?.Base?.personalize_id && activeAssetData.Base.personalize_id !== "" ? (personalizeFileData ? personalizeFileData : activeAssetData.Base.personalize_template) : {};
    }

    baseGlobalFields.map((ch, index) => {
      if (selectedAssetType.type !== "Video" && _.isEmpty(ch.Base?.thumbImg)) {
        thumbarr.push(index);
      }
      if (selectedAssetType.type === "Video" && _.isEmpty(ch.Base?.thumbImg) && _.isEmpty(ch.Base?.micrositeThumbImg)) {
        thumbarr.push(index);
      }
      /*  else {
        let img = new Image();


        img.src = ch.Base?.thumbImg?.base64;

        img.onload = function () {
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;
          if (imgWidth !== 500 && imgHeight !== 282) {
            dmarr.push(index);
          }
        };
      } */

      // console.log("jjsd", ch.Base.intrltags);
      if (ch.Base?.intrltags || ch.Base?.multi_cateSolutions) {
        if ((ch.Base?.intrltags.some(h => h.label))) {
          ch.Base.intrltags = ch.Base.intrltags.map((c) => {
            return c.id;
          })
          //console.log("Object found inside the array");
        }

        if ((ch.Base?.multi_cateSolutions.some(c => c.label))) {
          ch.Base.multi_cateSolutions = ch.Base.multi_cateSolutions.map((c) => {
            return c.id;
          })
          //console.log("Object found inside the array");
        }
      }

    });
    setTimeout(() => {
      if (selectedAssetType.type === "Brochure" ||
        selectedAssetType.type === "Infographics" ||
        selectedAssetType.type === "Infographic") {
        if (dmarr.length > 0) {
          Toast("Thumbnail Image should be Width:500px and Height: 282px", "E");
          setSubmitBtn(false);
        } else {
          dispatch({ type: "TOGGLE_LOADING", payload: true });
          setSubmitBtn(true);
          setTimeout(() => {
            dispatch(setBreadcrumbStatus(5));
            dispatch(saveGlobalFieldsData(authToken, baseGlobalFields));
          }, 4000);
        }
      } else {
        if (thumbarr.length > 0) {
          Toast(`Thumbnail Image required for each Asset`, "E");
          window.scroll(0, 0);
          activeAssetHandler(thumbarr[0]);
          setSubmitBtn(false);
        } else if (dmarr.length > 0) {
          Toast("Thumbnail Image should be Width:500px and Height: 282px", "E");
          setSubmitBtn(false);
        } else {
          dispatch({ type: "TOGGLE_LOADING", payload: true });
          setSubmitBtn(true);
          setTimeout(() => {
            dispatch(setBreadcrumbStatus(5));
            dispatch(saveGlobalFieldsData(authToken, baseGlobalFields));
          }, 4000);
        }
      }
    }, 2000);
  };

  const activeAssetHandler = (index) => {
    setActiveAsset(index);
    setActiveAssetData(baseGlobalFields[index]);
  };


  const setActiveCategories = () => {
    let currCategory = [];
    activeAssetData.Base.multi_cateSolutions.some((c) => {
      categories.some((b) => {
        if (b.value === c) {
          currCategory.push(b);
        }
      })
    });
    setActiveAssetCatogries(currCategory);
  }

  const setActiveSubCategories = () => {
    let currCategory = [];
    activeAssetData.Base.sub_category.some((c) => {
      subcategories.some((b) => {
        if (b.value === c) {
          currCategory.push(b);
        }
      })
    });
    setActiveAssetSubCatogries(currCategory);
  }
  
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fr = new FileReader();
      
      fr.onload = function () {
        const audio = document.createElement('audio');
        audio.src = fr.result;
        audio.onloadedmetadata = function () {
          if (audio.duration > 5.5) {
            Toast("The audio file should be upto 5 seconds.", "E");
            fileInputRef.current.value = '';
            setAudioFile(null);
            setAudioDuration(null);
            setAudioName(null);
          } else {
            // setError(null);
            const data = {
              base64: fr.result,
              file: {
                name: file.name,
                size: file.size,
                type: file.type,
              },
            };
            console.log("Audio data:", data);
            activeAssetData.Base.reel = data;
            activeAssetData.Base.audio_duration = audio.duration;
            setAudioFile(data);
            setAudioDuration(audio?.duration);
            setAudioName(file.name);
          }
        };

        audio.onerror = function () {
          // setError('Failed to load audio metadata');
          setAudioFile(null);
          setAudioName(null);
        };
      };

      fr.onerror = function () {
        // setError('Failed to read file');
        setAudioFile(null);
        setAudioName(null);
      };

      fr.readAsDataURL(file);
    } else {
      // setError('No file selected');
      setAudioFile(null);
      setAudioName(null);
    }
  };

  // const setActiveCampaigns = () => {
  //   let currCampaign = [];
  //   activeAssetData.Base.campaign.some((c)=>{
  //     camapigns.some((b)=>{
  //       if(b.value === c) {
  //         currCampaign.push(b);
  //       }    
  //     })     
  //   });
  //   setActiveAssetCampaigns(currCampaign);
  // }

  const setActiveHashtags = () => {
    let currHashtag = [];
    activeAssetData.Base.intrltags.some((c) => {
      hashtags.some((b) => {
        if (b.value === c) {
          currHashtag.push(b);
        }
      })
    });
    setActiveAssetHashtags(currHashtag);
  }

  const onSelectCategory = (selectedList, selectedItem) => {
    selectCategoryHandler(selectedList, "select-option");
    setActiveCategories();
  };

  const onRemoveCategory = (selectedList, removedItem) => {
    selectCategoryHandler(selectedList, "deselect-option");
    setActiveCategories();
  };

  const onSelectSubCategory = (selectedList, selectedItem) => {
    selectSubCategoryHandler(selectedList, "select-option");
    setActiveSubCategories();
  };

  const onRemoveSubCategory = (selectedList, removedItem) => {
    selectSubCategoryHandler(selectedList, "deselect-option");
    setActiveSubCategories();
  };

  // const onSelectCampaign = (selectedList, selectedItem) => {
  //   selectCampaignHandler(selectedList, "select-option");
  //   setActiveCampaigns();
  // };

  // const onRemoveCampaign = (selectedList, removedItem) => {
  //   selectCampaignHandler(selectedList, "deselect-option");
  //   setActiveCampaigns();
  // };

  const onSelectHashtag = (selectedList, selectedItem) => {
    selectHashtagHandler(selectedList, "select-option");
    setActiveHashtags();
  };

  const onRemoveHashtag = (selectedList, removedItem) => {
    selectHashtagHandler(selectedList, "deselect-option");
    setActiveHashtags();
  };


  useEffect(() => {
    if (activeAssetData.Base) {

      if (!(activeAssetData.Base.content_name === "Banners")) {
        //console.log("activeAsset>>",activeAsset);
        setActiveCategories();
        setActiveSubCategories();
        //setActiveCampaigns();
        setActiveHashtags();
      }

      switch (activeAssetData.Base.url_option) {
        case "L":
          setCtaCheck(false); setLpUrl(true); setScUrl(false); setMsUrl(false); setCusUrl(false); setNoneUrl(false);
          break;
        case "V":
          setCtaCheck(true); setLpUrl(false); setScUrl(false); setMsUrl(false); setCusUrl(false); setNoneUrl(false);
          break;
        case "S":
          setCtaCheck(false); setLpUrl(false); setScUrl(true); setMsUrl(false); setCusUrl(false); setNoneUrl(false);
          break;
        case "M":
          setCtaCheck(false); setLpUrl(false); setScUrl(false); setMsUrl(true); setCusUrl(false); setNoneUrl(false);
          break;
        case "C":
          setCtaCheck(false); setLpUrl(false); setScUrl(false); setMsUrl(false); setCusUrl(true); setNoneUrl(false);
          break;
        default:
          setCtaCheck(false); setLpUrl(false); setScUrl(false); setMsUrl(false); setCusUrl(false); setNoneUrl(true);
          break;
      }

      if (activeAssetData.Base.secure_url) {
        setActiveAssetDefineUrl((activeAssetData.Base.secure_url).split("/").pop());
      }

      if (activeAssetData.Base.form_type !== null) {
        setFormType(activeAssetData.Base.form_type);
      }
    }

  }, [activeAsset]);

  useEffect(() => {
    if (uploadedData && uploadedData.length > 0) {
      setTimeout(() => {
        navigate("/new-asset/publish-asset");
      }, 2000);
    }
  }, [uploadedData]);

  //console.log("activeAssetCampaigns",activeAssetCampaigns);
  //console.log("activeAssetData",activeAssetData);

  const handleCheckImageSize = (file) => {
    const img = new Image();

    img.src = file.base64;

    img.onload = function () {
      const imgWidth = img.naturalWidth;
      const imgHeight = img.naturalHeight;
      if (imgWidth !== 500 && imgHeight !== 282) {
        Toast("Image dimesion is not matching.., crop your image", "E");
        return;
      } else {
        if (activeAssetData) {
          //console.log("file_arr", file_arr);
          activeAssetData.Base.thumbImg = {
            base64: file.base64,
            file: {
              name: file.name,
              size: file.size,
              type: file.type,
            },
          };
          return [activeAssetData.Base.thumbImg];
        }
      }
    };
  }

  useEffect(() => {
    if (assetLibraryHashtagList.length > 0 && tagName) {
      let t = assetLibraryHashtagList.filter(e => {
        return e.tag_name === tagName
      });
      let htags = t.map(
        ({ id: value, tag_name: label }) => ({
          value,
          label,
        })
      );
      if (htags) {
        if (!_.isEmpty(activeAssetHashtags)) {
          let arr = [...activeAssetHashtags];
          arr.push(htags);
          setActiveAssetHashtags([...activeAssetHashtags, ...htags]);
          activeAssetData.Base?.intrltags.push(htags[0]?.value);
        } else {
          if (globalHashtags && globalHashtags.length > 0) {
            setActiveAssetHashtags([...globalHashtags, ...htags]);
          } else {
            setActiveAssetHashtags(htags);
          }
          activeAssetData.Base?.intrltags.push(htags[0]?.value);
        }
      }
    }
  }, [assetLibraryHashtagList])


  const addHashTag = () => {
    if (tagName !== "") {
      dispatch(addNewHashTag(authToken, tagName));
      setTagModal(false);
    } else {
      Toast("Please enter tag name", "E");
    }
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    if (activeAsset !== baseGlobalFields.length - 1) {
      setTimeout(() => {
        // if (Object.keys(activeAssetData.Base.thumbImg).length <= 0) {
        //   activeAssetData.Base.thumbImg = {};
        // }
        activeAssetHandler(activeAsset + 1);
        window.scroll(0, 0);
      }, 2000)
    } else {
      submitHandler();
    }
  }

  const handleButtonClick = event => {
    if (mobileAppCheck === true || micrositeCheck === true) {
      if (currentThumbnailStatus === "mobile_app" && mobileAppCheck === true) {
        hiddenFileInput.current.click();
      } else if (currentThumbnailStatus === "microsite" && micrositeCheck === true) {
        setOpenMicrositeCropper(true);
        setMicrositeCropperData(null);
        hiddenFileInput1.current.click();
      } else {
        return;
      }
    } else {
      Toast("Select the platform to deploy", "E");
    }
  };

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleImage = (e) => {
    if (e) {
      setFile(null);
      setFileName("");
      var fr = new FileReader;
      fr.onload = function () {
        let img = document.createElement('img');
        img.id = 'imgId';
        img.src = e.target.files[0];
        img.onload = function () {
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;
          //console.log("imgWidth", imgWidth, "imgHeight", imgHeight);
          if (imgWidth !== 500 && imgHeight !== 282) {
            setFile(fr.result);
            setFileName(e.target.files[0].name);
            Toast("Image dimesion is not matching.., crop your image & submit", "E");
            return;
          } else {
            setFileName(e.target.files[0].name);
            submitedImage(fr.result, "direct", e.target.files[0].name);
          }
        };
        img.src = fr.result;
      };

      fr.readAsDataURL(e.target.files[0]);
    }
  }

  const handleMultipleImage = (e, count, type) => {
    if (e) {
      if (mobileAppCheck === true) {
        setFile(null);
        setFileName("");
      }
      if (micrositeCheck === true) {
        setMicrositeCropperData(null);
        setMicrositeCropperImageName("");
      }

      var fr = new FileReader;
      fr.onload = function () {
        let img = document.createElement('img');
        img.id = 'imgId';
        img.src = e.target.files[0];
        img.onload = function () {
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;
          //console.log("imgWidth", imgWidth, "imgHeight", imgHeight);
          if (imgWidth !== 500 && imgHeight !== 282 && currentThumbnailStatus !== "microsite") {
            setFile(fr.result);
            setFileName(e.target.files[0].name);
            Toast("Image dimesion is not matching.., crop your image & submit", "E");
            return;
          } else if (imgWidth !== 353 && imgHeight !== 400 && currentThumbnailStatus === "microsite") {
            setMicrositeCropperData(fr.result);
            setMicrositeCropperImageName(e.target.files[0].name);
            Toast("Image dimesion is not matching.., crop your image & submit", "E");
            return;
          }
          else {
            setCurrentThumbnailStatus(type);
            setFileName(e.target.files[0].name);
            setMicrositeCropperImageName(e.target.files[0].name);
            submitedMultipleImage(fr.result, "direct", e.target.files[0].name);
          }
        };
        img.src = fr.result;
      };

      fr.readAsDataURL(e.target.files[0]);
    }
  }

  const submitedImage = (e, path, name) => {
    if (activeAssetData) {
      let blob = new Blob();
      const fileee = new File([e], path === "direct" ? name : fileName, { type: "image/jpeg" });
      activeAssetData.Base.thumbImg = {
        base64: e,
        file: {
          name: fileee.name,
          size: fileee.size,
          type: fileee.type,
        },
      };
      setImagefile(activeAssetData.Base.thumbImg);
      setFile(null); setFileName("");
    }
  }

  const submitedMultipleImage = (e, path, name) => {
    if (activeAssetData) {
      let blob = new Blob();
      if (currentThumbnailStatus === "mobile_app") {
        const fileee = new File([e], path === "direct" ? name : fileName, { type: "image/jpeg" });
        activeAssetData.Base.thumbImg = {
          base64: e,
          file: {
            name: fileee.name,
            size: fileee.size,
            type: fileee.type,
          },
        };
        setMobileAppImage(activeAssetData.Base.thumbImg);
        setCurrentThumbnailStatus("microsite");
        setFile(null); setFileName("");
      } else if (currentThumbnailStatus === "microsite") {
        const mFileee = new File([e], path === "direct" ? name : micrositeCropperImageName, { type: "image/jpeg" });
        activeAssetData.Base.micrositeThumbImg = {
          base64: e,
          file: {
            name: mFileee.name,
            size: mFileee.size,
            type: mFileee.type,
          },
        };
        setMicrositeImage(activeAssetData.Base.micrositeThumbImg)
        setCurrentThumbnailStatus("none");
        setMicrositeCropperData(null); setMicrositeCropperImageName("");
      } else {
        console.log("error");
      }
    }
  }

  // console.log("activeasset", activeAsset);
  // console.log("baseUploadData.data.length", baseUploadData?.data?.length);
  // console.log("baseGlobalFields.length", baseGlobalFields?.length);
  // console.log("true or false", activeAsset !== baseUploadData?.data?.length - 1 ? 'Next' : 'Save & Proceed');

  return (
    <div className="new-asset create-content">
      
      {isLoading && <Loading />}
      <Row className="g-0">
        <Col>
          <ul className="selected-asset-list">
            <li className="asset-type-img">
              <img
                src={selectedAssetType.article_image}
                alt={selectedAssetType.article_type}
              />
            </li>
            <li className="web-icon">
              <span>
                <Icon.Globe />
              </span>
            </li>
            {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
              selectedAssetType.type === "Banner") && (
                <>
                  {baseUploadData.data &&
                    baseUploadData.data.map((d, i) => {
                      return d.map((b, index) => {
                        return (
                          <li
                            id={index}
                            className={
                              activeAsset === index ? "active asset" : "asset"
                            }
                            // onClick={() => {
                            //   activeAssetHandler(index);
                            // }}
                            key={index}
                          >
                            {selectedAssetType.type === "Banner" ? (
                              <img
                                src={b.Base.banner_image.base64}
                                alt={b.Base.banner_image.file.name}
                              />
                            ) : (
                              <img
                                src={b.Base.post_image.base64}
                                alt={b.Base.post_image.file.name}
                              />
                            )}
                          </li>
                        );
                      });
                    })}
                </>
              )}
            {(selectedAssetType.type === "Brochure" ||
              selectedAssetType.type === "Infographics" ||
              selectedAssetType.type === "Infographic") && (
                <>
                  {baseUploadData.data &&
                    baseUploadData.data.map((d) => {
                      return d.map((b, index) => {
                        return (
                          <li
                            title={b.Base.document.file.name}
                            id={index}
                            key={index}
                            className={
                              activeAsset === index
                                ? "active asset file"
                                : "asset file"
                            }
                          // onClick={() => {
                          //   activeAssetHandler(index);
                          // }}
                          >
                            {activeAsset === index && (
                              <span className="active-icon">
                                <Icon.CaretUpFill />
                              </span>
                            )}
                            <span className="file-type-icon">
                              <Icon.FilePdf />
                            </span>
                            <span className="file-type-name">
                              {b.Base.document.file.name}
                            </span>
                          </li>
                        );
                      });
                    })}
                </>
              )}
            {selectedAssetType.type === "Video" && (
              <>
                {baseUploadData.data &&
                  baseUploadData.data.map((d) => {
                    return d.map((b, index) => {
                      if (_.has(b.Base, "video_url")) {
                        return (
                          <li
                            title={b.Base.video_url}
                            id={index}
                            key={index}
                            className={
                              activeAsset === index
                                ? "active asset file"
                                : "asset file"
                            }
                          // onClick={() => {
                          //   activeAssetHandler(index);
                          // }}
                          >
                            {activeAsset === index && (
                              <span className="active-icon">
                                <Icon.CaretUpFill />
                              </span>
                            )}
                            <span className="file-type-icon">
                              <Icon.FilePlay />
                            </span>
                            <span className="file-type-name">
                              {b.Base.video_url}
                            </span>
                          </li>
                        );
                      } else {
                        return (
                          <li
                            title={b.Base.video.file.name}
                            id={index}
                            className={
                              activeAsset === index
                                ? "active asset file"
                                : "asset file"
                            }
                          // onClick={() => {
                          //   activeAssetHandler(index);
                          // }}
                          >
                            {activeAsset === index && (
                              <span className="active-icon">
                                <Icon.CaretUpFill />
                              </span>
                            )}
                            <span className="file-type-icon">
                              <Icon.FilePlay />
                            </span>
                            <span className="file-type-name">
                              {b.Base.video.file.name}
                            </span>
                          </li>
                        );
                      }
                    });
                  })}
              </>
            )}
            {/* <li className="add-more">+</li> */}
          </ul>
        </Col>
      </Row>
      <Card className="global-fields-card base">
        <Row className="asset-type g-0">
          <Col className="col-lg-6 col-md-12 col m-lg-0 my-2 my-2 mx-lg-0 mx-2">
            <Row className="g-0">
              <Col>
                <Form > {/* onSubmit={submitHandler} */}
                  {(selectedAssetType.type === "Brochure" || selectedAssetType.type === "Video") && (
                    <>
                      <Form.Group className="mb-4 checkbox-btn d-flex m-0">
                        <Form.Check
                          style={{ marginRight: "48px" }}
                          type="checkbox"
                          label="Mobile App"
                          name="mobile-app"
                          checked={mobileAppCheck}
                          onChange={(e) => {
                            checkboxHandler(e, "mobile_app");
                          }}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Microsite"
                          name="microsite"
                          checked={micrositeCheck}
                          onChange={(e) => {
                            checkboxHandler(e, "microsite");
                          }}
                        />
                      </Form.Group>
                    </>
                  )}
                  {(selectedAssetType.type === "Brochure" || selectedAssetType.type === "Video") && <>
                    <div className="mb-3">
                      {currentThumbnailStatus !== "none" && ((mobileAppCheck === true && mobileAppImage === null) || (micrositeCheck === true && micrositeImage === null)) && <Button onClick={handleButtonClick} className="btn submit-btn">
                        Upload Thumbnail
                      </Button>}
                      <input type="file"
                        ref={hiddenFileInput}
                        style={{ display: 'none' }}
                        accept="image/*"
                        onClick={e => (e.target.value = null)}
                        onChange={(e) => handleMultipleImage(e, currentThumbnailStatus, 'mobile_app')}
                      />
                      <input type="file"
                        ref={hiddenFileInput1}
                        style={{ display: 'none' }}
                        accept="image/*"
                        onClick={e => (e.target.value = null)}
                        onChange={(e) => handleMultipleImage(e, currentThumbnailStatus, "microsite")}
                      />
                      {currentThumbnailStatus !== "none" && <div>
                        {/* {selectedAssetType.type === "Brochure" && <small className="brochureThumbInfo">Note: If you don't upload a thumbnail, the first page of the PDF will be auto-fetched as a thumbnail.</small>} */}
                        {mobileAppCheck === true && mobileAppImage === null && <div style={{ fontSize: "11px" }} className="text-danger small-text">Recommended size (app) - 500(W) x 282(H)</div>}
                        {micrositeCheck === true && micrositeImage === null && <div style={{ fontSize: "11px" }} className="text-danger small-text">Recommended size (microsite) - 353(W) x 400(H)</div>}
                        {selectedAssetType.type === "Brochure" && <small>Note: If you don't upload a thumbnail, the first page of the PDF will be auto-fetched as a thumbnail.</small>}
                      </div>}
                      {file !== null &&
                        <div style={{ width: 600, height: 400 }} className="mt-2">
                          <CropImage file={file} nextCropper={nextCropper} cropperFlag={cropperFlag} checkPass={(mobileAppCheck === true && micrositeCheck === true && openMicrositeCropper === true) ? "multi" : "single"} thumbnailStatus={currentThumbnailStatus} onImgSubmit={(e) => submitedMultipleImage(e, "crop", "")} /> {/* setSubmitImage */}
                        </div >
                      }
                      {micrositeCropperData !== null && openMicrositeCropper === true &&
                        <div style={{ width: 600, height: 400 }} className="mt-2">
                          <CropImage file={micrositeCropperData} cropperFlag={cropperFlag} thumbnailStatus={currentThumbnailStatus} onImgSubmit={(e) => submitedMultipleImage(e)} />
                        </div>
                      }
                    </div>
                    <div className="d-flex">
                      {mobileAppImage !== null && <div className="thumbImg mb-3" style={{ marginRight: "20px" }}>
                        <div className="thumb-div">
                          <img src={mobileAppImage.base64} alt="thumbImg" />
                          <Icon.X onClick={() => { setMobileAppImage(null); setCurrentThumbnailStatus("mobile_app"); }} className="close-icon-asset" />
                        </div>
                      </div>}
                      {micrositeImage !== null && <div className="thumbImg mb-3" style={{ marginRight: "20px" }}>
                        <div className="thumb-div">
                          <img src={micrositeImage.base64} alt="thumbImg" />
                          <Icon.X onClick={() => {
                            setMicrositeImage(null);
                            setCurrentThumbnailStatus("microsite");
                            setOpenMicrositeCropper(true);
                            setMicrositeCropperData(null);
                          }} className="close-icon-asset" />
                        </div>
                      </div>}
                    </div>
                  </>}
                  {
                    (selectedAssetType.type !== "Brochure" && selectedAssetType.type !== "Video") && <>
                      {imagefile !== null && (activeAssetData.Base &&
                        !_.isEmpty(activeAssetData.Base.thumbImg)) ? (
                        <div className="thumbImg mb-5 position-relative">
                          <img
                            src={activeAssetData.Base.thumbImg.base64}
                            alt="thumbImg"
                            />
                            <Icon.X onClick={() => {
                              setImagefile(null);
                              activeAssetData.Base.thumbImg = {};
                            }} className="cursor-pointer close-icon-asset" />
                        </div>
                      ) : (
                        <div className="mb-5">
                          {/*  <FileInputComponent
                        labelText="Upload Thumbnail"
                        //imagePreview={true}
                        multiple={false}
                        callbackFunction={(file_arr) => {
                          handleCheckImageSize(file_arr)
                        }}
                        // accept="image/*,.pdf,video/*"
                        accept="image/*"
                      />
                      {(selectedAssetType.article_type === "Brochure" ||
                    selectedAssetType.article_type === "Infographics" ||
                    selectedAssetType.article_type === "Infographic") && <small className="brochureThumbInfo">Note: If you don't upload a thumbnail, the first page of the PDF will be auto-fetched as a thumbnail.</small>}
                      <small className="text-danger small-text">Accepted image size - Width: 500px and Height: 282px</small> */}
                          <Button onClick={handleClick} className="btn submit-btn m-lg-0 mb-2 mt-5">
                            Upload Thumbnail
                          </Button>
                          <input type="file"
                            ref={hiddenFileInput}
                            style={{ display: 'none' }}
                            accept="image/*"
                            onChange={(e) => handleImage(e)}
                            />
                          <div className="position-relative">
                            {(selectedAssetType.type === "Brochure" ||
                              selectedAssetType.type === "Infographics" ||
                              selectedAssetType.type === "Infographic") && <small className="brochureThumbInfo">Note: If you don't upload a thumbnail, the first page of the PDF will be auto-fetched as a thumbnail.</small>}
                            <small className="text-danger small-text">Accepted image size - Width: 500px and Height: 282px</small>
                          </div>
                          {file !== null &&
                            <div style={{ width: 600, height: 400 }} className="mt-2">
                              <CropImage file={file} onImgSubmit={(e) => submitedImage(e, "crop", "")} /> {/* setSubmitImage */}
                            </div>
                          }
                        </div>
                      )}
                    </>
                  }
                  {
                    selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                      !selectedAssetType.type === "Brochure" ||
                      !selectedAssetType.type === "Infographics" ||
                      !selectedAssetType.type === "Infographic" ||
                      selectedAssetType.type === "Video" ||
                      !selectedAssetType.type === "Banner" ? (
                      <div>
                        <Form.Label htmlFor="select-platform">
                          Select Platform
                        </Form.Label>
                        <Form.Group className="mb-4 checkbox-btn">
                          <Form.Check
                            type="checkbox"
                            label="Facebook"
                            name="fb-platform"
                            checked={
                              activeAssetData.Base &&
                              _.includes(activeAssetData.Base.platform, 1)
                            }
                            onChange={(e) => {
                              platformHandler(e, "fb");
                              setPlatformFB(!platformFB);
                            }}
                          />
                        </Form.Group>
                      </div>
                    ) : (
                      ""
                    )
                  }

                  <Form.Group className="mb-3 d-flex align-items-center justify-content-between g-1">
                    <Form.Label className="mt-2">
                      Name <span className="mandatory">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      ref={nameRef}
                      autoComplete="off"
                      required
                      value={activeAssetData.Base && activeAssetData.Base.title}
                      onChange={(e) => {
                        setActiveAssetTitle(e.target.value);
                        activeAssetData.Base.title = e.target.value;
                      }}
                    />
                  </Form.Group>

                  {
                    selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                      selectedAssetType.type === "Brochure" ||
                      selectedAssetType.type === "Infographics" ||
                      selectedAssetType.type === "Infographic" ||
                      selectedAssetType.type === "Video" ||
                      !selectedAssetType.type === "Banner" ? (
                      <>
                        {categories && (
                          <div className="smartFolder-multiSelect mb-3 d-flex align-items-center justify-content-between g-1">
                            <Form.Label className="mt-2">
                              Categories
                            </Form.Label>

                            
                            <Multiselect
                              options={categories}
                              avoidHighlightFirstOption={true}
                              selectedValues={activeAssetCatogries ? activeAssetCatogries : globalCategories}
                              onSelect={onSelectCategory}
                              onRemove={onRemoveCategory}
                              displayValue="label"
                              showCheckbox={true}
                              customArrow={<Icon.ChevronDown/>}    
                              singleSelect={company_logo?.new_login != "tal" ? false : true}    
                              placeholder="Selected Categories"  
                            />

                          </div>
                        )}


                        {/* {camapigns && (
                        <div className="smartFolder-multiSelect mb-4">
                          <Form.Label className="mt-2">
                            Campaigns
                          </Form.Label>
                          <Multiselect
                            options={camapigns}
                            selectedValues={activeAssetCampaigns?activeAssetCampaigns:globalCampaigns}
                            onSelect={onSelectCampaign}
                            onRemove={onRemoveCampaign}
                            displayValue="label"
                            showCheckbox={true}
                            placeholder="Selected Campaigns"
                          />
                        </div>
                      )} */}
                        {selectedAssetType.type === "Video" && company_logo?.personalize_video === 1 && <>
                          <div className="smartFolder-multiSelect mb-3">
                            <Form.Label className="mt-1">
                                Personalize Video
                              </Form.Label>
                            <div className="input-form-column">
                              <Form.Select
                                className="greeting input-form w-100 p-2 text-capitalize float-unset"
                                aria-label="select-greetings"
                                style={{color:"#6e6b6b"}}
                                onChange={(e) => {
                                  setSelectedPersonalize(e.target.value);
                                  setPersonalizeType(e.target?.selectedOptions[0]?.text?.toLowerCase())
                                  activeAssetData.Base.p_type = e.target?.selectedOptions[0]?.text?.toLowerCase();
                                  activeAssetData.Base.personalize_id = e.target.value
                                }}
                                value={activeAssetData?.Base ? activeAssetData?.Base?.personalize_id : selectedPersonalize}
                              >
                                <option value="" onClick={()=>{setSelectedPersonalize(null)}}>Select Personalize Video</option>
                                {personalizeList?.preVideoList?.length > 0 &&
                                    personalizeList?.preVideoList?.map((personalise) => {
                                    let selected = false;
                                    if (activeAssetData.Base) {
                                      if ( personalise.id === parseInt(activeAssetData?.Base?.personalize_id) ) {
                                        selected = true;
                                      }
                                      return (
                                        <>
                                          <option className="text-capitalize" value={personalise.id}>
                                            {personalise.greeting_name}
                                          </option>
                                        </>
                                      );
                                    } else return "";
                                  })}
                              </Form.Select>
                              <div className="max-width-100">
                                  <a className="mt-1 text-decoration-none show-pointer" onClick={() => { setPersonalizeModal(true) }}>Add New Type?</a>
                                  {personalizeList?.preVideoList.length > 0 && <span style={{float:"right"}} onClick={() => { setPersonalizeDeleteModal(true) }} className="delete-icon"><Icon.Trash/></span>}
                              </div>
                            </div>
                            </div>
                        
                          {((!activeAssetData?.Base?.p_type?.includes("reel")) && ((activeAssetData?.Base?.personalize_id !== null && activeAssetData?.Base?.personalize_id !== "" && activeAssetData?.Base?.personalize_id) || (selectedPersonalize !== null && selectedPersonalize!== ""))) && <div className="smartFolder-multiSelect mb-3">
                            <Form.Label className="mt-0">
                                Co-Branded Template
                              </Form.Label>
                            <div className="input-form-column d-flex">
                                <input className="d-none" type="file" id="actual-btn" accept="image/*" onChange={(e)=>{handlePersonalizeThumbnail(e)}} />
                                <label for="actual-btn"><Icon.Upload style={{ marginRight: "15px", fontSize: "18px" }} /><span className="pl-3">{activeAssetData?.Base?.personalize_template?.file?.name || personalizeFileName}</span></label> 
                            </div>
                            </div>}
                          {((activeAssetData?.Base?.personalize_id !== null && activeAssetData?.Base?.personalize_id !== "" && activeAssetData?.Base?.p_type?.includes("reel") ) || (selectedPersonalize !== null && selectedPersonalize!== "" && personalizeType?.includes("reel"))) && <div className="smartFolder-multiSelect mb-3">
                            <Form.Label className="mt-0">
                                Upload Audio
                              </Form.Label>
                            <div className="input-form-column d-flex">
                                <input ref={fileInputRef} className="d-none" type="file" id="actual-audio-btn" accept="audio/*" onChange={handleFileChange} />
                                <label htmlFor="actual-audio-btn"><Icon.Upload style={{ marginRight: "15px", fontSize: "18px" }} /><span className="pl-3">{activeAssetData?.Base?.reel?.file?.name || audioName}</span></label> 
                            </div>
                          </div>}  
                            </>
                        } 
                        {hashtags && (
                          <div className="smartFolder-multiSelect mb-3">
                            <Form.Label className="mt-2">
                              Hashtags
                            </Form.Label>
                            <div>
                              <Multiselect
                                options={hashtags}
                                avoidHighlightFirstOption={true}
                                selectedValues={!_.isEmpty(activeAssetHashtags) ? activeAssetHashtags : globalHashtags}
                                onSelect={onSelectHashtag}
                                onRemove={onRemoveHashtag}
                                displayValue="label"
                                showCheckbox={true}
                                placeholder="Selected Hashtags"
                              />
                              <div>
                                <a className="mt-1 text-decoration-none show-pointer" onClick={() => { setTagModal(true) }}>Add New Hashtag?</a>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )
                  }

                  {(selectedAssetType.type !== "Banner" && company_logo?.isBuyNow !== 0) && (
                      <>
                        {subcategories && (
                          <div className="smartFolder-multiSelect mb-3">
                            <Form.Label className="mt-0">
                              Buy Now Link
                            </Form.Label>

                            <Form.Group className="mb-0 ali checkbox-btn d-flex align-item-center m-0">
                              <Form.Check
                                type="checkbox"
                                name="buy-now"
                                checked={buyNowCheck}
                                onChange={(e) => {
                                  setBuyNowCheck(e?.target?.checked)
                                  activeAssetData.Base.buy_now = e.target.checked !== false ? 1 : 0
                                }}
                              />
                            </Form.Group>
                          </div>
                        )}
                      </>
                    )}

                  {
                    (selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                      selectedAssetType.type === "Video" ||
                      selectedAssetType.type === "Images" ||
                      selectedAssetType.type === "Brochure") && (
                      <>
                        {subcategories && (
                          <div className="smartFolder-multiSelect mb-4">
                            <Form.Label className="mt-2">
                              {company_logo?.new_login != "tal" ? (company_logo?.isBuyNow !== 0 ? "Fund Name" : "Sub-Categories") : "Product"}
                            </Form.Label>

                            
                            <Multiselect
                              options={subcategories}
                              avoidHighlightFirstOption={true}
                              selectedValues={activeAssetSubCatogries ? activeAssetSubCatogries : globalSubCategories}
                              onSelect={onSelectSubCategory}
                              onRemove={onRemoveSubCategory}
                              displayValue="label"
                              showCheckbox={true}
                              customArrow={<Icon.ChevronDown/>}  
                              singleSelect={company_logo?.new_login != "tal" ? false : true}  
                              placeholder={company_logo?.new_login != "tal" ? (company_logo?.isBuyNow !== 0 ? "Selected Fund Name":"Selected Sub Categories"):"Selected Products"}
                            />
                          </div>
                        )}
                      </>
                    )
                  }


                  {
                    (selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                      selectedAssetType.type === "Video" ||
                      selectedAssetType.type === "Images") && (
                      <>
                        {assetLibraryLeadGenerationList && (
                          <div className="smartFolder-multiSelect mb-3">
                            <Form.Label className="mt-2">
                              Lead Generation
                            </Form.Label>
                            <Form.Select
                              id="lead-genration"
                              as="select"
                              className="greeting mb-3"
                              value={activeAssetData.Base && activeAssetData.Base.lg_type}
                              onChange={(e) => {
                                setLeadGeneration(e.target.value);
                                activeAssetData.Base.lg_type = e.target.value;
                              }}
                            >
                              <option key={1} value="">Select Lead Generation</option>
                              {assetLibraryLeadGenerationList.map((item, index) => {
                                return (
                                  <>
                                    <option key={index + 2} value={item.id} >{item.lg_type}</option>
                                  </>
                                )
                              })}
                            </Form.Select>
                          </div>
                        )}
                      </>
                    )}

                  {(selectedAssetType.type === "Social" ||
                    selectedAssetType.type === "Social Post" ||
                    selectedAssetType.type === "Image" ||
                    selectedAssetType.type === "Video" ||
                    selectedAssetType.type === "Banners" ||
                    selectedAssetType.type === "Banner") && (
                      <div className="smartFolder-multiSelect">
                        <Form.Label>
                          Define URL
                        </Form.Label>
                        <br />
                        <div className="social-path url-max-size">

                          <Form.Group className="mb-3 radio-btn">
                            <Form.Check
                              type="radio"
                              label="Microsite URL"
                              name="define-url"
                              value="M"
                              checked={msUrl}
                              onClick={(e) => {
                                setCtaCheck(false);
                                setScUrl(false);
                                setLpUrl(false);
                                setMsUrl(true);
                                setCusUrl(false);
                                setNoneUrl(false);
                                setUrlOption("M");
                                activeAssetData.Base.url_option = "M";
                                activeAssetData.Base.secure_url = micrositeUrl;
                              }}
                            />
                          </Form.Group>
                        
                          <Form.Group className="mb-3 radio-btn">
                            <Form.Check
                              type="radio"
                              label="Landingpage URL"
                              name="define-url"
                              value="L"
                              checked={lpUrl}
                              onClick={(e) => {
                                setCtaCheck(false);
                                setLpUrl(true);
                                setScUrl(false);
                                setMsUrl(false);
                                setCusUrl(false);
                                setNoneUrl(false);
                                setUrlOption("L");
                                activeAssetData.Base.url_option = "L";
                              }}
                            />
                          </Form.Group>
                        
                          <Form.Group className="mb-3 radio-btn">
                            <Form.Check
                              type="radio"
                              label="Custom URL"
                              name="define-url"
                              value="C"
                              checked={cusUrl}
                              onClick={(e) => {
                                setCtaCheck(false);
                                setScUrl(false);
                                setLpUrl(false);
                                setMsUrl(false);
                                setCusUrl(true);
                                setNoneUrl(false);
                                setUrlOption("C");
                                activeAssetData.Base.secure_url = "";
                                activeAssetData.Base.url_option = "C";
                              }}
                            />
                          </Form.Group>

                          {(video_cta_flag === true && selectedAssetType.type === "Video") && <Form.Group className="mb-3 radio-btn">
                            <Form.Check
                              type="radio"
                              label="CTA"
                              name="define-url"
                              value="V"
                              checked={ctaCheck}
                              onClick={(e) => {
                                setCtaCheck(true);
                                setScUrl(false);
                                setLpUrl(false);
                                setMsUrl(false);
                                setCusUrl(false);
                                setNoneUrl(false);
                                setUrlOption("V");
                                activeAssetData.Base.url_option = "V";
                              }}
                            />
                          </Form.Group>}

                          {talic_active != "tal" && <Form.Group className="mb-3 radio-btn">
                            <Form.Check
                              type="radio"
                              label="Showcase URL"
                              name="define-url"
                              value={"S"}
                              checked={scUrl}
                              onClick={(e) => {
                                setCtaCheck(false);
                                setScUrl(true);
                                setLpUrl(false);
                                setMsUrl(false);
                                setCusUrl(false);
                                setNoneUrl(false);
                                setUrlOption("S");
                                activeAssetData.Base.url_option = "S";
                              }}
                            />
                          </Form.Group>}
                          
                          <Form.Group className="">
                            <Form.Check
                              type="radio"
                              label="None"
                              name="define-url"
                              value="N"
                              checked={noneUrl}
                              onClick={(e) => {
                                setCtaCheck(false);
                                setScUrl(false);
                                setLpUrl(false);
                                setMsUrl(false);
                                setCusUrl(false);
                                setNoneUrl(true);
                                setUrlOption(null);
                                activeAssetData.Base.url_option = "";
                              }}
                            />
                          </Form.Group>



                          {lpUrl && (
                            <Form.Select
                              className="mb-4"
                              aria-label="select-landing-page"
                              onChange={(e) => {
                                secureUrl =
                                  landingSecureUrl +
                                  e.target.value;
                                activeAssetData.Base.secure_url = secureUrl;
                                setActiveAssetDefineUrl(e.target.value);
                              }}
                              value={activeAssetDefineUrl}
                            >
                              <option value="">Select Landing URL</option>
                              {landingpageUrlList &&
                                landingpageUrlList.map((lp, index) => {
                                  return (
                                    <>
                                      <option
                                        key={index}
                                        id={lp.publish_page_id}
                                        value={lp.publish_page_name}
                                        selected={(selectedDefineUrl === lp.publish_page_name) ? true : false}
                                      >
                                        {lp.publish_page_name}
                                      </option>
                                    </>
                                  );
                                })}
                            </Form.Select>
                          )}


                          {ctaCheck && (
                            <Form.Select
                              className="mb-4"
                              aria-label="select-landing-page"
                              onChange={(e) => {
                                setSelectedVideoType(e.target.value);
                                activeAssetData.Base.video_type = e.target.value
                              }}
                              value={activeAssetData?.Base?.video_type ? activeAssetData.Base.video_type : selectedVideoType}
                            >
                              <option value="">Select CTA</option>
                              {cta_asset_list &&
                                cta_asset_list.map((lp, lp_index) => {
                                  return (
                                    <>
                                      <option
                                        key={lp_index}
                                        id={lp.id}
                                        value={lp.id}
                                      >
                                        {lp.ctaName}
                                      </option>
                                    </>
                                  );
                                })}
                            </Form.Select>
                          )}


                          {scUrl && (

                            <Form.Select
                              className="mb-4"
                              aria-label="select-landing-page"
                              onChange={(e) => {
                                secureUrl =
                                  showcaseSecureUrl +
                                  e.target.value;
                                activeAssetData.Base.secure_url = secureUrl;
                                setActiveAssetDefineUrl(e.target.value);
                              }}
                              value={activeAssetDefineUrl}
                            >
                              <option value="">Select Showcase URL</option>
                              {showcaseUrlList &&
                                showcaseUrlList.map((sc, index) => {
                                  return (
                                    <>
                                      <option
                                        key={index}
                                        id={sc.id}
                                        value={sc.case_study_title}
                                        selected={(selectedDefineUrl === sc.case_study_title) ? true : false}
                                      >
                                        {sc.case_study_title}
                                      </option>
                                    </>
                                  );
                                })}
                            </Form.Select>
                          )}

                          {msUrl && (
                            <Form.Group className="mb-3 ms-cus-url">

                              <Form.Control
                                type="text"
                                ref={msRef}
                                autoComplete="off"
                                // required
                                value={micrositeUrl}
                                onChange={(e) => {
                                  activeAssetData.Base.secure_url = e.target.value;
                                }}
                                className="mb-4"
                              />
                            </Form.Group>
                          )}
                          {cusUrl && (
                            <Form.Group className="ms-cus-url">
                              <Form.Control
                                type="text"
                                ref={cusRef}
                                autoComplete="off"
                                // required
                                //placeholder="Enter Custom URL"
                                value={customUrl}
                                onChange={(e) => {
                                  customUrl = e.target.value;
                                  activeAssetData.Base.secure_url = e.target.value;
                                }}
                                className="mb-4"
                              />
                            </Form.Group>
                          )}
                        </div>
                      </div>
                    )}

                  {ctaCheck && <div className="smartFolder-multiSelect m-lg-0 mb-3 custom-flex">
                    <Form.Label>
                      Form Type
                    </Form.Label>
                    <br />
                    <div className="url-max-size">
                      <Form.Group className="mb-3 radio-btn">
                        <Form.Check
                          type="radio"
                          label="Sequential Form"
                          name="Sequential_Form"
                          value="sequential_form"
                          checked={formType === "sequential_form" || activeAssetData.Base.form_type === "sequential_form"}
                          onClick={(e) => {
                            setFormType(e.target.value);
                            activeAssetData.Base.form_type = e.target.value;
                          }
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3 radio-btn">
                        <Form.Check
                          type="radio"
                          label="Single Form"
                          name="Single_Form"
                          value="single_form"
                          checked={formType === "single_form" || activeAssetData.Base.form_type === "single_form"}
                          onClick={(e) => {
                            setFormType(e.target.value);
                            activeAssetData.Base.form_type = e.target.value;
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>}

                  {
                    (selectedAssetType.type === "Brochure" ||
                      selectedAssetType.type === "Video" ||
                      selectedAssetType.type === "Infographics" ||
                      selectedAssetType.type === "Infographic") && (
                      <>
                        <Form.Group className="caption mb-3 d-flex justify-content-between align-items-center gap-2 smartFolder-multiSelect" controlId="formTextarea">
                          <Form.Label className="mt-2">
                            Description
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={7}
                            ref={descRef}
                            value={
                              activeAssetData.Base &&
                              activeAssetData.Base.description
                            }
                            onChange={(e) => {
                              setActiveAssetDesc(e.target.value);
                              activeAssetData.Base.description = e.target.value;
                            }}
                          />
                        </Form.Group>

                        {(selectedAssetType.type === "Brochure" ||
                          selectedAssetType.type === "Infographics" ||
                          selectedAssetType.type === "Infographic" ||
                          !selectedAssetType.type === "Video") && (
                            < div className="smartFolder-multiSelect">
                              <Form.Label className="mt-2">
                                Content Type
                              </Form.Label>
                              <Form.Select
                                className="greeting mb-3"
                                aria-label="select-type"
                                onChange={(e) => {
                                  setSelectedContentType(e.target.value);
                                  activeAssetData.Base.content_type = e.target.value
                                }}
                                value={activeAssetCatogries ? activeAssetData.Base.content_type : selectedContentType}
                              >
                                <option value={null}>Select Content Type</option>
                                {contentTypeList &&
                                  contentTypeList.map((c, index) => {
                                    let selected = false;
                                    if (activeAssetData.Base) {
                                      if (
                                        c.id ===
                                        parseInt(activeAssetData.Base.content_type)
                                      ) {
                                        selected = true;
                                      }
                                      return (
                                        <>
                                          <option key={index} value={c.id} selected={selected}>
                                            {c.article_type}
                                          </option>
                                        </>
                                      );
                                    } else return "";
                                  })}
                              </Form.Select>
                            </div>
                          )}
                      </>
                    )
                  }

                  {
                    selectedAssetType.type === "Banner" && (
                      <Form.Group className="caption mb-3 d-flex justify-content-between smartFolder-multiSelect" controlId="formTextarea">
                        <Form.Label className="mt-2">
                          Description
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={7}
                          ref={descRef}
                          value={
                            activeAssetData.Base &&
                            activeAssetData.Base.description
                          }
                          onChange={(e) => {
                            setActiveAssetDesc(e.target.value);
                            activeAssetData.Base.description = e.target.value;
                          }}
                        />
                      </Form.Group>
                    )
                  }

                  {/* {(selectedAssetType.article_type === "Social Post" ||
                    selectedAssetType.article_type === "Video" ||
                    selectedAssetType.article_type === "Banners") && (
                      <> */}
                  {
                    (selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                      !selectedAssetType.type === "Video") && (
                      <>
                        {(activeAssetData?.Base?.long_caption || activeAssetData?.Base?.long_caption === "") &&
                          <Form.Group className="mb-3 caption smartFolder-multiSelect" controlId="formTextarea">
                            <Form.Label className="mt-2">
                              Long Caption <span className="mandatory">*</span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              required
                              rows={7}
                              ref={longCaptionRef}
                              value={
                                activeAssetData.Base &&
                                activeAssetData.Base.long_caption
                              }
                              onChange={(e) => {
                                setActiveAssetLongDesc(e.target.value);
                                activeAssetData.Base.long_caption = e.target?.value || "";
                              }}
                            />
                          </Form.Group>}
                        {((activeAssetData.Base && activeAssetData.Base.platform.includes(2)) ||
                          (activeAssetData.Base && activeAssetData.Base.platform.includes(3))) &&
                          <>
                            <Form.Group className="caption mb-3 smartFolder-multiSelect" controlId="formTextarea">
                              <Form.Label className="mt-2">
                                Short Caption <span className="mandatory">*</span>
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={7}
                                required
                                ref={shortCaptionRef}
                                value={
                                  activeAssetData.Base &&
                                  activeAssetData.Base.short_caption
                                }
                                onChange={(e) => {
                                  setActiveAssetShortDesc(e.target.value);
                                  activeAssetData.Base.short_caption = e.target.value;
                                }}

                              />
                            </Form.Group>
                          </>}
                      </>
                    )
                  }

                  {
                    (selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                      selectedAssetType.type === "Video") && (
                      <FormGroup className="mb-3 mt-2 smartFolder-multiSelect">
                        <Form.Label className="mt-2">
                          Greetings
                        </Form.Label>
                        <Form.Select
                          className="mb-3 greeting"
                          aria-label="select-greetings"
                          onChange={(e) => {
                            setSelectedGreeting(e.target.value);
                            activeAssetData.Base.greeting_id = e.target.value
                          }}
                          value={activeAssetCatogries ? activeAssetData.Base.greeting_id : selectedGreeting}
                        >
                          <option value="empty">Select Greetings</option>
                          {greetingList &&
                            greetingList.map((greeting, index) => {
                              let selected = false;
                              if (activeAssetData.Base) {
                                if (
                                  greeting.greetingID ===
                                  parseInt(activeAssetData.Base.greeting_id)
                                ) {
                                  selected = true;
                                }
                                return (
                                  <>
                                    <option key={index}
                                      value={greeting.greetingID}
                                      selected={selected}
                                    >
                                      {greeting.greeting_name}
                                    </option>
                                  </>
                                );
                              } else return "";
                            })}
                        </Form.Select>
                      </FormGroup>
                    )
                  }

                  {
                    business_flag === true && business_need_list?.data && <div className="smartFolder-multiSelect mb-4">
                      <Form.Label className="mt-2">
                        Business Need
                      </Form.Label>
                      <Form.Select
                        className="greeting mb-3"
                        aria-label="select-type"
                        onChange={(e) => {
                          setSelectedBusinessType(e.target.value);
                          activeAssetData.Base.business_need = e.target.value
                        }}
                        value={selectedBusinessType}
                      >
                        <option value={null}>Select Business Need</option>
                        {business_need_list?.data &&
                          business_need_list.data.map((c, index) => {
                            let selected = false;
                            if (activeAssetData.Base) {
                              if (
                                c.id ===
                                parseInt(activeAssetData.Base.business_need)
                              ) {
                                selected = true;
                              }
                              return (
                                <>
                                  <option key={index} value={c.id} selected={selected}>
                                    {c.type}
                                  </option>
                                </>
                              );
                            } else return "";
                          })}
                      </Form.Select>
                    </div>
                  }
                  {/*  </>
                    )} */}
                  {
                    baseGlobalFields.length > 1 ? (
                      <Button
                        variant="info"
                        type="submit"
                        className="save-proceed-btn mt-lg-0 mt-3"
                        disabled={submitBtn}
                        onClick={handleSubmit}
                      >
                        {activeAsset !== baseGlobalFields.length - 1 ? 'Next' : 'Save & Proceed'}
                        <span>
                          <Icon.ChevronRight />
                        </span>
                      </Button>
                    ) :
                      <Button
                        variant="info"
                        type="submit"
                        className="save-proceed-btn mt-lg-0 mt-3"
                        disabled={submitBtn}
                        onClick={submitHandler}
                      >
                        Save &amp; Proceed
                        <span>
                          <Icon.ChevronRight />
                        </span>
                      </Button>
                  }

                </Form >
              </Col >
            </Row >
          </Col >
          <Col className="col-lg-6 col-md-12 my-lg-0 my-2 mx-lg-0 mx-2 ">
            {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
              selectedAssetType.type === "Banner") && (
                <div className="post-img">
                  {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image") && (
                    <img
                      src={
                        activeAssetData?.Base?.post_image?.base64
                      }
                      alt="img"
                    />
                  )}
                  {selectedAssetType.type === "Banner" && (
                    <img
                      src={
                        activeAssetData.Base &&
                        activeAssetData.Base.banner_image.base64
                      }
                      alt="img"
                    />
                  )}
                </div>
              )}
            {(selectedAssetType.type === "Brochure" ||
              selectedAssetType.type === "Infographics" ||
              selectedAssetType.type === "Infographic") && (
                // <PDFViewer
                //   document={{
                //     base64: `"${activeAssetData.Base.post_image.base64}"`,
                //   }}
                // />
                <div className="position-relative"
                >
                  <div className="post-img">
                    <span className="file-type-name">
                      {activeAssetData.Base &&
                        activeAssetData.Base.document.file.name}
                    </span>
                    <img
                      src={pdfImgUrl}
                      alt="img"
                    />
                  </div>
                  {/* <span className="file-type-icon">
                  <Icon.FilePdf />
                </span> 
                <span className="file-type-name">
                  {activeAssetData.Base &&
                    activeAssetData.Base.document.file.name}
                </span> */}
                </div>
              )}
            {selectedAssetType.type === "Video" && (
              <div className="position-relative"
              >
                <div className="post-img">
                  {_.has(activeAssetData.Base, "video_url") ? (
                    <>
                      {activeAssetData.Base && activeAssetData.Base.video_url && (
                        <span className="file-type-name">
                          {activeAssetData.Base && activeAssetData.Base.video_url}
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {activeAssetData.Base &&
                        activeAssetData.Base.video.file.name && (
                          <span className="file-type-name">
                            {activeAssetData.Base &&
                              activeAssetData.Base.video.file.name}
                          </span>
                        )}
                    </>
                  )}
                  <img className="vedio-img"
                    src={videoImgUrl}
                    alt="img"
                  />
                </div>
                {/* <span className="file-type-icon">
                  <Icon.FilePlay />
                </span> */}
              </div>
            )}
            <ul className="base-action-btn">
              {/* <li className="icon">
                <Icon.Files />
              </li> */}
              {/* <li className="icon">
                <Icon.Trash />
              </li> */}
              {/* <li className="icon">
                <Icon.Image />
              </li>
              <li className="btn">Create a Variation</li> */}
            </ul>
          </Col>
        </Row >
      </Card >

      <Modal show={tagModal} onHide={() => setTagModal(false)}>
        <Modal.Header closeButton> {/* style={{ border: "none" }} */}
          <div className="fw-bold h5">Add New HashTag</div>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Label>HashTag Name</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              name="tag-input"
              required
              placeholder="Name"
              onChange={(e) => setTagName(e.target.value)}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer> {/* style={{ border: "none" }} */}
          <Button
            variant="info"
            onClick={() => addHashTag()}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={personalizeModal} onHide={() => setPersonalizeModal(false)}>
        <Modal.Header closeButton > {/* style={{ border: "none" }} */}
          <div className="fw-bold h5">Add Personalize Name</div>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Label>Personalize Video Name</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              name="tag-input"
              required
              placeholder="Personalize Video Name"
              onChange={(e) => setPersonalizeName(e.target.value)}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer > {/* style={{ border: "none" }} */}
          <Button
            variant="info"
            onClick={() => addPersonalizeName()}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={personalizeDeleteModal} onHide={() => setPersonalizeDeleteModal(false)}>
        <Modal.Header closeButton > {/* style={{ border: "none" }} */}
          <div className="fw-bold h5">Delete Personalize Name</div>
        </Modal.Header>
        <Modal.Body>
          {personalizeList?.preVideoList?.length > 0 && personalizeList.preVideoList.map((p_v) => {
            return (
              <>
                {p_v.deleted_by === 1 && <div className="d-flex">
                  <input
                      className="url-input"
                      type="checkbox"
                      onChange={(e) => handleUrlCheckboxChange(e,p_v)}
                      checked={selectedPersonalizeCheck.includes(p_v)}
                    />
                  <div style={{paddingLeft:"10px"}}>{ p_v.greeting_name }</div>
                </div>}
              </>
            );
          }) }
        </Modal.Body>
        <Modal.Footer > {/* style={{ border: "none" }} */}
          <Button
            variant="info"
            onClick={() => deletePersonalizeName()}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
};

export default Base;
