import axios from "axios";

let tempUrl = `${window.location.href}`;
let setUrl;
//let testUrl = "https://app.sppreprod.in/react-ui/build/AssetLibrary"
if (tempUrl.search("localhost") > 0) {
  // setUrl = "http://localhost/sp-bfsi/";
  // setUrl = "https://spbfsi.com/";
  setUrl = "https://app.sppreprod.in";
  // setUrl = "https://app.spdevmfp.com/";
} else if (tempUrl.search("app.spdevmfp.com") > 0) {
  setUrl = "https://app.spdevmfp.com/";
} else if (tempUrl.search("app.sptestmfp.com") > 0) {
  setUrl = "https://app.sptestmfp.com/";
} else {
  setUrl = window.location.href;
  //setUrl = setUrl.replace("react-ui/build/", '');
  setUrl = setUrl.split('/')[0] + "/" + setUrl.split('/')[1] + "/" + setUrl.split('/')[2] + "/";
}

// testUrl = testUrl.split('/')[0] +"/"+ testUrl.split('/')[1] +"/"+ testUrl.split('/')[2] + "/";

// console.log("window.location.href",testUrl);   

export default axios.create({
  baseURL: setUrl,
  headers: { "Access-Control-Allow-Origin": "*" }
});