/* eslint-disable */
import storeApis from "../../api/storeApi";
import "react-toastify/dist/ReactToastify.min.css";
import * as types from "../../constants/ActionTypes";
import _ from "lodash";
import { toast } from "react-toastify";
import Toast from "../../components/shared/Toast";

const LOGOUT_URL = "framework/api/logout";
const MICROSITE_SEQUENCE = "/framework/api/reordercontentorder";
const FORGOT_PASSWORD_URL = "framework/api/forgot-password";
const GENERATE_PASSWORD_URL = "framework/api/genratePass";
const TOP_MENU_URL = "/framework/api/topMenu";
const ASSET_LIBRARY_URL = "/framework/api/assetLibraryList";
const PUBLISH_CONTENT_URL = "/framework/api/publishContent";
const DELETE_CONTENT_URL = "/framework/api/deleteContent";
const DUPLICATE_CONTENT_URL = "/framework/api/duplicateContent";
const EDIT_CONTENT_URL = "/framework/api/editContent";
const FETCH_CONTENT_DETAILS_URL = "/framework/api/fetchContent";
const SELECT_ASSET_TYPE_URL = "/framework/api/assetTypeList";
const CAMPAIGN_URL = "/framework/api/campaignDropdown";
const GREETING_URL = "/framework/api/greetingDropdown";
const LANDINGPAGE_URL = "/framework/api/landingpageDropdown";
const SHOWCASE_URL = "/framework/api/showcaseDropdown";
const MICROSITE_URL = "/framework/api/urlDetails";
const GLOBALFIELDS_SAVE_URL = "/framework/api/saveContent";
const ACCESS_URL = "/framework/api/accessDropdown";
const PUBLISH_URL = "/framework/api/publishContent";
const CONTENT_TYPE_URL = "/framework/api/contentTypeDropdown";
const KEYWORDS_URL = "/framework/api/keywords";
const CTA_URL = "/framework/api/CTADropdown";
const SEARCH_SUGGESTION_URL = "/framework/api/globalSearchSuggestionList";
const REMOVE_SUGGESTION_URL = "/framework/api/removeSuggestion";
const FETCH_ASSET_LIBRARY_SUBCATEGORY = "/framework/api/subCategoryDropdown";
const FETCH_ASSET_LIBRARY_LEAD_GENERATION_DROPOWN = "/framework/api/leadGeneartionDropdown";
const ADD_NEW_HASHTAG = "/framework/api/addHashtag";
const GET_COMPANY_LOGO = "/framework/api/companyLogo"
const MAIN_CONTENT = "/framework/api/allContentUpdate";
//SET Loader Status
// export const SetLoaderStatus = () => {
//   return (dispatch) => {
//     dispatch({ type: "TOGGLE_LOADING", payload: false });
//   };
// };

const notify = (msg, status) => {
  if (status === "success") {
    if (!toast.isActive(msg)) {
      toast.success(msg, {
        toastId: msg,
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } else {
    toast.error(msg, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

//SET Unauthorization Status
export const setAssetLibUnauthorized = () => {
  //console.log("setUnauthorizedCALLED");
  return (dispatch) => {
    dispatch({ type: "UNAUTHORIZED_ASSET_LIBRARY", payload: null });
  };
};

export const setFilesAction = (files) => {
  //console.log("setUnauthorizedCALLED");
  return (dispatch) => {
    dispatch({ type: "SET_FILES", payload: files });
  };
};

//SET Unauthorization Status
export const setCurrentAssetDetailNull = () => {
  //console.log("setUnauthorizedCALLED");
  return (dispatch) => {
    dispatch({ type: "FETCH_ASSET_CONTENT_DETAILS", payload: null });
  };
};

export const backToPreviousRoute = (url) => {
  return async (dispatch) => {
    await dispatch({ type: "SET_PREVIOUS_URL", payload: url });
  };
};

export const removeBroucher = (ind) => {
  return async (dispatch) => {
    await dispatch({ type: "REMOVE_FAILURE", payload: ind });
  };
};


export const fetchPhpSession = (session) => {
  //console.log("setUnauthorizedCALLED");
  return (dispatch) => {
    dispatch({ type: "FETCH_PHP_SESSION", payload: session });
  };
};

export const setUnauthorizedAssetUpload = () => {
  // console.log("setUnauthorizedAssetUploadCALLED");
  return (dispatch) => {
    dispatch({ type: "UNAUTHORIZED_ASSET_UPLOAD", payload: null });
  };
};

export const setTitleStatus = (value) => {
  // console.log("setUnauthorizedAssetUploadCALLED");
  return (dispatch) => {
    dispatch({type: "CAMPAIGN_TITLE_STATUS",payload: value});
  };
};

export const uploadFile = (authToken, file, index, handleErrorData) => {
  console.log(file);
  return async (dispatch) => {
    dispatch({ type: "START_UPLOAD", payload: index });
    try {
      const response = await storeApis.post(
        '/framework/api/checkpdfstatus',
        file,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        handleErrorData(response.data.message, index);
        if (response.data.statusCode != 200) {
          dispatch({ type: "UPLOAD_FAILURE", payload: index });
        } else {
          dispatch({ type: "UPLOAD_SUCCESS", payload: index });
        }
      } else {
        dispatch({ type: "UPLOAD_FAILURE", payload: index });
      }
    } catch (err) {
        dispatch({ type: "UPLOAD_FAILURE", payload: index });
      dispatch({ type: "UNAUTHORIZED", payload: err.response.status });
    }
  };
};

//Logout
export const logout = (authToken) => {
  return async (dispatch) => {
    try {
      const response = await storeApis.post(
        LOGOUT_URL,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        notify("Logged out successfully!", "success");
        // dispatch({type: types.FETCH_COMPANY_LOGO, payload: null});
        //Toast(response.data.data.message, 'S');
        //console.log("response>>>",response);
        // dispatch({
        //   type: types.FETCH_TOP_MENU,
        //   payload: response.data.data.message,
        // });
      }
    } catch (err) {
      //dispatch({ type: "UNAUTHORIZED", payload: err.response.status });
      //console.log("err>>>",err);
    }
  };
};


//Forgot Password
export const downloadFile = (authToken, url) => {
  return async (dispatch) => {
    try {
      const response = await storeApis.post(
        "framework/api/download",
        { url: url },
        {
          headers: { 
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            // 'Content-Type': "application/json",
          },
        }
      );
      if (response.status === 200) {
        //notify("Logged out successfully!", "success");
        // Toast(response.data.data.message, 'S');
      }
    } catch (err) {
      //dispatch({ type: "UNAUTHORIZED", payload: err.response.status });
      //console.log("err>>>", err);
      Toast(err.message, 'E');
    }
  };
};

//Forgot Password
export const forgotPassword = (authToken, emailId) => {
  return async (dispatch) => {
    try {
      const response = await storeApis.post(
        FORGOT_PASSWORD_URL,
        { email: emailId },
      );
      if (response.status === 200) {
        //notify("Logged out successfully!", "success");
        Toast(response.data.data.message, 'S');
        // console.log("response>>>", response);
        dispatch({
          type: types.FETCH_TOP_MENU,
          payload: response.data.data.message,
        });
      }
    } catch (err) {
      //dispatch({ type: "UNAUTHORIZED", payload: err.response.status });
      //console.log("err>>>", err);
      Toast(err.message, 'E');
    }
  };
};

//Fetch Reset Password Details
export const fetchResetPasswordDetails = (urlLink) => {
  // console.log("urlLink>>>", urlLink);
  return async (dispatch) => {
    try {
      const response = await storeApis.post(
        urlLink,
      );
      if (response.status === 200) {
        //notify("Logged out successfully!", "success");
        //Toast(response.data.data.message, 'S');
        // console.log("response>>>", response);
        dispatch({
          type: types.FETCH_RESET_PASSWORD_DETAILS,
          payload: response.data,
        });
      }
    } catch (err) {
      //dispatch({ type: "UNAUTHORIZED", payload: err.response.status });
      // console.log("err>>>", err);
      //Toast(err.message, 'E');
    }
  };
};

//-----------------------------------------------------------------------------------------//

//Fetch Top Menu

export const fetchTopMenus = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        TOP_MENU_URL,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            // 'Content-Type': "application/json",
          },
        }
      );
      if (response.status === 200) {
        dispatch({ type: types.FETCH_TOP_MENU, payload: response.data.data });
        // let data1 ={ logo: response.data.logo_url, favicon: response.data.favicon, title:response.data.title }
        // dispatch({ type: types.FETCH_COMPANY_LOGO, payload: data1 });
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
      //dispatch({ type: types.FETCH_TOP_MENU, payload: response.data.data });
      //dispatch({ type: "TOGGLE_LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "UNAUTHORIZED", payload: err.response.status });
    }
  };
};


//Fetch login page company Logo

export const fetchCompanyLogo = () => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        GET_COMPANY_LOGO,
        {},
        {
          headers: {
            Accept: "application/json",
            //Authorization: `Bearer ${authToken}`,
            // 'Content-Type': "application/json",
          },
        }
      );
      if (response.status === 200) {
        await dispatch({ type: types.TALIC_ACTIVE, payload: response?.data?.response?.new_login || "" })
        await dispatch({ type: types.FETCH_COMPANY_LOGO, payload: response.data.response });
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
      //dispatch({ type: types.FETCH_TOP_MENU, payload: response.data.data });
      //dispatch({ type: "TOGGLE_LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "UNAUTHORIZED", payload: err?.response?.status });
    }
  };
};



//-------------------------------------ASSET-LIBRARY-ACTIONS-------------------------------------//

//Search Suggestion List
export const searchSuggestionList = (authToken) => {
  return async (dispatch) => {
    //dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        SEARCH_SUGGESTION_URL,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.SEARCH_SUGGESTION_LIST,
            payload: response.data,
          });
        }

        //dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//Remove Suggestion List
export const removeSuggestionList = (authToken, id) => {
  return async (dispatch) => {
    //dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        REMOVE_SUGGESTION_URL,
        { id: id },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.REMOVE_SUGGESTION_LIST,
            payload: response.data.response,
          });
          //dispatch({ type: "TOGGLE_LOADING", payload: false });
          notify(response.data.response, "success");
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
      notify(err.response, "error");
    }
  };
};

//Search Asset Library Content List -- NA
export const searchAssetLibraryContentList = (authToken, searchKey, startLimit) => {
  return async (dispatch) => {
    if (authToken) {
      dispatch({ type: "TOGGLE_LOADING", payload: true });
      try {
        const response = await storeApis.post(
          ASSET_LIBRARY_URL,
          {
            action: "contentList",
            global_search: searchKey,
            quick_filter_value: null,
            category_filter: null,
            status_filter: null,
            sort_filter: null,
            startlimit: startLimit ? startLimit : 0,
            endlimit: 20
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status === 200) {
          if (response.data.statusCode == "403") {
            if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
              dispatch({
                type: "UNAUTHORIZED_ASSET_LIBRARY",
                payload: response.data.statusCode,
              });
            } else {
              Toast(response.data.message, "E");
            }
          } else {
            dispatch({
              type: types.SEARCH_ASSET_LIBRARY_CONTENT_LIST,
              payload: response.data.response,
            });
            dispatch({ type: types.SET_GLOBAL_SEARCH_KEY, payload: searchKey });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
          }
        }
      } catch (err) {
        dispatch({
          type: "UNAUTHORIZED_ASSET_LIBRARY",
          payload: err.response.status,
        });
      }
    } else {
      dispatch({ type: types.SET_GLOBAL_SEARCH_KEY, payload: null });
    }
  };
};

//GlobalFilter Asset Library Content List
export const globalFilterAssetLibraryContentList = (authToken, searchKey, pFilterKey, qFilterKey, cFilterKey, sFilterKey, svFilterKey, folderId, folderType, folderName, startLimit,endlimit) => {
  return async (dispatch) => {
    if (authToken) {
      dispatch({ type: "TOGGLE_LOADING", payload: true });
      dispatch({ type: "LIST_LOADING", payload: true });
      try {
        const response = await storeApis.post(
          ASSET_LIBRARY_URL,
          {
            action: "contentList",
            global_search: searchKey,
            sub_category_filter: pFilterKey,
            quick_filter_value: qFilterKey ? qFilterKey.id : null,
            category_filter: cFilterKey,
            status_filter: sFilterKey,
            sort_value: (svFilterKey === "1" || svFilterKey === "2") ? "created_at" : (svFilterKey === "3" || svFilterKey === "4") ? "title" : null,
            sort_order: (svFilterKey === "1" || svFilterKey === "3") ? "ASC" : (svFilterKey === "2" || svFilterKey === "4") ? "DESC" : null,
            folder_id: folderId,
            smart_folder: folderType,
            startlimit: startLimit ? startLimit : 0,
            endlimit: endlimit ? endlimit : 20
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status === 200) {

          if (response.data.statusCode == "403") {
            if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
              dispatch({
                type: "UNAUTHORIZED_ASSET_LIBRARY",
                payload: response.data.statusCode,
              });
            } else {
              Toast(response.data.message, "E");
            }
          } else {
            dispatch({
              type: types.GLOBAL_FILTER_ASSET_LIBRARY_CONTENT_LIST,
              payload: response.data.response,
            });
            console.log("gsearch if action");
            dispatch({ type: types.SET_GLOBAL_SEARCH_KEY, payload: searchKey });
            dispatch({ type: types.SET_GLOBAL_QFILTER_KEY, payload: qFilterKey ? qFilterKey : null });
            dispatch({ type: types.SET_GLOBAL_CFILTER_KEY, payload: cFilterKey ? cFilterKey : null });
            dispatch({ type: types.SET_GLOBAL_PFILTER_KEY, payload: pFilterKey ? pFilterKey : null });
            dispatch({ type: types.SET_GLOBAL_SFILTER_KEY, payload: sFilterKey });
            dispatch({ type: types.SET_GLOBAL_SOFILTER_KEY, payload: svFilterKey });
            dispatch({ type: "FOLDER", payload: { "id": folderId, "value": folderType, "name": folderName } });
            dispatch({ type: "LIST_LOADING", payload: false });
            dispatch({ type: "TOGGLE_LOADING", payload: false });

            if (folderId && response.data.response.folder_data) {
              dispatch({ type: types.SET_FOLDER_DETAILS_DATA, payload: response.data.response.folder_data });
            }

          }
        }
      } catch (err) {
        dispatch({
          type: "UNAUTHORIZED_ASSET_LIBRARY",
          payload: err.response.status,
        });
      }
    } else {
      dispatch({ type: types.SET_GLOBAL_SEARCH_KEY, payload: null });
      dispatch({ type: types.SET_GLOBAL_QFILTER_KEY, payload: null });
      dispatch({ type: types.SET_GLOBAL_CFILTER_KEY, payload: null });
      dispatch({ type: types.SET_GLOBAL_PFILTER_KEY, payload: null });
      dispatch({ type: types.SET_GLOBAL_SFILTER_KEY, payload: null });
      dispatch({ type: types.SET_GLOBAL_SOFILTER_KEY, payload: null });
      dispatch({ type: "FOLDER", payload: { "id": null, "value": null, "name": null } });
    }

    if (qFilterKey) {
      dispatch({ type: "QUICK_FILTERS", payload: { "id": qFilterKey.id, "value": qFilterKey.value } });
    } else {
      dispatch({ type: "QUICK_FILTERS", payload: { "id": null, "value": null } });
    }

    // if(cFilterKey) {
    //   dispatch({ type: "FILTER", payload: {"filterType": "category", "filterValue": cFilterKey} });
    // } else {
    //   dispatch({ type: "FILTER", payload: {"filterType": null, "filterValue": null} });
    // }

  };
};

//Fetch Asset Library Content List
export const fetchAssetLibraryContentList = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ASSET_LIBRARY_URL,
        { action: "contentList", startlimit: 0, endlimit: 20 },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_ASSET_LIBRARY_CONTENT_LIST,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//Fetch Asset Library Folder List
export const fetchAssetLibraryFolderList = (authToken) => {
  return async (dispatch) => {
    // dispatch({ type: "TOGGLE_LOADING", payload: true });
    dispatch({ type: types.LIST_LOADING, payload: true });
    try {
      const response = await storeApis.post(
        ASSET_LIBRARY_URL,
        { action: "contentList", startlimit: 0, endlimit: 20 },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        console.log('fetchAssetLibraryFolderList');
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_ASSET_LIBRARY_FOLDER_LIST,
            payload: response.data.response.folders,
          });
          // dispatch({ type: "TOGGLE_LOADING", payload: false });
          dispatch({ type: types.LIST_LOADING, payload: false });
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//Fetch Asset Library Category List 
export const fetchAssetLibraryCategoryList = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ASSET_LIBRARY_URL,
        { action: "category" },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_ASSET_LIBRARY_CATEGORY_LIST,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//Fetch Asset Library Product List 
export const fetchAssetLibraryProductList = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ASSET_LIBRARY_URL,
        { action: "sub_category" },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_ASSET_LIBRARY_PRODUCT_LIST,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//Fetch Asset Library Sub Category List
export const fetchAssetLibrarySubCategoryList = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        FETCH_ASSET_LIBRARY_SUBCATEGORY,
        null,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_ASSET_LIBRARY_SUBCATEGORY_LIST,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//Fetch Asset Library Lead Generation List
export const fetchAssetLibraryLeadGenerationList = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        FETCH_ASSET_LIBRARY_LEAD_GENERATION_DROPOWN,
        null,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_ASSET_LIBRARY_LEAD_GENERATION_LIST,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//Fetch Asset Library Hashtag List
export const fetchAssetLibraryHashtagList = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ASSET_LIBRARY_URL,
        { action: "hashtags" },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_ASSET_LIBRARY_HASHTAG_LIST,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//Fetch Asset Library Quick Filter List
export const fetchAssetLibraryQuickFilters = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    dispatch({ type: "FILTER_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ASSET_LIBRARY_URL,
        { action: "quick_filter_list" },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_ASSET_LIBRARY_QUICK_FILTERS,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
          dispatch({ type: "FILTER_LOADING", payload: false });
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//Set Asset Library Quick Filter -- NA
export const setAssetLibraryQuickFilter = (authToken, id, value, startLimit, gsearch) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      if (value !== "All") {
        const response = await storeApis.post(
          ASSET_LIBRARY_URL,
          {
            action: "contentList",
            global_search: gsearch,
            quick_filter_value: id,
            category_filter: null,
            status_filter: null,
            sort_filter: null,
            startlimit: startLimit ? startLimit : 0,
            endlimit: 20,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status === 200) {
          if (response.data.statusCode == "403") {
            if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
              dispatch({
                type: "UNAUTHORIZED_ASSET_LIBRARY",
                payload: response.data.statusCode,
              });
            } else {
              Toast(response.data.message, "E");
            }
          } else {
            dispatch({
              type: types.SET_ASSET_LIBRARY_QUICK_FILTER,
              payload: response.data.response,
            });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "QUICK_FILTERS", payload: { "id": id, "value": value } });
          }
        }
      } else {
        const response = await storeApis.post(
          ASSET_LIBRARY_URL,
          {
            action: "contentList",
            quick_filter_value: id,
            global_search: gsearch,
            category_filter: null,
            status_filter: null,
            sort_filter: null,
            startlimit: startLimit ? startLimit : 0,
            endlimit: 20,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status === 200) {
          if (response.data.statusCode == "403") {
            if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
              dispatch({
                type: "UNAUTHORIZED_ASSET_LIBRARY",
                payload: response.data.statusCode,
              });
            } else {
              Toast(response.data.message, "E");
            }
          } else {
            dispatch({
              type: types.FETCH_ASSET_LIBRARY_CONTENT_LIST,
              payload: response.data.response,
            });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "QUICK_FILTERS", payload: { "id": null, "value": null } });
          }
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//Set Asset Library Filter -- NA
export const setAssetLibraryFilter = (authToken, type, value, startLimit) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ASSET_LIBRARY_URL,
        {
          action: "filter",
          type: type,
          value: value,
          startlimit: startLimit,
          endlimit: 20,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.SET_ASSET_LIBRARY_CATEGORY_FILTER,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//Set Asset Library Category Filter -NA
export const setAssetLibraryCategoryFilter = (authToken, category, startLimit) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      if (category !== "All") {
        const response = await storeApis.post(
          ASSET_LIBRARY_URL,
          // {
          //   action: "filter",
          //   type: "category",
          //   value: category,
          //   startlimit: 0,
          //   endlimit: 20,
          // },
          { category_filter: category, startlimit: startLimit ? startLimit : 0, endlimit: 20 },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status === 200) {
          if (response.data.statusCode == "403") {
            if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
              dispatch({
                type: "UNAUTHORIZED_ASSET_LIBRARY",
                payload: response.data.statusCode,
              });
            } else {
              Toast(response.data.message, "E");
            }
          } else {
            dispatch({
              type: types.SET_ASSET_LIBRARY_CATEGORY_FILTER,
              payload: response.data.response,
            });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "FILTER", payload: { "filterType": "category", "filterValue": category } });
            dispatch({ type: "QUICK_FILTERS", payload: { "id": null, "value": null } });
          }
        }
      } else {
        const response = await storeApis.post(
          ASSET_LIBRARY_URL,
          { action: "contentList", startlimit: 0, endlimit: 20 },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status === 200) {
          if (response.data.statusCode == "403") {
            if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
              dispatch({
                type: "UNAUTHORIZED_ASSET_LIBRARY",
                payload: response.data.statusCode,
              });
            } else {
              Toast(response.data.message, "E");
            }
          } else {
            dispatch({
              type: types.FETCH_ASSET_LIBRARY_CONTENT_LIST,
              payload: response.data.response,
            });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "FILTER", payload: { "filterType": null, "filterValue": null } });
          }
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//Set Asset Library Status Filter
export const setAssetLibraryStatusFilter = (authToken, status) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      if (status !== "All") {
        const response = await storeApis.post(
          ASSET_LIBRARY_URL,
          {
            action: "filter",
            type: "status",
            value: status,
            startlimit: 0,
            endlimit: 20,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status === 200) {
          if (response.data.statusCode == "403") {
            if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
              dispatch({
                type: "UNAUTHORIZED_ASSET_LIBRARY",
                payload: response.data.statusCode,
              });
            } else {
              Toast(response.data.message, "E");
            }
          } else {
            dispatch({
              type: types.SET_ASSET_LIBRARY_STATUS_FILTER,
              payload: response.data.response,
            });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "FILTER", payload: { "filterType": "status", "filterValue": status } });
          }
        }
      } else {
        const response = await storeApis.post(
          ASSET_LIBRARY_URL,
          { action: "contentList", startlimit: 0, endlimit: 20 },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status === 200) {
          if (response.data.statusCode == "403") {
            if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
              dispatch({
                type: "UNAUTHORIZED_ASSET_LIBRARY",
                payload: response.data.statusCode,
              });
            } else {
              Toast(response.data.message, "E");
            }
          } else {
            dispatch({
              type: types.FETCH_ASSET_LIBRARY_CONTENT_LIST,
              payload: response.data.response,
            });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "FILTER", payload: { "filterType": null, "filterValue": null } });
            dispatch({ type: "QUICK_FILTERS", payload: { "id": null, "value": null } });
          }
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//Set Asset Library Status Filter
export const setAssetLibrarySortByFilter = (authToken, sortby, startLimit) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      if (sortby === "title") {
        const response = await storeApis.post(
          ASSET_LIBRARY_URL,
          { action: "sortContent", value: "title", order: "ASC", startlimit: startLimit ? startLimit : 0, endlimit: 20 },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status === 200) {
          if (response.data.statusCode == "403") {
            if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
              dispatch({
                type: "UNAUTHORIZED_ASSET_LIBRARY",
                payload: response.data.statusCode,
              });
            } else {
              Toast(response.data.message, "E");
            }
          } else {
            dispatch({
              type: types.SET_ASSET_LIBRARY_SORTBY_FILTER,
              payload: response.data.response,
            });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "SORT_FILTER", payload: sortby });
            dispatch({ type: "QUICK_FILTERS", payload: { "id": null, "value": null } });
          }
        }
      } else if (sortby === "created_at") {
        const response = await storeApis.post(
          ASSET_LIBRARY_URL,
          { action: "sortContent", value: "created_at", order: "ASC", startlimit: startLimit ? startLimit : 0, endlimit: 20 },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status === 200) {
          if (response.data.statusCode == "403") {
            if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
              dispatch({
                type: "UNAUTHORIZED_ASSET_LIBRARY",
                payload: response.data.statusCode,
              });
            } else {
              Toast(response.data.message, "E");
            }
          } else {
            dispatch({
              type: types.SET_ASSET_LIBRARY_SORTBY_FILTER,
              payload: response.data.response,
            });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "SORT_FILTER", payload: sortby });
            dispatch({ type: "QUICK_FILTERS", payload: { "id": null, "value": null } });
          }
        }
      } else {
        const response = await storeApis.post(
          ASSET_LIBRARY_URL,
          { action: "contentList", startlimit: 0, endlimit: 20 },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status === 200) {
          if (response.data.statusCode == "403") {
            if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
              dispatch({
                type: "UNAUTHORIZED_ASSET_LIBRARY",
                payload: response.data.statusCode,
              });
            } else {
              Toast(response.data.message, "E");
            }
          } else {
            dispatch({
              type: types.FETCH_ASSET_LIBRARY_CONTENT_LIST,
              payload: response.data.response,
            });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "SORT_FILTER", payload: null });
          }
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//Set Asset Bookmark
export const setAssetBookmark = (authToken, id, type, action) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ASSET_LIBRARY_URL,
        { action: action, content_id: id, content_type: type },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.SET_ASSET_BOOKMARK,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
          notify(response.data.response, "success");
        }
      }
    } catch (err) {
      notify(err.response, "error");
    }
  };
};

//Set Asset Library Content List Pagination
export const setAssetLibraryContentListPagination = (
  authToken,
  startLimit
) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ASSET_LIBRARY_URL,
        { action: "contentList", startlimit: startLimit, endlimit: 20 },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_ASSET_LIBRARY_CONTENT_LIST,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//Create Asset Folder
export const createAssetFolder = (
  authToken,
  smartFolder,
  categoryList,
  hashtagList,
  name,
  desc
) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ASSET_LIBRARY_URL,
        {
          action: "createFolder",
          smart_folder: smartFolder,
          category: categoryList,
          hashtags: hashtagList,
          name: name,
          description: desc,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.SET_ASSET_BOOKMARK,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
          notify(response.data.response, "success");
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
      notify(err.response, "error");
    }
  };
};

//GET Asset Folder Dropdown List
export const fetchAssetFolderDropdownList = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ASSET_LIBRARY_URL,
        { action: "foldersDropdown" },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_ASSET_FOLDER_DROPDOWN_LIST,
            payload: response.data,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//ADD Asset to Folder
export const addAssetToFolder = (authToken, id, contentList) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ASSET_LIBRARY_URL,
        {
          action: "addFolderContent",
          content: contentList,
          folder_id: id,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.ADD_CONTENT_TO_FOLDER,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
          notify(response.data.message, "success");
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
      notify(err.response, "error");
    }
  };
};

//GET Folder Content List
export const fetchFolderContentList = (authToken, id, type, name, startlimit) => {
  return async (dispatch) => {
    if (authToken) {
      dispatch({ type: "TOGGLE_LOADING", payload: true });
      try {
        const response = await storeApis.post(
          ASSET_LIBRARY_URL,
          {
            action: "folderdata",
            folder_id: id,
            smart_folder: type,
            startlimit: startlimit ? startlimit : 0,
            endlimit: 20
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status === 200) {
          if (response.data.statusCode == "403") {
            if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
              dispatch({
                type: "UNAUTHORIZED_ASSET_LIBRARY",
                payload: response.data.statusCode,
              });
            } else {
              Toast(response.data.message, "E");
            }
          } else {
            dispatch({
              type: types.FETCH_FOLDER_CONTENT_LIST,
              payload: response.data.response,
            });
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "FOLDER", payload: { "id": id, "value": type, "name": name } });
          }
        }
      } catch (err) {
        dispatch({
          type: "UNAUTHORIZED_ASSET_LIBRARY",
          payload: err.response.status,
        });
      }
    } else {
      dispatch({ type: "FOLDER", payload: { "id": null, "value": null, "name": null } });
    }
  };
};

//Edit Folder Name
export const editFolderName = (authToken, id, type, name) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ASSET_LIBRARY_URL,
        {
          action: "createFolder",
          id: id,
          smart_folder: type,
          name: name,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.EDIT_FOLDER_NAME,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
          notify("Folder name updated successfully!", "success");
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
      notify(err.response, "error");
    }
  };
};

//PUBLISH Asset Content
export const publishContent = (authToken, id, type, status) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        PUBLISH_CONTENT_URL,
        {
          content: { [type]: [id] },
          status: status,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.PUBLISH_ASSET_CONTENT,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
          notify(response.data.message, "success");
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
    }
  };
};

//DELETE Asset Content
export const deleteContent = (authToken, id, type) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        DELETE_CONTENT_URL,
        {
          action: "delete",
          content: { [type]: [id] },
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.DELETE_ASSET_CONTENT,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
          notify(response.data.message, "success");
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
      notify(err.response, "error");
    }
  };
};

//DELETE Asset Bulk Content
export const deleteBulkContent = (authToken, contentList) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        DELETE_CONTENT_URL,
        {
          action: "delete",
          content: contentList,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.DELETE_ASSET_BULK_CONTENT,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
          notify(response.data.message, "success");
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
      notify(err.response, "success");
    }
  };
};

//DUPLICATE Asset Content
export const duplicateContent = (authToken, id, type) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        DUPLICATE_CONTENT_URL,
        {
          content: { [type]: [id] },
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.DUPLICATE_ASSET_CONTENT,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
          notify(response.data.message, "success");
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
      notify(err.response, "error");
    }
  };
};

// microsite list sequencing

export const micrositeListSequencing = (authToken, id, position, page) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        MICROSITE_SEQUENCE,
        {
          item_id: id,
          order: page * 20 + position
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_SETUP",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
          dispatch({ type: "TOGGLE_LOADING", payload: false });
        } else {
          await dispatch(globalFilterAssetLibraryContentList(authToken, "", { id: "microsite", value: "microsite" }, null, "1", null, null, null, null, page * 20));
          dispatch({ type: "TOGGLE_LOADING", payload: false });
          Toast(response.data.message, "S");
          // window.scrollTo(0, 0)
        }
      }
    } catch (err) {
      Toast(err.response.message, 'E');
      dispatch({ type: "TOGGLE_LOADING", payload: false });
      dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
    }
  };
};



//EDIT Asset Content
export const editContent = (authToken, newData) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        EDIT_CONTENT_URL,
        newData,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.EDIT_ASSET_CONTENT,
            payload: response.data.response,
          });
          dispatch({ type: "TOGGLE_LOADING", payload: false });
          notify(response.data.message, "success");
        }
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
      notify(err.response, "error");
    }
  };
};

//FETCH Asset Content Details
export const fetchContent = (authToken, content) => {
  return async (dispatch) => {
    if (authToken) {
      dispatch({ type: "TOGGLE_LOADING", payload: true });
      try {
        const response = await storeApis.post(
          FETCH_CONTENT_DETAILS_URL,
          {
            content: content,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status === 200) {
          if (response.data.statusCode == "403") {
            if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
              dispatch({
                type: "UNAUTHORIZED_ASSET_LIBRARY",
                payload: response.data.statusCode,
              });
            } else {
              Toast(response.data.message, "E");
            }
          } else {
            let assetData = response?.data?.response[0]
            let url;
            dispatch({
              type: types.FETCH_ASSET_CONTENT_DETAILS,
              payload: response?.data?.response,
            });
            
            if (assetData?.type === "Social" || assetData?.type === "Image") {
              url = assetData?.thumb_image;
            }
            else if (assetData?.type === "Brochure" ||
              assetData?.type === "Document" ||
              assetData?.type === "Infographics" ||
              assetData?.type === "Infographic") {
              url = assetData?.document_file;
            } else if (assetData?.type === "Microsite") {
              if (assetData?.c_type === "brochure") {
                url = assetData?.content_file;
              } else {
                url = assetData?.content_file;
              }
            } else if (assetData?.type === "Video") {
              url = assetData?.video_mp4;
            } else if (assetData?.type === "Banner") {
              url = assetData?.slider_img;
            } else {
              url = ""
              console.log("content_type missing");
            }
            
            dispatch({
              type: types.UPDATE_MAIN_CONTENT,
              payload: url,
            });
            if (response.data?.response?.length > 0 && response.data.response[0].type == "Emailer") {
              dispatch(setAutoemailStatus(response.data.response[0].html_template));
            }
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: types.SET_ASSET, payload: content });
          }
        }
      } catch (err) {
        dispatch({
          type: "UNAUTHORIZED_ASSET_LIBRARY",
          payload: err.response.status,
        });
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } else {
      dispatch({ type: types.SET_ASSET, payload: null });
    }
  };
};

//-------------------------------------ASSET-UPLOAD-ACTIONS-------------------------------------//

//Fetch Asset Select Type List
export const fetchAssetSelectTypeList = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        SELECT_ASSET_TYPE_URL,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_BUSINESS_FLAG,
            payload: response.data.business_need,
          });
          dispatch({
            type: types.FETCH_VIDEO_CTA_FLAG,
            payload: response.data.video_configuration,
          });
          dispatch({
            type: types.FETCH_LEADERBOARD_FLAG,
            payload: response.data.leaderboard,
          });
          dispatch({
            type: types.FETCH_ASSET_SELECT_TYPE_LIST,
            payload: response.data.response,
          });
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      dispatch({ type: "UNAUTHORIZED_ASSET_UPLOAD", payload: err.response.status });
    }
  };
};

//Fetch Campaign List
export const fetchCampaignList = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        CAMPAIGN_URL,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_CAMPAIGN_LIST,
            payload: response.data.response,
          });
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      dispatch({ type: "UNAUTHORIZED_ASSET_UPLOAD", payload: err.response.status });
    }
  };
};

//Fetch Greeting List
export const fetchGreetingList = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        GREETING_URL,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_GREETING_LIST,
            payload: response.data.response,
          });
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      dispatch({ type: "UNAUTHORIZED_ASSET_UPLOAD", payload: err.response.status });
    }
  };
};

//Fetch Landingpage Urls
export const fetchLandingpageUrls = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        LANDINGPAGE_URL,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_LANDINGPAGE_URLS,
            payload: response.data,
          });
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      dispatch({ type: "UNAUTHORIZED_ASSET_UPLOAD", payload: err.response.status });
    }
  };
};

//Fetch fetchCreateTemplateData Urls
export const fetchCreateTemplateData = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        "/framework/api/emailCampaign",
        {
          action: "create_template"
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_CREATE_TEMPLATE_DATA,
            payload: response.data,
          });
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      dispatch({ type: "UNAUTHORIZED_ASSET_UPLOAD", payload: err.response.status });
    }
  };
};

//Fetch Showcase Urls
export const fetchShowcaseUrls = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        SHOWCASE_URL,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_SHOWCASE_URLS,
            payload: response.data,
          });
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      dispatch({ type: "UNAUTHORIZED_ASSET_UPLOAD", payload: err.response.status });
    }
  };
};

//Fetch Microsite Urls
export const fetchMicrositeUrls = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        MICROSITE_URL,
        { action: "micrositelink" },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_MICROSITE_URLS,
            payload: response.data.response,
          });
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      dispatch({ type: "UNAUTHORIZED_ASSET_UPLOAD", payload: err.response.status });
    }
  };
};

//Set Breadcrumb Status
export const setBreadcrumbStatus = (status) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_BREADCRUMB_STATUS,
      payload: status,
    });
  };
};

export const setAutoEmailAccessPath = (value) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_AUTOEMAIL_PATH,
      payload: value,
    });
  };
};

//Set Autoemail Status
export const setAutoemailStatus = (status) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_AUTOEMAIL_STATUS,
      payload: status,
    });
  };
};

//Set Selected Asset Type
export const setSelectedAssetType = (assetType) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_ASSET_TYPE,
      payload: assetType,
    });
  };
};

//Set Base Upload Data
export const setBaseUploadData = (baseUpload) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_BASE_UPLOAD_DATA,
      payload: baseUpload,
    });
  };
};

//Set Base Upload Data
export const setGlobalFieldsData = (globalFields, selectedCategory, selectedSubCategories, selectedCampaign, selectedHashtag) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_GLOBAL_FIELDS_DATA,
      payload: { globalFields, selectedCategory, selectedSubCategories, selectedCampaign, selectedHashtag },
    });
  };
};

//Set selected categories
export const setSelectedCategories = (categories) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_CATEGORIES,
      payload: categories,
    });
  };
};

//Set selected sub categories
export const setSelectedSubCategories = (subcategories) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_SUBCATEGORIES,
      payload: subcategories,
    });
  };
};

//Set selected campaigns
export const setSelectedCampaigns = (campaigns) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_CAMPAIGNS,
      payload: campaigns,
    });
  };
};

//Set selected hashtags
export const setSelectedHashtags = (hashtags) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_HASHTAGS,
      payload: hashtags,
    });
  };
};

//Set selected greetings
export const setSelectedGreetings = (greeting) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_GREETINGS,
      payload: greeting,
    });
  };
};

//Set selected personalize
export const setSelectedPersonalizes = (personalize) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_PERSONALIZE,
      payload: personalize,
    });
  };
};

//Set selected content type
export const setSelectedContentType = (content) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_CONTENT_TYPE,
      payload: content,
    });
  };
};

//Set selected business type
export const setSelectedBusinessType = (content) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_BUSINESS_TYPE,
      payload: content
    });
  };
};

// set seleected video type
export const setSelectedVideoType = (content) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_VIDEO_TYPE,
      payload: content,
    });
  };
};

//Set selected defineurl
export const setSelectedDefineUrl = (url) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_DEFINE_URL,
      payload: url,
    });
  };
};

//Set selected customurl
export const setCustomUrl = (url) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_CUSTOM_URL,
      payload: url,
    });
  };
};

//Save Global Fields Data
export const saveGlobalFieldsData = (authToken, globalFields) => {
  console.log(globalFields);
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        GLOBALFIELDS_SAVE_URL,
        { data: globalFields },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.SAVE_GLOBAL_FIELDS_DATA,
            payload: response.data.inserted_ids,
          });

          if (response.data.status == "Success") {
            notify("Content saved successfully!", "success");
          }
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      Toast("Something Went Wrong", "E");
      //dispatch({ type: "UNAUTHORIZED", payload: err.response.status });
    }
  };
};

//Fetch Partners Access List
export const fetchPartnersAccessList = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ACCESS_URL,
        { option: 1 },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_PARTNERS_LIST,
            payload: response.data.response,
          });
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      // console.log("ERROR", err);
      dispatch({ type: "UNAUTHORIZED_ASSET_UPLOAD", payload: err.response.status });
    }
  };
};

//Fetch Users Access List
export const fetchUsersAccessList = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ACCESS_URL,
        { option: 2 },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_USERS_LIST,
            payload: response.data.response,
          });
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      // console.log("ERROR", err);
      dispatch({ type: "UNAUTHORIZED_ASSET_UPLOAD", payload: err.response.status });
    }
  };
};

//Set Publish Type
export const setPublishType = (directPublish) => {
  return async (dispatch) => {
    dispatch({
      type: types.DIRECT_PUBLISH_ASSET,
      payload: directPublish,
    });
  };
};

//Set Edit Asset
export const setEditAsset = (editAsset) => {
  return async (dispatch) => {
    dispatch({
      type: types.EDIT_ASSET,
      payload: editAsset,
    });
  };
};

//Set Selected Asset Publish
export const setSelectedAssetPublish = (asset) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_ASSET_PUBLISH,
      payload: asset,
    });
  };
};

//Publish Asset
export const publishAsset = (authToken, data) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(PUBLISH_URL, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.PUBLISH_ASSET,
            payload: response.data.response,
          });
          notify(response.data.message, "success");
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      // console.log("ERROR", err);
      dispatch({ type: "UNAUTHORIZED_ASSET_UPLOAD", payload: err.response.status });
      notify(err.response.message, "error");
    }
  };
};

//Fetch Content Type List
export const fetchPersonalizeList = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        "/framework/api/personalizeVideoList",
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_PERSONALIZE_LIST,
            payload: response.data.response,
          });
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      // console.log("ERROR", err);
      dispatch({ type: "UNAUTHORIZED_ASSET_UPLOAD", payload: err.response.status });
    }
  };
};

//Fetch Content Type List
export const fetchContentTypeList = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        CONTENT_TYPE_URL,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_CONTENT_TYPE_LIST,
            payload: response.data.response,
          });
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      // console.log("ERROR", err);
      dispatch({ type: "UNAUTHORIZED_ASSET_UPLOAD", payload: err.response.status });
    }
  };
};

//Fetch Keywords List
export const fetchKeywordsList = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        KEYWORDS_URL,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_KEYWORDS_LIST,
            payload: response.data.response,
          });
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      // console.log("ERROR", err);
      dispatch({ type: "UNAUTHORIZED_ASSET_UPLOAD", payload: err.response.status });
    }
  };
};

//Fetch CAT List
export const fetchCTAList = (authToken) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        CTA_URL,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.FETCH_CTA_LIST,
            payload: response.data.response,
          });
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      // console.log("ERROR", err );
      dispatch({ type: "UNAUTHORIZED_ASSET_UPLOAD", payload: err.response.status });
    }
  };
};




//add new hashtag 
export const addNewHashTag = (authToken, tagname) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ADD_NEW_HASHTAG,
        { tag_name: tagname },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch(fetchAssetLibraryHashtagList(authToken));
          if (response.data.status === "Success") {
            notify("New tag added successfully!", "success");
          }
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      //Toast("Something Went Wrong", "E");
      // console.log("ERROR", err);
      dispatch({ type: "UNAUTHORIZED", payload: err.response.status });
    }
  };
};

//add new hashtag
export const addNewTypePersonalize = (authToken, pname) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        "framework/api/personalizeVideoAdd",
        { video_name: pname },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch(fetchPersonalizeList(authToken));
          if (response.data.status === "Success") {
            notify("New Type added successfully!", "success");
          }
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      //Toast("Something Went Wrong", "E");
      // console.log("ERROR", err);
      dispatch({ type: "UNAUTHORIZED", payload: err.response.status });
    }
  };
};

//add new hashtag
export const deleteNewTypePersonalize = (authToken, pId) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        "framework/api/personalizeVideoDelete",
        {
          action: "delete",
          id: [pId]
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch(fetchPersonalizeList(authToken));
          if (response.data.status === "Success") {
            notify("Type deleted successfully!", "success");
          }
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      //Toast("Something Went Wrong", "E");
      // console.log("ERROR", err);
      dispatch({ type: "UNAUTHORIZED", payload: err.response.status });
    }
  };
};

//delete folder
export const deleteAssetFolder = (authToken, folderId) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ASSET_LIBRARY_URL,
        {
          action: "delete_folder",
          folder_id: folderId
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          if (response.data.status === "Success") {
            notify("Folder deleted successfully!", "success");
          }
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      //Toast("Something Went Wrong", "E");
      // console.log("ERROR", err);
      dispatch({ type: "UNAUTHORIZED", payload: err.response.status });
    }
  };
};

//update Asset Folder
export const updateAssetFolder = (authToken, smartFolder, categoryList, hashtagList, name, desc, id) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        ASSET_LIBRARY_URL,
        {
          action: "createFolder",
          smart_folder: smartFolder,
          category: categoryList,
          hashtags: hashtagList,
          name: name,
          description: desc,
          id: id
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.SET_ASSET_BOOKMARK,
            payload: response.data.response,
          });
          notify(response.data.response, "success");
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      dispatch({
        type: "UNAUTHORIZED_ASSET_LIBRARY",
        payload: err.response.status,
      });
      notify(err.response, "error");
    }
  };
};

//SET autoemail data Status
export const autoEmailMainData = (value) => {
  //console.log("setUnauthorizedCALLED");
  return (dispatch) => {
    dispatch({ type: "AUTOEMAIL_DATA", payload: value });
  };
};

//Save Global Fields Data
export const saveAutoEmailData = (authToken, payload) => {
  console.log(payload);
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        "/framework/api/emailCampaign",
        payload,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.SAVE_GLOBAL_FIELDS_DATA,
            payload: [response.data.inserted_id],
          });

          if (response.data.status == "Success") {
            notify("Content saved successfully!", "success");
          }
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      Toast("Something Went Wrong", "E");
      //dispatch({ type: "UNAUTHORIZED", payload: err.response.status });
    }
  };
};

// main content
export const updateMainContent = (authToken, payloadData, onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        MAIN_CONTENT,
        payloadData,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            Toast(response.data.message, "E");
          }
        } else {
          dispatch({
            type: types.UPDATE_MAIN_CONTENT,
            payload: response.data.file_link,
          });
          onSuccess();
          if (response.data.status === "Success") {
            notify("Content update successfully!", "success");
          }
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      //Toast("Something Went Wrong", "E");
      // console.log("ERROR", err);
      dispatch({ type: "TOGGLE_LOADING", payload: false });
      dispatch({ type: "UNAUTHORIZED", payload: err.response.status });
    }
  };
};

//add new hashtag 
export const checkCampaignTitle = (authToken, title, id) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
    try {
      const response = await storeApis.post(
        "/framework/api/verifyTemplateName",
        {
          camp_name: title,
          camp_id: id
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.statusCode == "403") {
          if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
            dispatch({
              type: "UNAUTHORIZED_ASSET_LIBRARY",
              payload: response.data.statusCode,
            });
          } else {
            dispatch({
              type: "CAMPAIGN_TITLE_STATUS",
              payload: "cross",
            });
          }
        } else {
          dispatch({
            type: "CAMPAIGN_TITLE_STATUS",
            payload: "tick",
          });
        }
        dispatch({ type: "TOGGLE_LOADING", payload: false });
      }
    } catch (err) {
      //Toast("Something Went Wrong", "E");
      // console.log("ERROR", err);
      dispatch({ type: "UNAUTHORIZED", payload: err.response.status });
    }
  };
};