// import "./styles.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import * as Icon from "react-bootstrap-icons";
import SubTable from "./SubTable"
import vector from '../../assets/setup-icon/Vector.png';
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { categoryListSequencing, activeCategoryData, deleteCategory, setCategoryAttribute, setContentAttribute, deleteContent, deleteBusinessNeed, setBatchAttribute, deleteBatch, fetchFaqBenefitListing, benefitFaqOrdering, updateFaqBenefit, deleteFaqBenefit } from "../../redux/actions/setupActions";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, Modal, Form} from 'react-bootstrap';

export default function DragTable(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState(props.categoryData);
  const [deleteData, setDeleteData] = useState({});
  const [title, setTitle] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [currentScreen, setCurrentScreen] = useState("list");
  const [description, setDescription] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [placeHolder, setPlaceHolder] = useState({});
  const [showBenefitListing, setShowBenefitListing] = useState(false);
  const [showFaqListing, setShowFaqListing] = useState(false);
  const [parentId, setParentId] = useState("");
  const category_all = useSelector((state) => state.assetLibrary.categoryList);
  const content_drop = useSelector((state) => state.setupScreen.content_drop.content_type_dropdown);
  const benefit_list = useSelector((state) => state.setupScreen.benefit_list);
  const faq_list = useSelector((state) => state.setupScreen.faq_list);
  const authToken = props.authToken;
  const type = 1;
  
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setDeleteData({});
    setShowBenefitListing(false);
    setCategoryId(null);
    setShowFaqListing(false);
    setShow(false);
  }
  const editPageOpen = (userData) => {
    switch (props.subPageName) {
      case "category":
         edit_category(userData.cat_name, type, userData.id, null, "cat_table", userData.image,"", userData.description )
        break;
      case "contentList":
        edit_content(userData.article_type, userData.selected_content_id ,userData.id ,userData.image, userData.description, userData.internal)
        break;
      case "businessNeed":
        props.handleEditShow("inside" ,userData.type ,userData.id);
        break;
      case "levels":
        edit_batch(userData.badge_icon
          , userData.badge_name ,userData.date
          ,userData.id
          , userData.points
          )
        break;
      default:
        break;
    }
  }
  
  useEffect(() => {
      if (props.subPageName === "addBenefit") {
        setPlaceHolder({ name: "Title", type: "Select Category" });
      } else {
        setPlaceHolder({ name: "Question", type: "Select Category" });
      }
      // setRenderForm(true);
    }, [authToken, props.subPageName, dispatch, category_all]);
  
  
  const handleShowPopup = (id, type) => {
    setShow(true);
    setDeleteData({ id: id, type: type })
  }
  const handleActiveChange = (e, id) => {
      setIsActive(!isActive);
      dispatch(activeCategoryData(authToken, id, e.target.checked !== true ? 2 : 1, type, props.page));
  }
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(users);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setUsers(tempData);
    if (props.subPageName !== "contentList") {
      dispatch(categoryListSequencing(authToken, e.draggableId , e.destination.index + 1, props.page));
    }
  };
  
    const handleDragBenefit = (e, action) => {
      if (!e.destination) return;
      console.log(e.draggableId, e.destination.index + 1)
      dispatch(benefitFaqOrdering(authToken, action, categoryId, e.draggableId, e.destination.index + 1, onSuccess));
    };
  
  const toggleSubTable = (id) => {
    if (parentId !== id) {
      setParentId(id);
    } else {
      setParentId("");
    }
  };
  
  const deleteEvent = () => {
    switch (props.subPageName) {
      case "category":
        dispatch(deleteCategory(authToken, deleteData.id, deleteData.type,props.page));
        break;
      case "contentList":
        dispatch(deleteContent(authToken, deleteData.id, deleteData.type,props.page));
        break;
      case "businessNeed":
        dispatch(deleteBusinessNeed(authToken, deleteData.id));
        break;
      case "levels":
        dispatch(deleteBatch(authToken, deleteData.id));
        break;
      default:
        console.log("Table Error")
        break;
    }
    props.handleRemoveSearch();
    handleClose();
  };
  
  const edit_content = (name, pid, sid, image, desc, internal) => {
    let activeContentType = null;
    activeContentType = content_drop.filter((item) => {
      return item.id === pid
    })
    dispatch(setContentAttribute(name, activeContentType, pid, sid, image, desc, internal));
    navigate("/setup/content/contentList/editContent");
  }
  const edit_batch = (icon, name, date, id, point) => {
    // let activeContentType = null;
    // activeContentType = content_drop.filter((item) => {
    //   return item.id === pid
    // })
    dispatch(setBatchAttribute(icon, name, date, id, point));
    navigate("/setup/leaderBoard/levels/editBatches");
  }
  
  const edit_category = ( name, type, pid, sid, page, imageLoaded, s_code, description) => {
    let activeCategory = null;
    if ((type === 2 && page ==='sub_cat_table') || (type === 1 && page ==='cat_table')) {
      activeCategory = category_all.filter((item) => {
        return item.id === pid
      })
    }
    dispatch(setCategoryAttribute(name, type, activeCategory,pid, sid, imageLoaded,s_code,description));
    navigate("/setup/content/editCategory");
  }
  
  useEffect(() => {
    console.log('xyz',props.categoryData);
  }, []);

  
  const handleFaqBenefitData = (action, cat_id) => {
    setCategoryId(cat_id);
    dispatch(fetchFaqBenefitListing(authToken, action, cat_id, onSuccess))
  }

  const onSuccess = (action) => {
    if (action != "faq") {
      setShowBenefitListing(true);
    } else {
      setShowFaqListing(true);
    }
    setCurrentScreen("list");
  }
  
  return (
    <div className="App">
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="table borderd">
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <div
                style={{ lineHeight: "35px" }}
                className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {users && users.map((user, index) => (
                  <>
                    <Draggable
                      key={user.id.toString()}
                      draggableId={user.id.toString()}
                      index={index}
                    >
                      {(provider) => (
                        <>
                          <div
                            className="row border-bottom"
                            {...provider.draggableProps}
                            ref={provider.innerRef}
                          >
                            <div
                              style={{display: (props.subPageName === "contentList" || props.subPageName === "businessNeed" || props.subPageName === "levels") && "none"}}
                              className="drag-button"
                              {...provider.dragHandleProps}
                            >
                              {" "}
                              <Icon.GripHorizontal color="#D9D9D9" />{" "}
                            </div>
                            <div className="table-row-count drag-button">
                              {props.page+index+1 + "."}
                            </div>
                            <div className="text-aligning table-name">
                              <span className="title-main" title={user.cat_name || user.article_type || user.type || user.badge_name}>{user.cat_name || user.article_type || user.type || user.badge_name}</span>
                            </div>
                            <div className={ props.subPageName === "category" ? "text-aligning":"adjust-width text-aligning others-setup-width"}>
                              <span className="bordering-date">{user && user.date && user.date.slice(0,10)}</span>
                            </div>
                            {/* <div className="edit-icon">
                              <Icon.ImageFill color="#64B6E7" />
                              <span className="px-1">view media</span>
                            </div> */}
                            {props.subPageName === "category" && <><div className="column-styling">
                              <span className="edit-icon"
                                onClick={() => {
                                  handleFaqBenefitData("benefit", user?.id)
                                }}
                              >
                                <Icon.PencilSquare className="mr-3" color="#64B6E7" />
                                Benefits
                              </span>
                            </div>
                            <div className="column-styling">
                              <span className="edit-icon"
                                onClick={() => {
                                    handleFaqBenefitData("faq", user?.id)
                                }}
                              >
                                <Icon.PencilSquare className="mr-3" color="#64B6E7" />
                                Faq
                              </span>
                            </div></>}
                            <div className="column-styling">
                              <span className="edit-icon" onClick={()=>{editPageOpen(user)}}>
                                <Icon.PencilSquare className="mr-3" color="#64B6E7" />
                                Edit
                              </span>
                            </div>
                            <div className="column-styling">
                              <span className="edit-icon" onClick={()=>{handleShowPopup(user.id,type)}}>
                                <Icon.TrashFill color="rgba(33, 33, 33, 0.3)" />
                              </span>
                            </div>
                            {props.subPageName === "category" && <div className="toggle-switch">
                              <Form.Check 
                                className="d-inline"
                                type="switch"
                                id="custom-switch"
                                value={user.status}
                                defaultChecked={user.status}
                                onChange={(e) => {
                                  handleActiveChange(e,user.id);
                                }}
                              />
                            </div>}
                            {user.subcategory_list && user.subcategory_list.length > 0 && props.subPageName === "category" && <div className="column-styling" onClick={() => { toggleSubTable(user.id) }}>
                              {parentId !== user.id ? <img src={vector} alt="icon" />
                                : <img style={{transform: "rotate(180deg)"}} src={vector} alt="icon" />}
                            </div>}
                            
                            {user.subcategory_list && user.subcategory_list.length > 0 && props.subPageName === "category" && parentId !== "" && <SubTable authToken={authToken} page={props.page} subPageName={props.subPageName} subUser={user.subcategory_list} pid={parentId} handleShowPopup={handleShowPopup} edit_category={edit_category} />}
                            
                          </div>
                        </>
                      )}
                    </Draggable>
                  </>
                ))}
                {provider.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      
      {/* listing popup */}
      
      <Modal className="listing-modal scroll-deactivate" show={showBenefitListing} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Benefits</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentScreen == "list" ?
            <>
              {benefit_list?.length > 0 && <DragDropContext onDragEnd={(e)=>{handleDragBenefit(e, "benefit")}}>
                <div className="listing-faq-benefit">
                  <Droppable droppableId="droppable-2">
                    {(provider) => (
                      <div
                        ref={provider.innerRef}
                        {...provider.droppableProps}
                        className="droppable-container" // Add this class for styling
                      >
                        {benefit_list?.map((benefit, b_ind) => (
                          <Draggable
                            key={benefit.id.toString()}
                            draggableId={benefit.id.toString()}
                            index={b_ind}
                          >
                            {(provider) => (
                              <div
                                className="d-flex align-items-center mb-4 gap-4"
                                {...provider.draggableProps}
                                ref={provider.innerRef}
                              >
                                <div {...provider.dragHandleProps}>
                                  <Icon.GripHorizontal color="#000000" />
                                </div>
                                <div style={{ width: "3%" }} className="d-flex count justify-content-center">
                                  {b_ind + 1}
                                </div>
                                <div style={{ width: "75%" }}>
                                  <h5>{benefit?.title}</h5>
                                  <p>{benefit?.description}</p>
                                </div>
                                <div className="d-flex gap-2">
                                  <div className="column-styling">
                                    <span
                                      className="edit-icon"
                                      onClick={() => {
                                        setCurrentScreen("update");
                                        setTitle(benefit?.title);
                                        setDescription(benefit?.description);
                                        setSelectedId(benefit.id);
                                      }}
                                    >
                                      <Icon.PencilSquare className="mr-3" color="#64B6E7" />
                                      Edit
                                    </span>
                                  </div>
                                  <div className="column-styling">
                                    <span className="edit-icon" onClick={()=>{dispatch(deleteFaqBenefit(authToken, benefit?.id, benefit?.cat_id, "benefit", onSuccess))}}>
                                      <Icon.TrashFill color="rgba(33, 33, 33, 0.3)" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provider.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>}
              {benefit_list?.length === 0 && (
                <div className="no-data asset empty-table">
                  <span>
                    <Icon.Inbox />
                  </span>
                  <br />
                  No Data
                </div>
              )}
            </>
            :
            <div>
              <Form className="edit-benefit">
                <div
                    className="cursor-pointer benefit-back-btn"
                    onClick={() => { setCurrentScreen("list"); setTitle(""); setDescription("")}}
                >
                  <img className="rotate-left " src={vector} alt="icon" />
                  Back
                </div>
                <p className="edit-benefit-title">Update Benefit</p>
                <div>
                  <Form.Control
                    className="add-name my-3"
                    value={title}
                    type="text"
                    placeholder={placeHolder.name}
                    name="title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <Form.Group>
                    <Form.Control
                      className="add-name my-3"
                      rows={3}
                      value={description}
                      as="textarea"
                      name="description"
                      placeholder="Description"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                </div>
              <div className="d-flex gap-3">
                <Button
                  variant="primary"
                  className="save-button"
                  size="lg"
                  onClick={() => { dispatch(updateFaqBenefit(authToken, title, description, "benefit", selectedId, categoryId, onSuccess)) }}
                >
                  Update
                </Button>
              </div>
            </Form>
            </div>
          }
        </Modal.Body>
      </Modal>
      
      {/* faq listing popup */}
      
      <Modal className="listing-modal" show={showFaqListing} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Faq</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentScreen == "list" ?
            <>
              {faq_list?.length > 0 && <DragDropContext onDragEnd={(e)=>{handleDragBenefit(e, "faq")}}>
                <div className="listing-faq-benefit">
                  <Droppable droppableId="droppable-3">
                    {(provider) => (
                      <div
                        ref={provider.innerRef}
                        {...provider.droppableProps}
                        className="droppable-container" // Add this class for styling
                      >
                        {faq_list?.map((faq, b_ind) => (
                          <Draggable
                            key={faq.id.toString()}
                            draggableId={faq.id.toString()}
                            index={b_ind}
                          >
                            {(provider) => (
                              <div
                                className="d-flex align-items-center mb-4 gap-4"
                                {...provider.draggableProps}
                                ref={provider.innerRef}
                              >
                                <div {...provider.dragHandleProps}>
                                  <Icon.GripHorizontal color="#000000" />
                                </div>
                                <div style={{ width: "3%" }} className="d-flex count justify-content-center">
                                  {b_ind + 1}
                                </div>
                                <div style={{ width: "75%" }}>
                                  <h5>{faq?.title}</h5>
                                  <p>{faq?.description}</p>
                                </div>
                                <div className="d-flex gap-2">
                                  <div className="column-styling">
                                    <span
                                      className="edit-icon"
                                      onClick={() => {
                                        setCurrentScreen("update");
                                        setTitle(faq?.title);
                                        setDescription(faq?.description);
                                        setSelectedId(faq?.id);
                                      }}
                                    >
                                      <Icon.PencilSquare className="mr-3" color="#64B6E7" />
                                      Edit
                                    </span>
                                  </div>
                                  <div className="column-styling">
                                    <span className="edit-icon" onClick={()=>{dispatch(deleteFaqBenefit(authToken, faq?.id, faq?.cat_id, "faq", onSuccess))}}>
                                      <Icon.TrashFill color="rgba(33, 33, 33, 0.3)" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provider.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>}
              {faq_list?.length === 0 && (
                <div className="no-data asset empty-table">
                  <span>
                    <Icon.Inbox />
                  </span>
                  <br />
                  No Data
                </div>
              )}
            </>
            :
            <div>
              <Form className="edit-benefit">
                <div
                    className="cursor-pointer benefit-back-btn"
                    onClick={() => { setCurrentScreen("list"); setTitle(""); setDescription("")}}
                >
                  <img className="rotate-left " src={vector} alt="icon" />
                  Back
                </div>
                <p className="edit-benefit-title">Update Benefit</p>
                <div>
                  <Form.Control
                    className="add-name my-3"
                    value={title}
                    type="text"
                    placeholder={placeHolder.name}
                    name="title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <Form.Group>
                    <Form.Control
                      className="add-name my-3"
                      rows={3}
                      value={description}
                      as="textarea"
                      name="description"
                      placeholder="Description"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                </div>
              <div className="d-flex gap-3">
                <Button
                  variant="primary"
                  className="save-button"
                  size="lg"
                  onClick={() => { dispatch(updateFaqBenefit(authToken, title, description, "faq", selectedId, categoryId, onSuccess)) }}
                >
                  Update 
                </Button>
              </div>
            </Form>
            </div>
          }
        </Modal.Body>
      </Modal>
      
      {/* delete poppup */}
      <Modal className="delete-popup" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete ?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={deleteEvent}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
