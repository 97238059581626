import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate,useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Page = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  
  const company_logo = useSelector((state) => state.headerTopMenus.companyLogo);

  const title = props.title;
  useEffect(() => {
    document.title = `${company_logo?.title} | ${title}`;
    window.scrollTo(0, 0);

    if(!localStorage.getItem("userData")) {
      navigate("/");
    }

  }, [title, navigate]);



  return <Container style={{paddingTop: location.pathname.includes("setup") ? "20px" : "10px"}} className={location.pathname === "/conversion/videoCta" ? "body-container custom-bg-light":"body-container"} >{props.children}</Container>;
};

export default Page;
