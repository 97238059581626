/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Stack, Form, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { appUserActivity, taskManager } from "../../../../../../redux/actions/crmActions";
import "./Leadtabs.css";
import { useParams } from 'react-router';
import moment from "moment";
import * as Icon from "react-bootstrap-icons";
import DeleteModal from "../../../../../shared/DeleteModal";
import { useNavigate } from "react-router-dom";

const Notes = (props) => {

    const { id } = useParams();
    const { authToken } = props;
    const { contactId } = props

    const [deleteDialog, setDeleteDialog] = useState(false);
    const [item, setItem] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userDetails = useSelector((state) => state.crmLeads.userActivityDetails);
    useEffect(() => {
        console.log("idd", id);

        let idd = id !== undefined ? id : contactId.contact_ids[0].contact_id;
        //dispatch(appUserActivity(authToken, idd));
        return () => {
            // console.log("activity cleanup", props);      
        };
    }, []);

    const getDate = (date) => {
        if (date !== "" && date !== null && date !== "0000-00-00 00:00:00") {
            let d = moment(date).format('DD');
            return d;
        }
    }

    const getMonth = (date) => {
        if (date !== "" && date !== null && date !== "0000-00-00 00:00:00") {
            let m = moment(date).format('MMM');
            return m.toUpperCase();
        }
    }

    const doAction = (item, val) => {
        setItem(item);
        if (val === 'edit') {
            let idd = id ? id : contactId.contact_ids[0].contact_id;
            navigate(`/crm/leads/${idd}/note/${'edit' + '-' + item.id}`);
        } else if (val === 'delete') {
            setDeleteDialog(true);
        }
    }

    const deleteConfirmedNotes = () => {
        let idd = id ? id : contactId.contact_ids[0].contact_id;
        let body = { "action": "delete", "id": item.id };
        dispatch(taskManager(authToken, body, 'deleteNote', idd));
        setDeleteDialog(false);
        setItem(null);
    }

    return (
        <div className="offcanvas-body-background-child">
            {userDetails && (
                <div className="activites">
                    {userDetails.response?.notes.length > 0 ?
                        userDetails.response.notes.map((item, i) => {
                            return (
                                <Row className="m-2 p-4" key={i}>
                                    <Col sm lg={2} className="p-1">
                                        <div className="fs-6 fw-bold">{getDate(item.activity_date)} <br /> {getMonth(item.activity_date)}</div>
                                    </Col>
                                    <Col sm lg={10} style={{ backgroundColor: '#fff' }}>
                                        <Row className="p-2 lh-base">
                                            <Col sm md={10}>
                                                <div className="act-title"><Icon.Sticky />&nbsp;<strong>Notes For</strong>&nbsp;&nbsp;{item.contact_name}</div>
                                                <div className="mt-2 act-notes">{item.task_notes}</div>
                                            </Col>
                                            <Col sm md={2}>
                                                <Row>
                                                    <Icon.Trash className="text-color" onClick={(e) => doAction(item, 'delete')} />
                                                </Row>
                                                <Row>
                                                    <Icon.Pencil className="text-color mt-3" onClick={(e) => doAction(item, 'edit')} />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )
                        })
                        :
                        <div className="no-data mt-5">
                            <span>
                                <Icon.Inbox />
                            </span>
                            <br />
                            No Data
                        </div>
                    }
                </div>
            )}
            <DeleteModal show={deleteDialog} hide={() => setDeleteDialog(false)} deleteItem={deleteConfirmedNotes} />
        </div>
    )
}

export default Notes;