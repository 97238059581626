/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Stack, Button, Form, Image, Card, Accordion } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import "../leads/tab-components/Leadtabs.css";
import { Link, Outlet } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router-dom';
import { appUserActivity, updateLeadsStage, fetchContactDetails, fetchTaskList } from '../../../../../redux/actions/crmActions';
import { useDispatch, useSelector } from "react-redux";
import dashboard from "../../../../../../src/assets/dashboard.png";
import moment from 'moment';

const DetailedLead = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetails = useSelector((state) => state.crmLeads.userActivityDetails);
    const taskList = useSelector((state) => state.crmLeads.tasksList);

    const authToken = props.authToken;
    const pageFrom = props.pageFrom;

    const [stage, setStage] = useState("");
    const [upcomingTask, setUpcomingTask] = useState({});

    props.pageTypeHandler("CRM Details");

    const getChars = (str) => {
        const firstLetters = str.split(' ').slice(0,2).map(word => word[0]).join('').toUpperCase();
        console.log("firstLetters",firstLetters);
        return firstLetters;
    }

    useEffect(() => {
        if (id !== undefined) {
            dispatch(appUserActivity(authToken, id));
            dispatch(fetchTaskList(authToken, id));
        }
    }, [])


    useEffect(() => {
        setStage(userDetails?.response?.current_stage);
    }, [userDetails]);

    useEffect(() => {
        if (userDetails?.response?.activity?.length > 0) {
            let dates = [];
            let days = [];
            setUpcomingTask({});
            userDetails?.response?.activity?.map(task => {
                if (task.activity_type ==="Task" && task.task_due_date !== "" &&  task.task_status !== 'Completed' && task.task_due_date !== "0000-00-00 00:00:00") {
                    if (moment(task.task_due_date).isSameOrAfter(moment(new Date()).format('YYYY-MM-DD')) > 0) {
                        console.log("future date is upcoming", moment(task.task_due_date).diff(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 'days'));
                        let d = moment(task.task_due_date).diff(moment(new Date()).format('YYYY-MM-DD'), 'days');
                        console.log("d", d);
                        let dd = d !== 0 ?  (d + " days to go") : "Today" ;
                        let obj = { date: task.task_due_date, val: d, day: dd };
                        console.log("obj aft", obj);
                        dates.push(obj);
                    } else if (moment(task.task_due_date).isBefore(moment(new Date()).format('YYYY-MM-DD HH:mm:ss')) > 0) {
                        console.log("future date is pending", moment(task.task_due_date).diff(moment(new Date()).format('YYYY-MM-DD'), 'days'));
                        let d = moment(new Date).diff(task.task_due_date, 'days');
                        let obj = { date: task.task_due_date, val: d, day: d + " days ago" };
                        console.log("obj bef", obj);
                        days.push(obj);
                    }
                } else {
                    let obj = { date: "", val: "", day: "" };
                    // days.push(obj);
                    days = [];
                }
            })


            console.log("dayss", days);
            if (dates.length > 0) {
                let min1 = Math.min(...dates.map(item => item.val));
                console.log("min1 date", min1);
                let data1 = dates.filter(item => item.val == min1);
                console.log("dataa1", data1);
                setUpcomingTask(data1[0]);
            } else if (days.length > 0) {
                let min2 = Math.min(...days.map(item => item.val));
                console.log("min2 date", min2);
                let data2 = days.filter(item => item.val == min2);
                console.log("dataa2", data2);
                setUpcomingTask(data2[0]);
            } else {
                setUpcomingTask({});
            }
        }
        console.log("upcoming task", upcomingTask);
    }, [userDetails]);


    const updateStage = (e) => {
        setStage(e);
        if (userDetails?.response?.lead_stages) {
            let stageNum = userDetails.response.lead_stages.filter(item => {
                if (e === item.lead_stage) {
                    return item.lead_type;
                }
            });
            dispatch(updateLeadsStage(authToken, stageNum[0].lead_stage, id, null, null, "2"));
        }
    }

    const doLeadAction = (e, val) => {
        if (val === 'plus') {
            navigate(`/crm/leads/${id}/`);
        } else if (val === 'note') {
            navigate(`/crm/leads/${id}/note/${'add'}`);
        } else if (val === 'mail') {
            navigate(`/crm/leads/${id}/add-mail`);
        } else if (val === 'call') {
            //navigate(`/crm/leads/${id}/add-call`);
        } else if (val === 'task') {
            navigate(`/crm/leads/${id}/task/${'add'}`);
        } else if (val === 'meeting') {
            //navigate(`/crm/leads/${id}/add-meeting`);
        }
    }

    const handleRoute = () => {
        navigate('/crm');
    }

    const getScore = (count) => {
        //let count = userDetails.response.lead_score;
        if (count < 25) {
            return count + "";
        } else if (count >= 25 && count < 50) {
            return count + " 🔥";
        } else if (count >= 50 && count < 75) {
            return count + " 🔥🔥";
        } else {
            return count + " 🔥🔥🔥";
        }
    }

    const editLeadProfile = () => {
        console.log("knownn", userDetails?.response?.about?.known);
        dispatch(fetchContactDetails(authToken, +id));
        if (userDetails.response.about.known == 1) {
            setTimeout(() => {
                navigate(`/crm/prospects/add-contact`);
            }, 2000);
        } else {
            setTimeout(() => {
                navigate(`/crm/contacts/add-contact`);
            }, 2000);
        }

    }

    return (
        <>
            {userDetails && (
                <Row className="detailed-lead">
                    <Col md={3}> {/* className="p-4" */}
                        <Container className="mt-4 ms-2 right-details">
                            <div className="mt-2 fw-bold show-pointer">
                                <Icon.ChevronLeft
                                    onClick={handleRoute}
                                    style={{
                                        fontSize: 25,
                                        color: '#111',
                                        padding: 5,
                                        fontWeight: 'bold'
                                    }} />&nbsp;&nbsp;&nbsp; {pageFrom}
                            </div>
                            <div className="mt-3">
                                <Row>
                                    <Col sm lg={3}>
                                        <div className='circle'>{getChars(userDetails.response?.about?.name)}</div>
                                    </Col>
                                    <Col sm lg={9} className="mb-3 ml-1">
                                        <div className='fs-5 fw-bold'>{userDetails.response?.about?.name}</div>
                                        <div className='fs-6 fw-normal'>{userDetails.response?.about?.company}</div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="selectedAssetActions">
                                <ul>
                                    <li>
                                        <Button variant="light" onClick={(e) => doLeadAction(e, 'plus')}>
                                            <span>
                                                <Icon.Plus />
                                            </span>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button variant="light" onClick={(e) => doLeadAction(e, 'note')}>
                                            <span>
                                                <Icon.Sticky />
                                            </span>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button variant="light" onClick={(e) => doLeadAction(e, 'mail')}>
                                            <span>
                                                <Icon.Envelope />
                                            </span>
                                        </Button>
                                    </li>
                                    {/* <li>
                                        <Button variant="light" onClick={(e) => doLeadAction(e, 'call')}>
                                            <span>
                                                <Icon.Telephone />
                                            </span>
                                        </Button>
                                    </li> */}
                                    <li>
                                        <Button variant="light" onClick={(e) => doLeadAction(e, 'task')}>
                                            <span>
                                                <Icon.ClipboardCheck />
                                            </span>
                                        </Button>
                                    </li>
                                    {/* <li>
                                        <Button variant="light" onClick={(e) => doLeadAction(e, 'meeting')}>
                                            <span>
                                                <Icon.People />
                                            </span>
                                        </Button>
                                    </li> */}
                                </ul>
                            </div>

                            <hr className="mt-2" />

                            <div className="mt-2 lh-lg">
                                <div className="fw-bold fs-6">Email</div>
                                <Row>
                                    <Col sm md={8}>
                                        {userDetails.response.about.email}
                                    </Col>
                                    <Col sm md={4} className="text-right" style={{ textAlign: 'right' }}>
                                        <Icon.Pencil
                                            className="show-pointer"
                                            style={{
                                                fontSize: 25,
                                                color: '#45BCD2',
                                                padding: 5
                                            }}
                                            onClick={editLeadProfile}
                                        />
                                    </Col>
                                </Row>
                            </div>

                            <div className="mt-4 lh-lg">
                                <div className="fs-6 fw-bold ">Mobile Number</div>
                                <div className="fs-6 fw-normal">
                                    {userDetails.response.about.mobile}
                                </div>
                            </div>

                            <hr className="mt-2" />

                            {userDetails?.response?.lead_type == "sip" && (
                                <div className="mt-4 lh-lg">
                                    <div className="fs-6 fw-bold">Recommendation Details</div>
                                    <Row>
                                        <Col className="act-title fw-bold">Folio Number:&nbsp;</Col>
                                        <Col className="act-title fw-normal">
                                            {userDetails.response.about.folio_no}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="act-title fw-bold">Scheme Name:&nbsp;</Col>
                                        <Col className="act-title fw-normal">
                                            {userDetails.response.about.scheme_name}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="act-title fw-bold">SIP Amount:&nbsp;</Col>
                                        <Col className="act-title fw-normal">
                                            {userDetails.response.about.amount}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="act-title fw-bold">Frequency:&nbsp;</Col>
                                        <Col className="act-title fw-normal">
                                            {userDetails.response.about.frequency}
                                        </Col>
                                    </Row>
                                </div>
                            )}

                            {/*  <div className="mt-2">
                                <div className="fs-6 fw-bold">Last Edited</div>
                                <div className="fs-6 fw-normal">
                                    30 Mar 2022
                                </div>
                            </div>

                            <div className="mt-2">
                                <div className="fs-6 fw-bold ">Last Created</div>
                                <div className="fs-6 fw-normal">
                                    28 Feb 2022
                                </div>
                            </div> */}

                        </Container>
                    </Col>

                    <Col md={6} className="offcanvas-body-background">
                        <Outlet></Outlet>
                    </Col>

                    <Col md={3}>
                        <Container className="mt-4 ms-3 mb-1">
                            <Row className="border border-1 right-details">
                                <Col >
                                    <div className="mt-1">Lead Stage</div>
                                </Col>
                                <Col >
                                    <Form.Select
                                        className="stage-select"
                                        as="select"
                                        value={stage}
                                        onChange={e => {
                                            updateStage(e.target.value);
                                        }}
                                    >
                                        {userDetails.response.lead_stages.map((stage, index) => {
                                            return (
                                                <option key={index} value={stage.lead_stage} >{stage.lead_stage}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </Col>
                            </Row>

                            <Row className="mt-3 border border-1 h-auto p-1 right-details">
                                {/* <Stack direction="horizontal"> */}
                                <Col sm md={2} className="d-flex justify-content-center p-1">
                                    <Image src={dashboard} alt="image not found" />
                                </Col>
                                <Col sm md={10}>
                                    {/* <Stack direction="vertical" gap={1} className="p-1"> */}
                                    <div className="fw-bolder act-title">Engagement Score : {getScore(userDetails.response?.lead_score)}</div>
                                    {userDetails.response?.current_stage && userDetails.response?.old_stage ?
                                        <div className="fs-6 fw-normal" style={{ color: '#199459' }}>{userDetails.response?.old_stage} -&gt; {userDetails.response?.current_stage}</div>
                                        :
                                        <div className="fs-6 fw-normal" style={{ color: '#199459' }}>{userDetails.response?.current_stage}</div>
                                    }
                                </Col>
                                {/*  </Stack>
                                </Stack> */}
                            </Row>

                            <div className="mb-2 mt-2 fs-6 right-details colour">Upcoming follow up</div>
                            {!_.isEmpty(upcomingTask) ? (
                                <Row className="mt-1 border border-1 h-auto p-1 item-card right-details">
                                    {/* <Stack direction="horizontal" gap={0}>
                                        <Stack direction="vertical" gap={1}> */}
                                    <Col sm md={8}>
                                        <div className="act-title fw-bold"><Icon.ClipboardCheck />
                                            <span>&nbsp;&nbsp;Task Scheduled</span>
                                        </div>
                                        <div className="act-datetime mt-2">&nbsp;&nbsp;on {moment(upcomingTask?.date).format('h:mm A DD MMM YYYY')}</div>
                                    </Col>
                                    {/*  </Stack> */}
                                    <Col sm md={4} className="d-flex align-items-center">
                                        <div className="upcoming-task text-color">{upcomingTask?.day}</div>
                                    </Col>
                                    {/*  </Stack> */}
                                </Row>
                            ) : (
                                <Row className="mt-1 border border-1 h-auto p-1 item-card right-details">
                                    <div>There are no upcoming tasks</div>
                                </Row>
                            )}

                            <hr className="mt-3 right-details" />

                            <Accordion className="mt-3 h-auto right-details"> {/* defaultActiveKey="0" */}
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Engagement</Accordion.Header>
                                    <Accordion.Body className="abody">
                                        {userDetails.response?.engagement.length > 0 ?
                                            (userDetails.response.engagement.map((item, i) => {
                                                return (
                                                    <Card className="item-card mb-1" key={i}>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col sm md={1}>
                                                                    {item.type == "Email" ? <Icon.Envelope /> :
                                                                        item.type == "Lead" ? <Icon.FileEarmarkText /> :
                                                                            item.type == "Website Visit" ? <Icon.Laptop /> :
                                                                                " "}
                                                                </Col>
                                                                <Col sm md={10}>
                                                                    <div className="fs-6 fw-bold">
                                                                        <span>&nbsp;&nbsp;{item.type == "Email" ? "Shared content on Email" :
                                                                            item.type == "Lead" ? "Requirement Filled" :
                                                                                item.type == "Website Visit" ? "Visited Website" :
                                                                                    " "}</span>{/* {userDetails?.response?.about?.name} */}
                                                                    </div>
                                                                    {item?.source ? (
                                                                        <div className="fs-6">&nbsp;&nbsp;&nbsp;{item?.source !== "" ? `via ${item.source}` : ""}  On {moment(item.visitdate).format('DD MMM YYYY')} </div>
                                                                    ) : (
                                                                        <span>&nbsp;&nbsp; On {moment(item.visitdate).format('DD MMM YYYY')}</span>
                                                                    )}

                                                                    {item?.comment && (
                                                                        <span className="mt-2">
                                                                            <div className="act-subhead mt-2">&nbsp;&nbsp;COMMENT</div>
                                                                            <div>&nbsp;&nbsp;{item.comment}</div>
                                                                        </span>
                                                                    )}

                                                                    {item?.engage_form_url_title !== "Home page" ? (
                                                                        <span className="mt-2">
                                                                            <div className="act-subhead mt-2">&nbsp;&nbsp;INTERESTED IN</div>
                                                                            <div>&nbsp;&nbsp;{item.engage_form_url_title}</div>
                                                                        </span>
                                                                    ) : (
                                                                        <span className="mt-2">
                                                                            <div className="act-subhead">&nbsp;&nbsp;VISITED BY</div>
                                                                            <div>&nbsp;&nbsp;{item.engage_form_url_title}</div>
                                                                        </span>
                                                                    )
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                )
                                            }))
                                            :
                                            <Row className="m-1 p-2 right-details">
                                                <div>No Records in Engagement</div>
                                            </Row>}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Requirement</Accordion.Header>
                                    <Accordion.Body className="abody">
                                        {userDetails.response?.requirement.length > 0 ? (
                                            userDetails.response.requirement.map((item, i) => {
                                                return (
                                                    <Card className="item-card mb-1" key={i}>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col sm md={1}>
                                                                    <Icon.FileEarmarkText />
                                                                </Col>
                                                                <Col sm md={10} className="p-1">
                                                                    <div className="fs-6 fw-bold">
                                                                        <span>&nbsp;&nbsp;{item.type == "Email" ? "Shared content on" :
                                                                            item.type == "Lead" ? "Requirement Filled" :
                                                                                item.type == "Website Visit" ? "Visited Website" :
                                                                                    " "}</span>{/* {userDetails?.response?.about?.name} */}
                                                                    </div>
                                                                    {item?.source ? (
                                                                        <div className="fs-6">&nbsp;&nbsp;&nbsp;{item?.source !== "" ? `via ${item.source}` : ""}  On {moment(item.visitdate).format('DD MMM YYYY')} </div>
                                                                    ) : (
                                                                        <div>&nbsp;&nbsp; On {moment(item.visitdate).format('DD MMM YYYY')}</div>
                                                                    )
                                                                    }
                                                                    {item?.comment && (
                                                                        <div className="mt-2">
                                                                            <div className="act-subhead">&nbsp;&nbsp;COMMENT</div>
                                                                            <div>&nbsp;&nbsp;{item.comment}</div>
                                                                        </div>
                                                                    )}

                                                                    {item.engage_form_url_title !== "Home page" ? (
                                                                        <div className="mt-2">
                                                                            <div className="act-subhead">&nbsp;&nbsp;INTRESTED IN</div>
                                                                            <div>&nbsp;&nbsp;{item.engage_form_url_title}</div>
                                                                        </div>
                                                                    ) :
                                                                        (
                                                                            <div className="mt-2">
                                                                                <div className="act-subhead">&nbsp;&nbsp;VISITED BY</div>
                                                                                <div>&nbsp;&nbsp;{item.engage_form_url_title}</div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                )
                                            }))
                                            :
                                            <Row className="m-1 p-2 right-details">
                                                <div>No Records in Requirements</div>
                                            </Row>}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Contacts</Accordion.Header>
                                    <Accordion.Body className="abody">

                                        {userDetails?.response.contacts.length > 0 ? (
                                            <Row className="d-flex justify-content-between right-details m-1">
                                                {userDetails?.response?.contacts?.map((item, index) => {
                                                    return (
                                                        <Col>
                                                            <div key={index}>
                                                                <div className='circle1'>{getChars(item.name)}</div>
                                                                <div className='act-title fw-normal wordBreak'>{item.name}</div>
                                                            </div>
                                                        </Col>
                                                    )
                                                }
                                                )}
                                            </Row>
                                        )
                                            :
                                            <Row className="m-1 p-2 right-details">
                                                <div>No Records in Contacts</div>
                                            </Row>}

                                    </Accordion.Body>
                                </Accordion.Item>
                                {/* <Accordion.Item eventKey="3">
                                    <Accordion.Header>Accounts</Accordion.Header>
                                    <Accordion.Body></Accordion.Body>
                                </Accordion.Item> */}
                            </Accordion>
                        </Container>
                    </Col>
                </Row>
            )
            }
        </>
    )


}


export default DetailedLead;