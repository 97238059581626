/* eslint-disable */
import {
  Row,
  Col,
  Button,
  Modal,
  Breadcrumb,
  Card,
  Form,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import React from "react";
import * as Icon from "react-bootstrap-icons";

import "./Contacts.css";

import { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router";

//Redux-imports 
import { useDispatch, useSelector } from "react-redux";
import { addContact } from "../../../../../redux/actions/crmActions";

const AddContact = (props) => {
  const { pageFrom } = props;
  const { id } = useParams(); 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [designation, setDesignation] = useState("");
  const [company, setCompany] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");

  const contactDetails = useSelector((state) => state.crmLeads.contactDetails);

  props.pageTypeHandler("CRM"); 

  //   const [show, setShow] = useState(false);
  //   const handleClose = () => {
  //     setShow(false);
  //   };

  //   const handleShow = () => setShow(true);

  const submitHandler = async (e) => {
    e.preventDefault();

    let newContact;
    if (contactDetails) {
      if (pageFrom === "Contacts") {
        newContact = {
          contact_id: contactDetails.contact_id,
          fname: fname,
          lname: lname,
          company: company,
          company_web: companyWebsite,
          email: email,
          designation: designation,
          city: city,
          phone: phone,
        };
      } else if (pageFrom === "Prospects") {
        newContact = {
          known: 1,
          contact_id: contactDetails.contact_id,
          fname: fname,
          lname: lname,
          company: company,
          company_web: companyWebsite,
          email: email,
          designation: designation,
          city: city,
          phone: phone,
        };
      }
    } else {
      if (pageFrom === "Contacts") {
        newContact = {
          fname: fname,
          lname: lname,
          company: company,
          company_web: companyWebsite,
          email: email,
          designation: designation,
          city: city,
          phone: phone,
        };
      } else if (pageFrom === "Prospects") {
        newContact = {
          known: 1,
          fname: fname,
          lname: lname,
          company: company,
          company_web: companyWebsite,
          email: email,
          designation: designation,
          city: city,
          phone: phone,
        };
      }
    }

    dispatch(addContact(props.authToken, newContact));
    setTimeout(() => {
     // navigate(`/crm/${pageFrom}`);
     navigate(-1);
    }, 2000);
   // console.log("newContact>>", newContact);
  };

  useEffect(() => {
    if (contactDetails) {
      setFname(contactDetails.fname);
      setLname(contactDetails.lname);
      if(contactDetails?.company?.comapny_name){
        setCompany(contactDetails?.company?.company_name);
      }else{
        setCompany(contactDetails?.company);
      }
      
      setCompanyWebsite(contactDetails.company_web);
      setEmail(contactDetails.email);
      setDesignation(contactDetails.designation);
      setCity(contactDetails.city);
      setPhone(contactDetails.phone);
    }
  }, []);

  //console.log("contactsList",contactsList);
 // console.log("contactDetails", contactDetails);

  return (
    <>
      <div className="sub-header">
        <Row>
          <Col className="header-breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item
                onClick={() => {
                  navigate(`/crm/${pageFrom}`);
                }}
              >
                <div>{pageFrom}</div>
              </Breadcrumb.Item>
              <span>
                <Icon.ChevronRight />
              </span>
              {contactDetails ? (
                <Breadcrumb.Item active>
                  Edit {pageFrom === "Contacts" ? "Contact" : "Prospect"}
                </Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item active>
                  New {pageFrom === "Contacts" ? "Contact" : "Prospect"}
                </Breadcrumb.Item>
              )}
            </Breadcrumb>
          </Col>
        </Row>
      </div>
      <div className="new-contact mt-4">
        <div className="container contacts-view">
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  {contactDetails ? (
                    <Card.Title>
                      Edit {pageFrom === "Contacts" ? "Contact" : "Prospect"}
                    </Card.Title>
                  ) : (
                    <Card.Title>
                      New {pageFrom === "Contacts" ? "Contact" : "Prospect"}
                    </Card.Title>
                  )}
                  <Card.Text className="mt-4 mb-4">
                    <Form onSubmit={submitHandler}>
                      <Row>
                        <Col className="col-5">
                          <h6 className="mb-3">Personal Information</h6>
                          <Form.Group className="mb-3">
                            <Row>
                              <Col className="col-4">
                                <Form.Label className="mt-1">
                                  First Name*
                                  {/* <span className="mandatory">*</span> */}
                                </Form.Label>
                              </Col>
                              <Col className="col-8">
                                <Form.Control
                                  type="text"
                                  autoComplete="off"
                                  required
                                  placeholder="First Name"
                                  value={fname}
                                  onChange={(e) => setFname(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Row>
                              <Col className="col-4">
                                <Form.Label className="mt-1">
                                  Last Name*
                                </Form.Label>
                              </Col>
                              <Col className="col-8">
                                <Form.Control
                                  type="text"
                                  //ref={nameRef}
                                  required
                                  placeholder="Last Name"
                                  value={lname}
                                  onChange={(e) => setLname(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Row>
                              <Col className="col-4">
                                <Form.Label className="mt-1">Email*</Form.Label>
                              </Col>
                              <Col className="col-8">
                                <Form.Control
                                  type="email"
                                  //ref={nameRef}
                                  required
                                  autoComplete="off"
                                  placeholder="Email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Row>
                              <Col className="col-4">
                                <Form.Label className="mt-1">
                                  Phone Number*
                                </Form.Label>
                              </Col>
                              <Col className="col-8">
                                <Form.Control
                                  type="tel"
                                  //ref={nameRef}
                                  required
                                  autoComplete="off"
                                  placeholder="Phone Number"
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Row>
                              <Col className="col-4">
                                <Form.Label className="mt-1">City</Form.Label>
                              </Col>
                              <Col className="col-8">
                                <Form.Control
                                  type="text"
                                  //ref={nameRef}
                                  autoComplete="off"
                                  placeholder="City"
                                  value={city}
                                  onChange={(e) => setCity(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Row>
                              <Col className="col-4">
                                <Form.Label className="mt-1">
                                  Designation
                                </Form.Label>
                              </Col>
                              <Col className="col-8">
                                <Form.Control
                                  type="text"
                                  //ref={nameRef}
                                  autoComplete="off"
                                  placeholder="Designation"
                                  value={designation}
                                  onChange={(e) =>
                                    setDesignation(e.target.value)
                                  }
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </Col>

                        <Col className="col-2"></Col>

                        <Col className="col-5">
                          <h6 className="mb-3">Company Information</h6>
                          <Form.Group className="mb-3">
                            <Row>
                              <Col className="col-4">
                                <Form.Label className="mt-1">
                                  Company Name
                                </Form.Label>
                              </Col>
                              <Col className="col-8">
                                <Form.Control
                                  type="text"
                                  //ref={nameRef}
                                  autoComplete="off"
                                  placeholder="Company Name"
                                  value={company}
                                  onChange={(e) => setCompany(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Row>
                              <Col className="col-4">
                                <Form.Label className="mt-1">
                                  Company Website
                                </Form.Label>
                              </Col>
                              <Col className="col-8">
                                <Form.Control
                                  type="text"
                                  //ref={nameRef}
                                  autoComplete="off"
                                  placeholder="Company Website"
                                  value={companyWebsite}
                                  onChange={(e) =>
                                    setCompanyWebsite(e.target.value)
                                  }
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </Col>
                      </Row>

                      {contactDetails ? (
                        <Button
                          variant="info"
                          type="submit"
                          className="save-proceed-btn"
                        >
                          Update{" "}
                          {pageFrom === "Contacts" ? "Contact" : "Prospect"}
                          <span>
                            <Icon.ChevronRight />
                          </span>
                        </Button>
                      ) : (
                        <Button
                          variant="info"
                          type="submit"
                          className="save-proceed-btn"
                        >
                          Add {pageFrom === "Contacts" ? "Contact" : "Prospect"}
                          <span>
                            <Icon.ChevronRight />
                          </span>
                        </Button>
                      )}
                    </Form>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body style={{ textAlign: "center" }}>
              <div className="alert-icon">
                <Icon.ExclamationTriangle />
              </div>
              Are you sure that you want to delete this {props.pagename}?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="info"
                onClick={() => {
                  // dispatch(
                  //   deleteContent(
                  //     props.authToken,
                  //     props.content.content_id,
                  //     props.content.type
                  //   )
                  // );
                  // setTimeout(() => {
                  //   dispatch(fetchAssetLibraryContentList(props.authToken));
                  // }, 2000);
                  handleClose();
                }}
              >
                Yes
              </Button>
              <Button variant="info" onClick={handleClose}>
                No
              </Button>
            </Modal.Footer>
          </Modal> */}
        </div>
      </div>
    </>
  );
};

export default AddContact;
