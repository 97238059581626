/* eslint-disable */
import { createContext, useState, useEffect } from "react";
//Redux-imports
import { useDispatch } from "react-redux";
// import {
//   setUnauthorizedAssetLibrary,
// } from "../redux/actions/actions";

import axios from "axios";
import Cookies from 'js-cookie';
import * as types from '../constants/ActionTypes';

const AuthContext = createContext({
  auth: {},
  onLogout: () => { },
  onLogin: (email, pasword) => { },
  baseUrl: "",
  loginUrl: "",
  arcLoginUrl:"",
  logoutUrl: "",
  tokenLoginUrl: "",
  topMenuUrl: "",
  assetLibraryUrl: "",
  forgotPasswordUrl: "",
  getOtpUrl: "",
  otpLoginUrl: "",
  generatePasswordUrl: "",
});

let tempUrl = `${window.location.href}`;
let baseUrl = "";
let loginUrl = "";
let arcLoginUrl = "";
let forgotPasswordUrl = "";
let getOtpUrl = "";
let otpLoginUrl = "";
let generatePasswordUrl = "";
let tokenLoginUrl = "";
let logoutUrl = "";
let topMenuUrl = "";
let assetLibraryUrl = "";

// if (tempUrl.search("localhost") > 0) {
//   baseUrl = "http://localhost/sp-bfsi/";
// } else if (tempUrl.search("app.spdevmfp.com") > 0) {
//   baseUrl = "https://app.spdevmfp.com/";
// } else if (tempUrl.search("app.sptestmfp.com") > 0) {
//   baseUrl = "https://app.sptestmfp.com/";
// } else {
//   baseUrl= window.location.href;
//   baseUrl = baseUrl.replace("react-ui/build/", '');
// }

if (tempUrl.search("localhost") > 0) {
  // baseUrl = "http://localhost/sp-bfsi/";
  // baseUrl = "https://spbfsi.com/";
  baseUrl = "https://app.sppreprod.in/";
  // baseUrl = "https://app.spdevmfp.com/";
} else {
  baseUrl = window.location.href;
  // baseUrl = baseUrl.replace("react-ui/build/", '');
  baseUrl = baseUrl.split('/')[0] + "/" + baseUrl.split('/')[1] + "/" + baseUrl.split('/')[2] + "/";
}

loginUrl = baseUrl + "framework/api/login";
arcLoginUrl = baseUrl + "framework/api/verify_ark_login";
tokenLoginUrl = baseUrl + "framework/api/tokenLogin";
logoutUrl = baseUrl + "framework/api/logout";
topMenuUrl = baseUrl + "framework/api/topMenu";
assetLibraryUrl = baseUrl + "framework/api/assetLibraryList";
forgotPasswordUrl = baseUrl + "framework/api/forgot-password";
getOtpUrl = baseUrl + "framework/api/getOtp";
otpLoginUrl = baseUrl + "framework/api/otpLogin";
generatePasswordUrl = baseUrl + "framework/api/genratePass";

export const AuthProvider = (props) => {

  const [userData, setUserData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    if (storedUserData !== {}) {
      setUserData(storedUserData);
    }
    //tokenLoginHandler();
  }, [setUserData]);

  const loginDataHanlder = (data) => {
    localStorage.setItem("userData", JSON.stringify(data));
    localStorage.setItem("authToken", JSON.stringify(data.token));
    localStorage.setItem("userType", JSON.stringify(data.user_type));
    // Cookies.set('SPSESSTKN', data.token);
    // let domain = `/${window.location.hostname}`;
    console.log("VAPT-Testing");
    Cookies.set('SPSESSTKN', data.token, { secure: true, path: "/home"});
    // document.cookie = `SPSESSTKN=${data.token}; secure=true; httpOnly=true;`;
    setUserData(data);
  };

  const logoutHandler = () => {
    //dispatch(setUnauthorizedAssetLibrary());
    localStorage.clear();
    Cookies.remove('SPSESSTKN');
    setUserData(null);
    // dispatch({type: types.FETCH_COMPANY_LOGO, payload: null});
  };

  //let tempUrl = `${window.location.href}`;

  // const tokenLoginHandler = async (e) => {
  //   if (window.location.href.indexOf("?token") > -1) {
  //     let currToken = tempUrl.split("?token=")[1];
  //     try {
  //       const response = await axios.post(tokenLoginUrl, {
  //         token: currToken,
  //       });
  //       if (response?.data?.statusCode === "200") {
  //         console.log("response", response);
  //         loginDataHanlder(response?.data);
  //       } else if (response?.data?.statusCode === "403") {
  //         alert(response?.data?.response);
  //       }
  //     } catch (err) {
  //       if (!err?.response) {
  //         alert("Invalid Token");
  //     }

  //   }
  // };

  return (
    <AuthContext.Provider
      value={{
        auth: userData ? userData.token : null,
        loginUrl: loginUrl,
        arcLoginUrl: arcLoginUrl,
        tokenLoginUrl: tokenLoginUrl,
        logoutUrl: logoutUrl,
        topMenuUrl: topMenuUrl,
        assetLibraryUrl: assetLibraryUrl,
        forgotPasswordUrl: forgotPasswordUrl,
        getOtpUrl: getOtpUrl,
        otpLoginUrl: otpLoginUrl,
        generatePasswordUrl: generatePasswordUrl,
        onLogout: logoutHandler,
        onLogin: loginDataHanlder,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext; 
