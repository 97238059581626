/* eslint-disable */
import storeApis from "../../api/storeApi";
import "react-toastify/dist/ReactToastify.min.css";
import * as types from "../../constants/ActionTypes";
import _ from "lodash";
import Toast from "../../components/shared/Toast";


const LEAD_DETAILS_URL = "/framework/api/appLeadDetails";
const PRODUCT_DROPDOWN_URL = "/framework/api/productDropdown";
const SOURCE_DROPDOWN_URL = "/framework/api/sourceDropdown";
const CITY_DROPDOWN_URL = "/framework/api/cityDropdown";
const SAVE_SETTINGS = "/framework/api/saveSettings";
const UPDATE_LEAD_STAGE = "/framework/api/updateLeadStage";
const DELETE_LEADS = "/framework/api/deleteLead";
const ADD_NOTE = "/framework/api/addNote";
const ADD_TASK = "/framework/api/addTask";
const APP_USER_ACTIVITY = "/framework/api/appUserActivity";
const LEAD_IMPORT_MAPPING_KEYS = "framework/api/leadMapData";
const LEAD_IMPORT_DOC = "framework/api/importDoc";
const EXPORT_LEADS = "framework/api/exportDoc";
const GET_CRM_IMPORT_TYPES = "framework/api/importTypes";
const DOWNLOAD_MAPPEDDATA_STATUS = "framework/api/downloadLeadStatus";
const APP_USER_EMAILS_DELETE = "framework/api/delete-email";
const FETCH_EMAIL_TEMPLATES = "framework/api/send-EmailToCRM";
const ADD_COMMENT = "framework/api/add-Comment";
const TASK_MANAGER = "framework/api/taskManager";
const CONTACTS_LIST = "framework/api/ContactsList";
const ADD_CONTACT_URL = "framework/api/addContacts";
const EDIT_CONTACT_URL = "framework/api/editContacts";
const DELETE_CONTACT_URL = "framework/api/app-Delete-Lead-Contact";
const CONTACT_IMPORT_DOC = "framework/api/contactimport";
const SHARE_VIA_URL = "framework/api/send-EmailCampaign";
const SHARE_LINK_URL = "framework/api/get_link";
import moment from "moment";

//SET Unauthorization Status
export const setCRMUnauthorized = () => {
    console.log("setCRMUnauthorizedCALLED");
    return (dispatch) => {
        dispatch({ type: "UNAUTHORIZED_CRM", payload: null });
    };
};

//Fetch Lead Stage List
export const fetchLeadStagesList = (authToken) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEAD_DETAILS_URL,
                { action: "stagelist"},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({
                        type: types.FETCH_STAGES_LIST,
                        payload: response.data,
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};



//Fetch All Lead Details
export const fetchAllLeadTypeDetails = (authToken, leadType, startLimit, filterbySource, filterSourceType,/* filterbyCity, */ filterbySort, date, sDate, eDate, gsearch) => {
   // console.log("filterbySource", filterbySource, "gsearch", gsearch, "date", date, "filterbySort", filterbySort, "startLimit", startLimit);
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEAD_DETAILS_URL,
                {
                    action: "stagedata",
                    key: "leads",
                    startlimit: ((filterbySource === null && gsearch === "" && date === null && filterbySort === null) && startLimit > 3) ? startLimit - 3 : 0,
                    endlimit: 3,
                    lead_type: leadType,
                    source: filterbySource,
                    filter_type: filterSourceType,
                    //city: filterbyCity,
                    sort: filterbySort,
                    date_value: date,
                    start_date: sDate,
                    end_date: eDate,
                    gsearch: gsearch,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    let data = response.data.activity_details;
                    // console.log("sff", { leadType: leadType, data: data });
                    //let total = 5;
                    dispatch({
                        type: types.ALL_LEAD_TYPE_DETAILS,
                        payload: { leadType: leadType.toString(), data: data, totalCount: response?.data?.total_data  ? response?.data?.total_data : 0},
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            console.log("ERROR", err);
            //dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};





//Fetch All Lead Details
export const fetchAllLeadDetails = (authToken, filterbySource, /* filterbyCity, */ filterbySort, date, sDate, eDate, gsearch) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEAD_DETAILS_URL,
                {
                    action: "stagedata",
                    key: "leads",
                    startlimit: 0,
                    endlimit: 3,
                    lead_type: 7,
                    source: filterbySource,
                    //city: filterbyCity,
                    sort: filterbySort,
                    date_value: date,
                    start_date: sDate,
                    end_date: eDate,
                    gsearch: gsearch,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({
                        type: types.FETCH_ALL_LEAD_DETAILS,
                        payload: response.data,
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//Fetch New Lead Details
export const fetchNewLeadDetails = (authToken, filterbySource, /* filterbyCity, */ filterbySort, date, sDate, eDate, gsearch) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEAD_DETAILS_URL,
                {
                    action: "stagedata",
                    key: "leads",
                    startlimit: 0,
                    endlimit: 3,
                    lead_type: 1,
                    source: filterbySource,
                    // city: filterbyCity,
                    sort: filterbySort,
                    date_value: date,
                    start_date: sDate,
                    end_date: eDate,
                    gsearch: gsearch,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({
                        type: types.FETCH_NEW_LEAD_DETAILS,
                        payload: response.data,
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//Fetch Engaged Lead Details
export const fetchEngagedLeadDetails = (authToken, filterbySource,/*  filterbyCity, */ filterbySort, date, sDate, eDate, gsearch) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEAD_DETAILS_URL,
                {
                    action: "stagedata",
                    key: "leads",
                    startlimit: 0,
                    endlimit: 3,
                    lead_type: 2,
                    source: filterbySource,
                    //city: filterbyCity,
                    sort: filterbySort,
                    date_value: date,
                    start_date: sDate,
                    end_date: eDate,
                    gsearch: gsearch,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({
                        type: types.FETCH_ENGAGED_LEAD_DETAILS,
                        payload: response.data,
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//Fetch Deal Lead Details
export const fetchDealLeadDetails = (authToken, filterbySource, /* filterbyCity, */ filterbySort, date, sDate, eDate, gsearch) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEAD_DETAILS_URL,
                {
                    action: "stagedata",
                    key: "leads",
                    startlimit: 0,
                    endlimit: 3,
                    lead_type: 3,
                    source: filterbySource,
                    //city: filterbyCity,
                    sort: filterbySort,
                    date_value: date,
                    start_date: sDate,
                    end_date: eDate,
                    gsearch: gsearch,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({
                        type: types.FETCH_DEAL_LEAD_DETAILS,
                        payload: response.data,
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//Fetch Quote Lead Details
export const fetchQuoteLeadDetails = (authToken, filterbySource,/*  filterbyCity, */ filterbySort, date, sDate, eDate, gsearch) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEAD_DETAILS_URL,
                {
                    action: "stagedata",
                    key: "leads",
                    startlimit: 0,
                    endlimit: 3,
                    lead_type: 4,
                    source: filterbySource,
                    //city: filterbyCity,
                    sort: filterbySort,
                    date_value: date,
                    start_date: sDate,
                    end_date: eDate,
                    gsearch: gsearch,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({
                        type: types.FETCH_QUOTE_LEAD_DETAILS,
                        payload: response.data,
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//Fetch Won Lead Details
export const fetchWonLeadDetails = (authToken, filterbySource, /* filterbyCity, */ filterbySort, date, sDate, eDate, gsearch) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEAD_DETAILS_URL,
                {
                    action: "stagedata",
                    key: "leads",
                    startlimit: 0,
                    endlimit: 3,
                    lead_type: 5,
                    source: filterbySource,
                    //city: filterbyCity,
                    sort: filterbySort,
                    date_value: date,
                    start_date: sDate,
                    end_date: eDate,
                    gsearch: gsearch,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({
                        type: types.FETCH_WON_LEAD_DETAILS,
                        payload: response.data,
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//Fetch Dropped Lead Details
export const fetchDroppedLeadDetails = (authToken, filterbySource, /* filterbyCity, */ filterbySort, date, sDate, eDate, gsearch) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEAD_DETAILS_URL,
                {
                    action: "stagedata",
                    key: "leads",
                    startlimit: 0,
                    endlimit: 3,
                    lead_type: 6,
                    source: filterbySource,
                    //city: filterbyCity,
                    sort: filterbySort,
                    date_value: date,
                    start_date: sDate,
                    end_date: eDate,
                    gsearch: gsearch,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({
                        type: types.FETCH_DROPPED_LEAD_DETAILS,
                        payload: response.data,
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//Load More Lead Details
export const loadMoreLeadDetails = (authToken, leadType, newLimit, endLimit) => {
    console.log("before api in action", moment(new Date()).format('mm:ss'));
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEAD_DETAILS_URL,
                {
                    action: "stagedata",
                    key: "leads",
                    startlimit: newLimit,
                    endlimit: endLimit ? endLimit : 3,
                    lead_type: leadType,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    console.log("before redux in action", moment(new Date()).format('mm:ss'));
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    dispatch({ type: types.LOAD_MORE_LEAD_DETAILS, payload: { leadType: leadType, data: response.data.activity_details } })
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//Fetch Product Dropdown
export const fetchProductDropdown = (authToken) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                PRODUCT_DROPDOWN_URL,
                {},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    const res = _.map(response.data.response).map((d) => {
                        return { label: d, value: d };
                    });
                    dispatch({
                        type: types.FETCH_PRODUCT_DROPDOWN,
                        payload: res,
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//Fetch Source Dropdown
export const fetchSourceDropdown = (authToken) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                SOURCE_DROPDOWN_URL,
                {},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    const res = response.data.response.map((d) => {
                        return { label: d.source, value: d.source };
                    });
                    dispatch({
                        type: types.FETCH_SOURCE_DROPDOWN,
                        payload: res,
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//Fetch City Dropdown
export const fetchCityDropdown = (authToken) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CITY_DROPDOWN_URL,
                {},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    const res = _.map(response.data.response).map((d, key) => {
                        return { label: d.distrcit_name, value: d.id };
                    });
                    dispatch({
                        type: types.FETCH_CITY_DROPDOWN,
                        payload: res,
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

const getCityFilteredData = (authToken, value) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEAD_DETAILS_URL,
                {
                    action: "stagedata",
                    key: "leads",
                    startlimit: 0,
                    endlimit: 3,
                    lead_type: 7,
                    "city": value,
                    "product": null,
                    "source": null
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({
                        type: types.FETCH_ALL_LEAD_DETAILS,
                        payload: response.data,
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    }
}

//Fetch Filtered Lead Details
export const fetchFilteredLeadDetails = (authToken, filterType, filterValue) => {
    switch (filterType) {
        case "product":
            return getCityFilteredData(authToken, filterValue);
        case "source":
            return getCityFilteredData(authToken, filterValue)
        case "city":
            return getCityFilteredData(authToken, filterValue);
        default:
            return null;
    }
};

//save settings
export const saveSettings = (authToken, settings) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                SAVE_SETTINGS,
                { settings: settings },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                // dispatch({
                //   type: types.SAVE_SETTINGS,
                //   payload: response.data,
                // });
                // dispatch(fetchLeadStagesList(authToken));
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    Toast("settings changed successfully", "S");
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//drop lead to other stage
export const updateLeadsStage = (authToken, leadDestination, contactId, stages, filters, from) => {
    console.log("update action");
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                UPDATE_LEAD_STAGE,
                {
                    lead_stage: leadDestination,
                    contact_id: contactId
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    if (from === "1" && stages.length > 0) {
                        dispatch(fetchLeadStagesList(authToken));
                        stages.map(stage => {
                            dispatch(fetchAllLeadTypeDetails(authToken, stage.lead_type, +stage.total,
                                filters.source,filters.filterSourceType, filters.sort, filters.time, filters.start_date, filters.end_date, filters.gsearch));
                        })
                    } else if (from === "2") {
                        dispatch(appUserActivity(authToken, contactId));
                    }

                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    Toast(response.data.response, 'S');
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
            Toast(err.data.message, "E");
        }
    };
};

//delete leads
export const deleteLeads = (authToken, leadsIdList, stages, filters) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                DELETE_LEADS,
                { contact_ids: leadsIdList },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch(fetchLeadStagesList(authToken));
                    if (stages.length > 0) {
                        stages.map(stage => {
                            dispatch(fetchAllLeadTypeDetails(authToken, stage.lead_type, +stage.total,
                                filters.source, filters.filterSourceType, filters.sort, filters.time, filters.start_date, filters.end_date, filters.gsearch));
                        })
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    Toast(response.data.response, "S");
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//Export Lead Details
export const exportLeadDetails = (authToken, leadType, filterbySource, filterSourceType,/* filterbyCity, */ filterbySort, date, sDate, eDate, gsearch) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                EXPORT_LEADS,
                {
                    action: "stagedata",
                    key: "leads",
                    lead_type: leadType,
                    source: filterbySource,
                    filter_type: filterSourceType,
                    //city: filterbyCity,
                    sort: filterbySort,
                    date_value: date,
                    start_date: sDate,
                    end_date: eDate,
                    gsearch: gsearch,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                        //'Content-Type': 'blob',
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    var filename = "Leads";
                    filename = filename + ".csv";
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/xlsx" })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                    Toast("Exported Successfully", "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//fetch crm import types
export const fetchCrmImportTypes = (authToken, domainName) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                GET_CRM_IMPORT_TYPES,
                {domain_name : domainName},
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({
                        type: types.CRM_IMPORT_TYPES,
                        payload: response.data.response,
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//Fetch import mapping List
export const LeadImportMappingData = (authToken, body) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEAD_IMPORT_MAPPING_KEYS,
                body,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({
                        type: types.LEAD_IMPORT_MAPPINGS,
                        payload: response.data.response,
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//Post import mappings 
export const LeadImportDoc = (authToken, formData, storeIdx, importType) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEAD_IMPORT_DOC,
                formData,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                        "Content-type": "multipart/form-data"
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.response, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: types.MAPPED_MEMORY_DATA, payload: storeIdx });
                    if (importType == 1) {
                        dispatch({ type: types.CRM_IMPORT_DATA_INSERT_ID, payload: 0 });
                    } else if (importType == 2) {
                        dispatch({ type: types.CRM_IMPORT_DATA_INSERT_ID, payload: response.data.inserted_id });
                    }
                    dispatch({ type: types.DOWNLOAD_MAPPEDDATA_STATUS_FILE_PATH, payload: response.data.file_path });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    Toast(response.data.response, "S");
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//export imported data with status
export const exportImportedDataStatus = (authToken, file, index1, index2, index3, index4, importedType) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                DOWNLOAD_MAPPEDDATA_STATUS,
                {
                    file_path: file,
                    index1: index1,
                    index2: index2,
                    index3: index3,
                    index4: index4,
                    imported_type: importedType
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    var filename = "Leads_mapped_data";
                    filename = filename + ".csv";
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/xlsx" })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                    Toast("Exported MappedData Successfully", "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//add Note
export const addNote = (authToken, contactIds, title, note, stagesList) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                TASK_MANAGER,
                {
                    action: "add",
                    associated_as: "0",
                    associated_with: contactIds,
                    notes: note,
                    task_category: "Notes",
                    title: title
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    dispatch(appUserActivity(authToken, contactIds));
                    Toast(response.data.message, "S");
                    if (stagesList.length > 0) {
                        stagesList.map(item => {
                            console.log("item lt", item.lead_type);
                            dispatch(fetchAllLeadTypeDetails(authToken, item.lead_type, null, null, null, null, null, null, null));
                        });
                    }
                }
            }
        } catch (err) {
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            // dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//add Task
export const addTask = (authToken, contactIds, title, note, date, remind, stagesList, val) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                TASK_MANAGER,
                {
                    action: "add",
                    associated_as: "0",
                    associated_with: contactIds,
                    due_date: date,
                    notes: note,
                    reminder_date: remind,
                    task_category: "Task",
                    title: title
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    Toast(response.data.message, "S");
                    if (val === "lpage" && stagesList.length > 0) {
                        stagesList.map(item => {
                            console.log("item lt", item.lead_type);
                            dispatch(fetchAllLeadTypeDetails(authToken, item.lead_type, null, null, null, null, null, null, null));
                        });
                    }else if(val === "dpage"){
                        console.log("fetchTaskList");
                        dispatch(fetchTaskList(authToken, contactIds));
                        dispatch(appUserActivity(authToken, contactIds));
                    }
                }
            }
        } catch (err) {
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//get user activity
export const appUserActivity = (authToken, contactId) => {
    //console.log("appUserActivity",appUserActivity);
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                APP_USER_ACTIVITY,
                {
                    contact_id: contactId
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({
                        type: types.USER_ACTIVITY_DETAILS,
                        payload: response.data,
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            // console.log("ERROR", err);
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//delete app user emails
export const deleteAppUserEmails = (authToken, mailIds, contactId) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                APP_USER_EMAILS_DELETE,
                {
                    action: "delete",
                    mail_id: mailIds //array of ids
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    Toast("Email Deleted Successfully", "S");
                    dispatch(appUserActivity(authToken, contactId))
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//get mail templates
export const fetchEmailTemplates = (authToken, mailIds, contactId) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                FETCH_EMAIL_TEMPLATES,
                null,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({
                        type: types.STORE_EMAIL_TEMPLATES,
                        payload: response.data.data,
                    });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//send mail also a test mail
export const sendMailOrTestmail = (authToken, mailIds, subject, message, content_type, contentList, linkList, testmailList, scheduleMail,sdate, button, campid, stagesList) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                FETCH_EMAIL_TEMPLATES,
                /* {
                    action: "lead_tracking_link",
                    app_footer_message: "",
                    app_message: "\nhttps://web-link.co/43hk0\n",
                    attachment_file: contentList, // array of ids of content dropdown and its multi select
                    attachment_link: linkList, //id of link dropdown and single select
                    button: button, //Send Now or Save as Draft
                    camp_id: campid, // select template id
                    colorRadio: content_type, //(mail-only => mail Attachment => upload) as values
                    etype: "direct", // constant
                    from_name: "",
                    list: mailIds, // for multiple its a array of strings "_,_"
                    lpage_id: "", //as of now we can send blank field
                    mailsubject: subject,
                    message: message,
                    scheduleCheckbox: scheduleMail,
                    schedule_events: "",
                    test_mail: testmailList,
                    sendTestMail: ""
                }, */

                {
                    action: "lead_tracking_link",
                    app_footer_message: "",
                    app_message: "", //\nhttps://web-link.co/43hk0\n
                    attachment_file: contentList,
                    attachment_link: linkList !== "" ? 1 : 0,
                    button: button,
                    camp_id: campid,
                    colorRadio: content_type,
                    etype: "direct",
                    from_name: "",
                    list: mailIds,
                    lpage_id: "",
                    link_file: linkList,
                    mailsubject: subject,
                    message: message,
                    schedule: scheduleMail,
                    //schedule: date ? 1 : 0,
                    schedule_date: sdate,
                    schedule_events: "",
                    test_mail: testmailList
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    Toast(response.data.message, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    if (stagesList !== null && stagesList.length > 0) {
                        stagesList.map(item => {
                            console.log("item lt", item.lead_type);
                            dispatch(fetchAllLeadTypeDetails(authToken, item.lead_type, null, null, null, null, null, null, null));
                        });
                    }
                }
            }
        } catch (err) {
            console.log("error", err);
            Toast(err.response.message, "E");
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//add comment to the activities
export const addCommentForActivity = (authToken, contactId, taskId, type, comment) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                ADD_COMMENT,
                {
                    contact_id: contactId,
                    task_id: taskId,
                    type: type,
                    comment: comment
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    Toast("Comment Added Successfully", "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, "E");
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};


export const taskManager = (authToken, body, val, id) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                TASK_MANAGER,
                body,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    Toast(response.data.message, "S");
                    if (val === 'deleteTask') {
                        dispatch(fetchTaskList(authToken, id));
                        dispatch(appUserActivity(authToken,id));
                    } else if (val === 'mark') {
                        dispatch(appUserActivity(authToken, id));
                    } else if (val === 'deleteNote') {
                        dispatch(appUserActivity(authToken, id));
                    }else if(val === "editTask"){
                        dispatch(fetchTaskList(authToken,id));
                        dispatch(appUserActivity(authToken,id));
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(rr.response.message, "E");
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

export const fetchTaskList = (authToken, id) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                TASK_MANAGER,
                {
                    action: "list",
                    contact_id: id
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: types.STORE_TASK_LIST, payload: response.data.response });
                    //dispatch(appUserActivity(authToken, id));
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, "E");
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//Fetch Contacts List
export const fetchContactsList = (authToken, search, startlimit) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CONTACTS_LIST,
                {
                    gsearch: search ? search : null,
                    startlimit: startlimit ? startlimit : 0,
                    endlimit: 10
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: types.FETCH_CONTACTS_LIST, payload: response.data });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, "E");
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//Fetch Prospects List
export const fetchProspectsList = (authToken, search, startlimit) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LEAD_DETAILS_URL,
                /* {
                    known: 1,
                    search: search ? search : null,
                    startlimit: startlimit ? startlimit : 0,
                    endlimit: 10
                } */
                {
                    action: "stagedata",
                    key: "leads",
                    lead_type: "1",
                    startlimit: startlimit ? startlimit : 0,
                    endlimit: 10,
                    gsearch: search ? search : null,
                }
                ,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    console.log("prospects", response.data.activity_details);
                    let obj = { data: response.data.activity_details };
                    dispatch({ type: types.FETCH_PROSPECTS_LIST, payload: obj });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, "E");
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};


//Add Contact
export const addContact = (authToken, newContact) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                ADD_CONTACT_URL,
                newContact,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    Toast(response.data.message, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, "E");
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

//Fetch Contact Details
export const fetchContactDetails = (authToken, contactId) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        if (authToken) {
            try {
                const response = await storeApis.post(
                    EDIT_CONTACT_URL,
                    { contact_id: contactId },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: `Bearer ${authToken}`,
                        },
                    }
                );
                if (response.status === 200) {
                    if (response.data.statusCode == "403") {
                        if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                            dispatch({
                                type: "UNAUTHORIZED_CRM",
                                payload: response.data.statusCode,
                            });
                        } else {
                            Toast(response.data.message, "E");
                        }
                        dispatch({ type: "TOGGLE_LOADING", payload: false });
                    } else {
                        dispatch({ type: types.FETCH_CONTACT_DETAILS, payload: response.data.response });
                        dispatch({ type: "TOGGLE_LOADING", payload: false });
                    }
                }
            } catch (err) {
                Toast(err.response.message, "E");
                dispatch({ type: "TOGGLE_LOADING", payload: false });
                dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
            }
        } else {
            dispatch({ type: types.FETCH_CONTACT_DETAILS, payload: null });
        }

    };
};


//Delete Contact
export const deleteContact = (authToken, contactId) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        if (authToken) {
            try {
                const response = await storeApis.post(
                    DELETE_CONTACT_URL,
                    {
                        action: "delete_contact",
                        lead_select: [...contactId]
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: `Bearer ${authToken}`,
                        },
                    }
                );
                if (response.status === 200) {
                    if (response.data.statusCode == "403") {
                        if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                            dispatch({
                                type: "UNAUTHORIZED_CRM",
                                payload: response.data.statusCode,
                            });
                        } else {
                            Toast(response.data.message, "E");
                        }
                        dispatch({ type: "TOGGLE_LOADING", payload: false });
                    } else {
                        Toast(response.data.message, "S");
                        dispatch({ type: "TOGGLE_LOADING", payload: false });
                    }
                }
            } catch (err) {
                Toast(err.response.message, "E");
                dispatch({ type: "TOGGLE_LOADING", payload: false });
                dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
            }
        } else {
            dispatch({ type: types.FETCH_CONTACT_DETAILS, payload: null });
        }

    };
};

//move contacts to prospects and leads
export const moveContactsProspectsLeads = (authToken, body, val) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        dispatch({ type: types.RESET_CONTACT_PROSPECT_PAGING, payload: 0 });
        try {
            const response = await storeApis.post(
                UPDATE_LEAD_STAGE,
                body,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.response, "I");
                    }
                } else {
                    Toast(response.data.response, "S");
                    if (val === 'prospects') {
                        dispatch(fetchProspectsList(authToken));
                    } else if (val === 'contacts') {
                        dispatch(fetchContactsList(authToken));
                    }
                }
                dispatch({ type: "TOGGLE_LOADING", payload: false });
                dispatch({ type: types.RESET_CONTACT_PROSPECT_PAGING, payload: 1 });
            }
        } catch (err) {
            dispatch({ type: types.RESET_CONTACT_PROSPECT_PAGING, payload: 0 });
            Toast(err.response.message, "E");
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    };
};

// contact/prospect import doc
//Post import mappings 
export const contactImportDoc = (authToken, formData, storeIdx) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CONTACT_IMPORT_DOC,
                formData,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                        "Content-type": "multipart/form-data"
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CRM",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                } else {
                    dispatch({ type: types.CONTACT_MAPPED_MEMORY_DATA, payload: storeIdx });
                    dispatch({ type: types.CONTACT_DOWNLOAD_MAPPEDDATA_STATUS_FILE_PATH, payload: response.data.file_path });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    Toast(response.data.response, "S");
                }
            }
        } catch (err) {
            Toast(err.response.message, "E");
            dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
        }
    }
}

//Share Via Email
export const shareViaEmail = (authToken, shareData) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        if (authToken) {
            try {
                const response = await storeApis.post(
                    SHARE_VIA_URL,
                    shareData,
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: `Bearer ${authToken}`,
                        },
                    }
                );
                if (response.status === 200) {
                    if (response.data.statusCode == "403") {
                        if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                            dispatch({
                                type: "UNAUTHORIZED_CRM",
                                payload: response.data.statusCode,
                            });
                        } else {
                            Toast(response.data.message, "E");
                        }
                    } else {
                        if (shareData) {
                            Toast(response.data.message, "S");
                            dispatch({ type: "TOGGLE_LOADING", payload: false });
                        } else {
                            dispatch({ type: types.FETCH_CONTACT_LIST, payload: response.data.contacts_list });
                            dispatch({ type: "TOGGLE_LOADING", payload: false });
                        }
                    }
                }
            } catch (err) {
                Toast(err.response.message, "E");
                dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
            }
        } else {
            dispatch({ type: types.FETCH_CONTACT_LIST, payload: null });
        }

    };
};

//Get Share Link
export const getShareLink = (authToken, linkData) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        if (authToken) {
            try {
                const response = await storeApis.post(
                    SHARE_LINK_URL,
                    linkData,
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: `Bearer ${authToken}`,
                        },
                    }
                );
                if (response.status === 200) {
                    if (response.data.statusCode == "403") {
                        if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                            dispatch({
                                type: "UNAUTHORIZED_CRM",
                                payload: response.data.statusCode,
                            });
                        } else {
                            Toast(response.data.message, "E");
                        }
                    } else {
                        dispatch({ type: types.GET_SHARE_LINK, payload: response.data });
                        dispatch({ type: "TOGGLE_LOADING", payload: false });
                    }
                }
            } catch (err) {
                Toast(err.response.message, "E");
                dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
            }
        } else {
            dispatch({ type: types.GET_SHARE_LINK, payload: null });
        }

    };
};

//Get Email Short Link
export const getEmailShortLink = (link) => {
    return async (dispatch) => {
        if (link) {
            try {
                const response = await storeApis.post(
                    link,
                );
                if (response.status === 200) {
                    if (response.data.statusCode == "403") {
                        if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                            dispatch({
                                type: "UNAUTHORIZED_CRM",
                                payload: response.data.statusCode,
                            });
                        } else {
                            Toast(response.data.message, "E");
                        }
                    } else {
                        dispatch({ type: types.GET_EMAIL_SHORT_LINK, payload: response.data.result });
                    }
                }
            } catch (err) {
                console.log("unable to get short link, as Share Track link is empty");
                //Toast(err.response.message, "E");
            }
        } else {
            dispatch({ type: types.GET_EMAIL_SHORT_LINK, payload: "" });
        }
    };
};

//Get FB Short Link
export const getFBShortLink = (link) => {
    return async (dispatch) => {
        if (link) {
            try {
                const response = await storeApis.post(
                    link,
                );
                if (response.status === 200) {
                    if (response.data.statusCode == "403") {
                        if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                            dispatch({
                                type: "UNAUTHORIZED_CRM",
                                payload: response.data.statusCode,
                            });
                        } else {
                            Toast(response.data.message, "E");
                        }
                    } else {
                        dispatch({ type: types.GET_FB_SHORT_LINK, payload: response.data.result });
                    }
                }
            } catch (err) {
                console.log("unable to get short link, as Share Track link is empty");
                //Toast(err.response.message, "E");
            }
        } else {
            dispatch({ type: types.GET_FB_SHORT_LINK, payload: "" });
        }
    };
};

//Get TW Short Link
export const getTWShortLink = (link) => {
    return async (dispatch) => {
        if (link) {
            try {
                const response = await storeApis.post(
                    link,
                );
                if (response.status === 200) {
                    if (response.data.statusCode == "403") {
                        if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                            dispatch({
                                type: "UNAUTHORIZED_CRM",
                                payload: response.data.statusCode,
                            });
                        } else {
                            Toast(response.data.message, "E");
                        }
                    } else {
                        dispatch({ type: types.GET_TW_SHORT_LINK, payload: response.data.result });
                    }
                }
            } catch (err) {
                console.log("unable to get short link, as Share Track link is empty");
                //Toast(err.response.message, "E");
            }
        } else {
            dispatch({ type: types.GET_TW_SHORT_LINK, payload: "" });
        }
    };
};

//Get LN Short Link
export const getLNShortLink = (link) => {
    return async (dispatch) => {
        if (link) {
            try {
                const response = await storeApis.post(
                    link,
                );
                if (response.status === 200) {
                    if (response.data.statusCode == "403") {
                        if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                            dispatch({
                                type: "UNAUTHORIZED_CRM",
                                payload: response.data.statusCode,
                            });
                        } else {
                            Toast(response.data.message, "E");
                        }
                    } else {
                        dispatch({ type: types.GET_LN_SHORT_LINK, payload: response.data.result });
                    }
                }
            } catch (err) {
                console.log("unable to get short link, as Share Track link is empty");
                //Toast(err.response.message, "E");
            }
        } else {
            dispatch({ type: types.GET_LN_SHORT_LINK, payload: "" });
        }
    };
};

//Get WH Short Link
export const getWHShortLink = (link) => {
    return async (dispatch) => {
        if (link) {
            try {
                const response = await storeApis.post(
                    link,
                );
                if (response.status === 200) {
                    if (response.data.statusCode == "403") {
                        if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                            dispatch({
                                type: "UNAUTHORIZED_CRM",
                                payload: response.data.statusCode,
                            });
                        } else {
                            Toast(response.data.message, "E");
                        }
                    } else {
                        dispatch({ type: types.GET_WH_SHORT_LINK, payload: response.data.result });
                    }
                }
            } catch (err) {
                console.log("unable to get short link, as Share Track link is empty");
                //Toast(err.response.message, "E");
            }
        } else {
            dispatch({ type: types.GET_WH_SHORT_LINK, payload: "" });
        }
    };
};




//Share Via Email
export const shareViaSalesForceEmail = (authToken, shareData) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        if (authToken) {
            try {
                const response = await storeApis.post(
                    "https://bizightsolutionsprivatelimited--devsandbox.sandbox.my.salesforce-sites.com/services/apexrest/webhook/Email",
                    shareData
                );
                if (response.status === 200) {
                    if (response.data.statusCode == "403") {
                        if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                            dispatch({
                                type: "UNAUTHORIZED_CRM",
                                payload: response.data.statusCode,
                            });
                        } else {
                            Toast(response.data.message, "E");
                        }
                    } else {
                        if (shareData) {
                            Toast(response.data.message, "S");
                            dispatch({ type: "TOGGLE_LOADING", payload: false });
                        } else {
                            dispatch({ type: types.FETCH_CONTACT_LIST, payload: response.data.contacts_list });
                            dispatch({ type: "TOGGLE_LOADING", payload: false });
                        }
                    }
                }
            } catch (err) {
                Toast(err.response.message, "E");
                dispatch({ type: "UNAUTHORIZED_CRM", payload: err.response.status });
            }
        } else {
            dispatch({ type: types.FETCH_CONTACT_LIST, payload: null });
        }

    };
};
