import { sortBy } from "lodash"
import { List } from "react-bootstrap-icons"

//LOADING
export const TOGGLE_LOADING = 'TOGGLE_LOADING'
export const LIST_LOADING = 'LIST_LOADING'
export const FILTER_LOADING = 'FILTER_LOADING'


//-----------------------------------------------------------------------------------------//

//ERROR
export const UNAUTHORIZED_ASSET_LIBRARY = 'UNAUTHORIZED_ASSET_LIBRARY'
export const UNAUTHORIZED_ASSET_UPLOAD = 'UNAUTHORIZED_ASSET_UPLOAD'

//-----------------------------------------------------------------------------------------//

//LOGOUT
export const LOGOUT = 'LOGOUT'

//-----------------------------------------------------------------------------------------//

//FORGOT PASSWORD
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'

//-----------------------------------------------------------------------------------------//

//RESET PASSWORD
export const RESET_PASSWORD = 'RESET_PASSWORD'

//-----------------------------------------------------------------------------------------//

//FETCH RESET PASSWORD DETAILS
export const FETCH_RESET_PASSWORD_DETAILS = 'FETCH_RESET_PASSWORD_DETAILS'

//-----------------------------------------------------------------------------------------//

//HEADER
// Get top menu
export const FETCH_TOP_MENU = 'FETCH_TOP_MENU'

//-----------------------------------------------------------------------------------------//

//Get company logo
export const FETCH_COMPANY_LOGO = 'FETCH_COMPANY_LOGO'

//Get company logo on topmenu
export const FETCH_TOPMENU_COMPANY_LOGO = 'FETCH_TOPMENU_COMPANY_LOGO'

//GLOBAL SEARCH
export const SEARCH_ASSET_LIBRARY_CONTENT_LIST = "SEARCH_ASSET_LIBRARY_CONTENT_LIST"

//GLOBAL FILTER
export const GLOBAL_FILTER_ASSET_LIBRARY_CONTENT_LIST = "GLOBAL_FILTER_ASSET_LIBRARY_CONTENT_LIST"

//SET GLOBAL SEARCH KEY
export const SET_GLOBAL_SEARCH_KEY = "SET_GLOBAL_SEARCH_KEY"

//SET GLOBAL QFILTER KEY
export const SET_GLOBAL_QFILTER_KEY = "SET_GLOBAL_QFILTER_KEY"

//SET GLOBAL CFILTER KEY
export const SET_GLOBAL_CFILTER_KEY = "SET_GLOBAL_CFILTER_KEY"

export const SET_GLOBAL_PFILTER_KEY = "SET_GLOBAL_PFILTER_KEY"

//SET GLOBAL SFILTER KEY
export const SET_GLOBAL_SFILTER_KEY = "SET_GLOBAL_SFILTER_KEY"

//SET GLOBAL SOFILTER KEY
export const SET_GLOBAL_SOFILTER_KEY = "SET_GLOBAL_SOFILTER_KEY"

//-----------------------------------------------------------------------------------------//

//GLOBAL SEARCH SUGGESTION LIST
export const SEARCH_SUGGESTION_LIST = "SEARCH_SUGGESTION_LIST"

//-----------------------------------------------------------------------------------------//

//REMOVE SUGGESTION LIST
export const REMOVE_SUGGESTION_LIST = "REMOVE_SUGGESTION_LIST"

//-----------------------------------------------------------------------------------------//

// ASSET LIBRARY LIST

// Update Previous URL
export const SET_PREVIOUS_URL = 'SET_PREVIOUS_URL'

//update end limit
export const END_LIMIT_MIROSITE = 'END_LIMIT_MIROSITE'

// GET asset library content list
export const FETCH_ASSET_LIBRARY_CONTENT_LIST = 'FETCH_ASSET_LIBRARY_CONTENT_LIST'

// GET asset library personalize list
export const FETCH_PERSONALIZE_LIST = 'FETCH_PERSONALIZE_LIST'

export const SET_SELECTED_PERSONALIZE = 'SET_SELECTED_PERSONALIZE'

// GET asset library folder list
export const FETCH_ASSET_LIBRARY_FOLDER_LIST = 'FETCH_ASSET_LIBRARY_FOLDER_LIST'

// GET asset library category list
export const FETCH_ASSET_LIBRARY_CATEGORY_LIST = 'FETCH_ASSET_LIBRARY_CATEGORY_LIST'

// GET asset library category list
export const FETCH_ASSET_LIBRARY_HASHTAG_LIST = 'FETCH_ASSET_LIBRARY_HASHTAG_LIST'

// GET asset library quick filters
export const FETCH_ASSET_LIBRARY_QUICK_FILTERS = 'FETCH_ASSET_LIBRARY_QUICK_FILTERS'

// SET asset library quick filter
export const SET_ASSET_LIBRARY_QUICK_FILTER = 'SET_ASSET_LIBRARY_QUICK_FILTER'

// SET asset library quick filters
export const QUICK_FILTERS = 'QUICK_FILTERS'

// SET asset library filter
export const FILTER = 'FILTER'

// SET asset library sort filter
export const SORT_FILTER = 'SORT_FILTER'

// SET asset library category filter
export const SET_ASSET_LIBRARY_CATEGORY_FILTER = 'SET_ASSET_LIBRARY_CATEGORY_FILTER'

// SET asset library status filter
export const SET_ASSET_LIBRARY_STATUS_FILTER = 'SET_ASSET_LIBRARY_STATUS_FILTER'

// SET asset library sortby filter
export const SET_ASSET_LIBRARY_SORTBY_FILTER = 'SET_ASSET_LIBRARY_SORTBY_FILTER'

// SET asset bookmark
export const SET_ASSET_BOOKMARK = 'SET_ASSET_BOOKMARK'

// Set main asset content
export const UPDATE_MAIN_CONTENT = 'UPDATE_MAIN_CONTENT'

// SET asset library content list Pagination
export const SET_ASSET_LIBRARY_CONTENT_LIST_PAGINATION = 'SET_ASSET_LIBRARY_CONTENT_LIST_PAGINATION'

// CREATE asset folder
export const CREATE_ASSET_FOLDER = 'CREATE_ASSET_FOLDER'

// FETCH asset to folder dropdown list
export const FETCH_ASSET_FOLDER_DROPDOWN_LIST = 'FETCH_ASSET_FOLDER_DROPDOWN_LIST'

// SET asset library folder
export const FOLDER = 'FOLDER'

// ADD content to folder
export const ADD_CONTENT_TO_FOLDER = 'ADD_CONTENT_TO_FOLDER'

// GET folder content list
export const FETCH_FOLDER_CONTENT_LIST = 'FETCH_FOLDER_CONTENT_LIST'

// EDIT folder name
export const EDIT_FOLDER_NAME = 'EDIT_FOLDER_NAME'

// PUBLISH asset content
export const PUBLISH_ASSET_CONTENT = 'PUBLISH_ASSET_CONTENT'

// DELETE asset content
export const DELETE_ASSET_CONTENT = 'DELETE_ASSET_CONTENT'

// DELETE asset bulk content
export const DELETE_ASSET_BULK_CONTENT = 'DELETE_ASSET_BULK_CONTENT'

// DUPLICATE asset content
export const DUPLICATE_ASSET_CONTENT = 'DUPLICATE_ASSET_CONTENT'

// EDIT asset content
export const EDIT_ASSET_CONTENT = 'EDIT_ASSET_CONTENT'

// SET asset
export const SET_ASSET = 'SET_ASSET'

// FETCH asset content details
export const FETCH_ASSET_CONTENT_DETAILS = 'FETCH_ASSET_CONTENT_DETAILS'

//-----------------------------------------------------------------------------------------//

// ASSET LIBRARY UPLOAD
// SET breadcrumb status
export const SET_BREADCRUMB_STATUS = 'SET_BREADCRUMB_STATUS'

export const SET_AUTOEMAIL_STATUS = 'SET_AUTOEMAIL_STATUS'

export const SET_AUTOEMAIL_PATH = 'SET_AUTOEMAIL_PATH'

// GET asset select type list
export const FETCH_ASSET_SELECT_TYPE_LIST = 'FETCH_ASSET_SELECT_TYPE_LIST' 

// GET asset select type list
export const AUTOEMAIL_DATA = 'AUTOEMAIL_DATA' 

// GET campaign list
export const FETCH_CAMPAIGN_LIST = 'FETCH_CAMPAIGN_LIST'

// GET greeting list
export const FETCH_GREETING_LIST = 'FETCH_GREETING_LIST'

// GET landingpage urls
export const FETCH_LANDINGPAGE_URLS = 'FETCH_LANDINGPAGE_URLS'

// GET landingpage urls
export const FETCH_CREATE_TEMPLATE_DATA = 'FETCH_CREATE_TEMPLATE_DATA'

// GET showcase urls
export const FETCH_SHOWCASE_URLS = 'FETCH_SHOWCASE_URLS'

// GET microsite urls
export const FETCH_MICROSITE_URLS = 'FETCH_MICROSITE_URLS'

// SET selected asset type
export const SET_SELECTED_ASSET_TYPE = 'SET_SELECTED_ASSET_TYPE'

// SET base upload data
export const SET_BASE_UPLOAD_DATA = 'SET_BASE_UPLOAD_DATA'

// SET global fields data
export const SET_GLOBAL_FIELDS_DATA = 'SET_GLOBAL_FIELDS_DATA'

// SET selected categories
export const SET_SELECTED_CATEGORIES = 'SET_SELECTED_CATEGORIES'

// SET selected campaigns
export const SET_SELECTED_CAMPAIGNS = 'SET_SELECTED_CAMPAIGNS'

// SET selected hashtags
export const SET_SELECTED_HASHTAGS = 'SET_SELECTED_HASHTAGS'

// SET selected greetings
export const SET_SELECTED_GREETINGS = 'SET_SELECTED_GREETINGS'

// SET selected content type
export const SET_SELECTED_CONTENT_TYPE = 'SET_SELECTED_CONTENT_TYPE'

// SET selected Business type
export const SET_SELECTED_BUSINESS_TYPE = 'SET_SELECTED_BUSINESS_TYPE'

// SET selected defineurl
export const SET_SELECTED_DEFINE_URL = 'SET_SELECTED_DEFINE_URL'

// SET selected customurl
export const SET_CUSTOM_URL = 'SET_CUSTOM_URL'

// SAVE global fields data
export const SAVE_GLOBAL_FIELDS_DATA = 'SAVE_GLOBAL_FIELDS_DATA'

// GET partners access list
export const FETCH_PARTNERS_LIST = 'FETCH_PARTNERS_LIST'

// GET users access list
export const FETCH_USERS_LIST = 'FETCH_USERS_LIST'

// SET selected asset publish
export const SET_SELECTED_ASSET_PUBLISH = 'SET_SELECTED_ASSET_PUBLISH'

// PUBLISH asset direct
export const DIRECT_PUBLISH_ASSET = 'DIRECT_PUBLISH_ASSET'

// EDIT asset 
export const EDIT_ASSET = 'EDIT_ASSET'

// PUBLISH asset
export const PUBLISH_ASSET = 'PUBLISH_ASSET'

// GET content type list
export const FETCH_CONTENT_TYPE_LIST = 'FETCH_CONTENT_TYPE_LIST'

// GET keywords list
export const FETCH_KEYWORDS_LIST = 'FETCH_KEYWORDS_LIST'

// GET cta list
export const FETCH_CTA_LIST = 'FETCH_CTA_LIST'

//-----------------------------------------------------------------------------------------//
// CRM LEAD LIST

// GET stages list
export const FETCH_STAGES_LIST = 'FETCH_STAGES_LIST'

//ResetPageination in contact/prospect
export const  RESET_CONTACT_PROSPECT_PAGING = 'RESET_CONTACT_PROSPECT_PAGING'

//Clear stages list
export const CLEAR_STAGES_LIST = 'CLEAR_STAGES_LIST'

// GET all lead details
export const FETCH_ALL_LEAD_DETAILS = 'FETCH_ALL_LEAD_DETAILS'

// GET new lead details
export const FETCH_NEW_LEAD_DETAILS = 'FETCH_NEW_LEAD_DETAILS'

// GET engaged lead details
export const FETCH_ENGAGED_LEAD_DETAILS = 'FETCH_ENGAGED_LEAD_DETAILS'

// GET deal lead details
export const FETCH_DEAL_LEAD_DETAILS = 'FETCH_DEAL_LEAD_DETAILS'

// GET quote lead details
export const FETCH_QUOTE_LEAD_DETAILS = 'FETCH_QUOTE_LEAD_DETAILS'

// GET won lead details
export const FETCH_WON_LEAD_DETAILS = 'FETCH_WON_LEAD_DETAILS'

// GET dropped lead details
export const FETCH_DROPPED_LEAD_DETAILS = 'FETCH_DROPPED_LEAD_DETAILS'

// LOAD more lead details
export const LOAD_MORE_LEAD_DETAILS = 'LOAD_MORE_LEAD_DETAILS'

// GET product dropdown
export const FETCH_PRODUCT_DROPDOWN = 'FETCH_PRODUCT_DROPDOWN'

// GET source dropdown
export const FETCH_SOURCE_DROPDOWN = 'FETCH_SOURCE_DROPDOWN'

// GET city dropdown
export const FETCH_CITY_DROPDOWN = 'FETCH_CITY_DROPDOWN'

// GET filtered leads details
export const FETCH_FILTERED_LEAD_DETAILS = 'FETCH_FILTERED_LEAD_DETAILS'

//save settings
//export const SAVE_SETTINGS = 'SAVE_SETTINGS'

//update lead stage
export const UPDATE_LEAD_STAGE = 'UPDATE_LEAD_STAGE'

//POST app user activity details
export const USER_ACTIVITY_DETAILS = 'USER_ACTIVITY_DETAILS'

//POST get lead import mapping data
export const LEAD_IMPORT_MAPPINGS = 'LEAD_IMPORT_MAPPING_KEYS'

//get crm import types
export const CRM_IMPORT_TYPES = 'CRM_IMPORT_TYPES'

//store the lead mappings 
export const MAPPED_MEMORY_DATA = 'MAPPED_MEMORY_DATA'

//store the lead download file url of mappedData status 
export const DOWNLOAD_MAPPEDDATA_STATUS_FILE_PATH = 'DOWNLOAD_MAPPEDDATA__STATUS_FILE_PATH' 

//store email templates
export const STORE_EMAIL_TEMPLATES = "STORE_EMAIL_TEMPLATES"

//store taskList
export const STORE_TASK_LIST =  "STORE_TASK_LIST"

//FETCH CONTACTS LIST
export const FETCH_CONTACTS_LIST =  "FETCH_CONTACTS_LIST"

//FETCH PROSPECTS LIST
export const FETCH_PROSPECTS_LIST =  "FETCH_PROSPECTS_LIST"

//FETCH CONTACT DETAILS
export const FETCH_CONTACT_DETAILS =  "FETCH_CONTACT_DETAILS"

//ADD CONTACT
export const ADD_CONTACT =  "ADD_CONTACT"

//store the contact mappings 
export const CONTACT_MAPPED_MEMORY_DATA = 'CONTACT_MAPPED_MEMORY_DATA'

//store the download file url of mappedData status 
export const CONTACT_DOWNLOAD_MAPPEDDATA_STATUS_FILE_PATH = 'CONTACT_DOWNLOAD_MAPPEDDATA__STATUS_FILE_PATH' 
//FETCH CONTACT LIST
export const FETCH_CONTACT_LIST =  "FETCH_CONTACT_LIST"

//GET SHARE LINK
export const GET_SHARE_LINK =  "GET_SHARE_LINK"

//GET EMAIL SHORT LINK
export const GET_EMAIL_SHORT_LINK =  "GET_EMAIL_SHORT_LINK"

//GET FB SHORT LINK
export const GET_FB_SHORT_LINK =  "GET_FB_SHORT_LINK"

//GET TW SHORT LINK
export const GET_TW_SHORT_LINK =  "GET_TW_SHORT_LINK"

//GET LN SHORT LINK
export const GET_LN_SHORT_LINK =  "GET_LN_SHORT_LINK"

//GET WH SHORT LINK
export const GET_WH_SHORT_LINK =  "GET_WH_SHORT_LINK"

//get the sub category dropdown  list
export const FETCH_ASSET_LIBRARY_SUBCATEGORY_LIST = "FETCH_ASSET_LIBRARY_SUBCATEGORY_LIST"

//get lead generation dropdown list
export const FETCH_ASSET_LIBRARY_LEAD_GENERATION_LIST = "FETCH_ASSET_LIBRARY_LEAD_GENERATION_LIST"

//set selected sub categories
export const SET_SELECTED_SUBCATEGORIES = "SET_SELECTED_SUBCATEGORIES"

export const UNAUTHORIZED_CRM = "UNAUTHORIZED_CRM"

//store the folder details data
export const SET_FOLDER_DETAILS_DATA = "SET_FOLDER_DETAILS_DATA"

//Store importdata insertId
export const CRM_IMPORT_DATA_INSERT_ID = "CRM_IMPORT_DATA_INSERT_ID"


//all leads type details
export const ALL_LEAD_TYPE_DETAILS = "ALL_LEAD_TYPE_DETAILS"

//clear all lead details
export const CLEAR_ALL_LEAD_TYPE_DETAILS = "CLEAR_ALL_LEAD_TYPE_DETAILS"

// talic active
export const TALIC_ACTIVE = "TALIC_ACTIVE"

// setup category screen
export const CURRENT_URL_PATH = "CURRENT_URL_PATH";

// unauthorize setup
export const UNAUTHORIZED_SETUP = "UNAUTHORIZED_SETUP";

// setup hashtag List
export const FETCH_HASHTAG_LIST = "FETCH_HASHTAG_LIST";

// setup search input
export const SEARCH_INPUT = "SEARCH_INPUT";

// setup current page
export const CURRENT_PAGE = "CURRENT_PAGE";

// setup current sort
export const SORT_VALUE = "SORT_VALUE";

export const SPECIAL_USER = "SPECIAL_USER"; 

export const SET_SCHEMA_CODE = "SET_SCHEMA_CODE";

// setup category List
export const FETCH_CATEGORY_LIST = "FETCH_CATEGORY_LIST"; 

export const FETCH_ASSET_LIBRARY_PRODUCT_LIST = "FETCH_ASSET_LIBRARY_PRODUCT_LIST"; 

export const SET_NAME_ATTR = "SET_NAME_ATTR";

export const SET_ATTR_TYPE = "SET_ATTR_TYPE";

export const SET_SELECTED_VALUE = "SET_SELECTED_VALUE"; 

export const SET_IMAGE = "SET_IMAGE"; 

export const SET_SUB_ID = "SET_SUB_ID"; 

export const SET_PARENT_ID = "SET_PARENT_ID"; 

export const FETCH_CONTENT_LIST = "FETCH_CONTENT_LIST";

export const CONTENT_LIST_DROPDOWN = "CONTENT_LIST_DROPDOWN";

export const SET_DESC_ATTR = "SET_DESC_ATTR";

export const ROUTE_CHANGE_FLAG = "ROUTE_CHANGE_FLAG";

// setup product types

export const FETCH_PRODUCT_TYPE_LIST = "FETCH_PRODUCT_TYPE_LIST";

// setup global search

export const FETCH_GLOBAL_SEARCH_LIST = "FETCH_GLOBAL_SEARCH_LIST";

export const FETCH_GLOBAL_SEARCH_LIST_U = "FETCH_GLOBAL_SEARCH_LIST_U";

// setup business need

export const FETCH_BUSINESS_NEED_LIST= "FETCH_BUSINESS_NEED_LIST"

// setup user management parent

export const FETCH_PARENT_LIST = "FETCH_PARENT_LIST";

export const FETCH_PARENT_CATEGORY_LIST = "FETCH_PARENT__CATEGORY_LIST";

// edit Partner
export const EDIT_PARTNER = "EDIT_PARTNER";

export const ADD_PARTNER = "ADD_PARTNER";

export const BASE_TYPE_PARENT = "BASE_TYPE_PARENT";

// DESIGNATION
export const PARTNER_ID_H = "PARTNER_ID_H";

export const FETCH_DESIGNATION_LIST = "FETCH_DESIGNATION_LIST";

export const FETCH_DESIGNATION_LIST_SUPPORT = "FETCH_DESIGNATION_LIST_SUPPORT";

export const FETCH_BUSINESS_FLAG = "FETCH_BUSINESS_FLAG";

// conversion CTA

export const UNAUTHORIZED_CONVERSION = "UNAUTHORIZED_CONVERSION";

export const SET_SELECTED_VIDEO_TYPE = "SET_SELECTED_VIDEO_TYPE";

export const FETCH_CTA_BUTTON_LIST = "FETCH_CTA_BUTTON_LIST";

export const FETCH_CTA_BUTTON_DROPDOWN = "FETCH_CTA_BUTTON_DROPDOWN";

export const FETCH_CTA_ASSET_LIST = "FETCH_CTA_ASSET_LIST";

export const FETCH_SELECTED_DATA = "FETCH_SELECTED_DATA";

export const FETCH_LEADERBOARD_FLAG = "FETCH_LEADERBOARD_FLAG";

export const FETCH_VIDEO_CTA_FLAG = "FETCH_VIDEO_CTA_FLAG";

//Leaderboard

export const LEADERBOARD_DROPDOWN = "LEADERBOARD_DROPDOWN";

export const FETCH_LEADERBOARD_REPORT = "FETCH_LEADERBOARD_REPORT";

export const LEADERBOARD_LISTING = "LEADERBOARD_LISTING";

export const FETCH_LEVELS_LIST = "FETCH_LEVELS_LIST";

export const BATCH_VALUES = "BATCH_VALUES";

// campaign

export const FETCH_DIRECT_LIST = "FETCH_DIRECT_LIST"; 

export const FETCH_INDIRECT_LIST = "FETCH_INDIRECT_LIST"; 

export const FETCH_LIST_ANALYTICS = "FETCH_LIST_ANALYTICS"; 

export const FETCH_LIST_DATA = "FETCH_LIST_DATA"; 

export const FETCH_PICKUP_LISTING = "FETCH_PICKUP_LISTING"; 

export const CURRENT_TEMPLATE_DATA = "CURRENT_TEMPLATE_DATA";

export const FETCH_FROM_EMAIL = "FETCH_FROM_EMAIL"; 

export const FETCH_REPLY_EMAIL = "FETCH_REPLY_EMAIL"; 

export const INDIVIDUAL_TEMPLATE_DATA = "INDIVIDUAL_TEMPLATE_DATA"; 

export const FETCH_CONTACTS_LIST_CAMPAIGN = "FETCH_CONTACTS_LIST_CAMPAIGN"; 

export const FETCH_CONTENT_LIST_CAMPAIGN = "FETCH_CONTENT_LIST_CAMPAIGN"; 

export const RUN_CAMPAIGN_DATA = "RUN_CAMPAIGN_DATA"; 

export const CAMPAIGN_CURRENT_PAGE = "CAMPAIGN_CURRENT_PAGE"; 

export const CAMPAIGN_SEARCH_INPUT = "CAMPAIGN_SEARCH_INPUT"; 

export const FETCH_INDIVIDUAL_LIST_DATA = "FETCH_INDIVIDUAL_LIST_DATA";

export const SET_SHOW_ENTERY = "SET_SHOW_ENTERY"; 

export const NEW_LIST_ID = "NEW_LIST_ID"; 

export const FETCH_VIEW_LIST_DATA = "FETCH_VIEW_LIST_DATA"; 

export const SELECTED_LIST_ID = "SELECTED_LIST_ID"; 

export const CAMPAIGN_TOGGLE_LOADING = "CAMPAIGN_TOGGLE_LOADING"; 

export const CAMPAIGN_TITLE_STATUS = "CAMPAIGN_TITLE_STATUS"; 

export const FILTERED_CONTACTS_LIST = "FILTERED_CONTACTS_LIST"; 

export const START_UPLOAD = 'START_UPLOAD';

export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';

export const UPLOAD_FAILURE = 'UPLOAD_FAILURE';

export const REMOVE_FAILURE = 'REMOVE_FAILURE';

export const SET_FILES = 'SET_FILES';
// Faq and Benefit

export const FETCH_BENEFIT_LIST = "FETCH_BENEFIT_LIST"; 

export const FETCH_FAQ_LIST = "FETCH_FAQ_LIST"; 

