import React from 'react'
import * as Icon from "react-bootstrap-icons";
import { useState, useRef, useEffect } from "react";
import { Button, InputGroup, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import DragTable from '../shared/DragTable';
import { DebounceInput } from 'react-debounce-input';
import Loading from "../Loading";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../../constants/ActionTypes";
import Pagination from "../shared/ReactPaginate";
import DragShimmer from './shimmer/DragShimmer';
import { fetchCategoryListing, searchInput } from "../../redux/actions/setupActions";


const SetupCategory = (props) => {
    let navigate = useNavigate();
    let isLoading = useSelector((state) => state.setupScreen.loading);
    const dispatch = useDispatch();
    const authToken = props.authToken;
    const total_data = useSelector((state) => state.setupScreen.category_list?.total_data);
    const search_input = useSelector((state) => state.setupScreen.search_input);
    let pageNum = useSelector((state) => state.setupScreen.currPage);
    const [searchValue, setSearchValue] = useState("");
    const category_list = useSelector((state) => state.setupScreen.category_list);
    const add_edit_screen = (condition) => {
      if (condition == "category") {
        navigate("/setup/content/addCategory"); 
      } else if (condition == "benefit") {
        navigate("/setup/content/addBenefit"); 
      } else {
        navigate("/setup/content/addFaq"); 
      } 
    }
    const handleRemoveSearch = (e) => {
        setSearchValue("");
        dispatch(searchInput(""));
        dispatch(fetchCategoryListing(authToken, pageNum, ""));
    }
    const handleSearch = (e) => {
      setSearchValue(e.target.value);
      dispatch(searchInput(e.target.value));
      dispatch(fetchCategoryListing(authToken, 0, e.target.value));
    }
    useEffect(() => {
      // setSearchValue(search_input);
      // dispatch(fetchCategoryListing(authToken, 0, search_input));
      dispatch(fetchCategoryListing(authToken, 0, ""));
    }, []);
  
    useEffect(() => { // onDestroy
        return () => {
          dispatch({ type: types.SEARCH_INPUT, payload: "" });
          dispatch({ type: types.CURRENT_PAGE, payload: 0 });
        }
    }, [])
  
    return (
      <div>
        <div>
          <InputGroup className="mb-3 search-inside">
              <DebounceInput value={searchValue} className="search-inside-input" placeholder="Search Categories" minLength={3} debounceTimeout={2000} onChange={handleSearch} />
              <InputGroup.Text id="basic-addon1">
                {searchValue && searchValue !== "" ? <Icon.X color="#A8A8A8" onClick={handleRemoveSearch} /> : <Icon.Search color="#A8A8A8" onClick={handleSearch}/>}
              </InputGroup.Text>
            </InputGroup>
          <div className='right-buttons'>
            <Button className='common-setup-button' onClick={()=>{add_edit_screen("benefit")}} variant="primary"><span className="plus-size">+</span> Benefits</Button>
            <Button className='common-setup-button mx-2' onClick={()=>{add_edit_screen("faq")}} variant="primary"><span className="plus-size">+</span> Faq</Button>
            <Button className='common-setup-button' onClick={()=>{add_edit_screen("category")}} variant="primary"><span className="plus-size">+</span> Category</Button>
          </div>
        </div>

        <div>
          {isLoading && <DragShimmer subPageName={props.subPageName}/>}
          {category_list?.response?.length > 0 && isLoading === false && <DragTable authToken={authToken} page={pageNum} subPageName={props.subPageName} categoryData={category_list.response} handleRemoveSearch={handleRemoveSearch} />}
          {category_list?.response?.length === 0 && (
            <div className="no-data asset">
              <span>
                <Icon.Inbox />
              </span>
              <br />
              No Data
            </div>
          )}
        </div>
        {total_data > 20 && <Pagination authToken={props.authToken} total_page={ total_data } page={pageNum} currPage={props.subPageName} />}
        {/* <Pagination total_page={ total_data } authToken={props.authToken} page={pageNum} currPage={props.subPageName} /> */}
      </div>
    )
}

export default SetupCategory;