const ArkLoading = () => {
  return (
    <>
      <div className="lds-dual-ring"></div>
      <div className="overlay-bg overlay-white"></div>
    </>
  );
};

export default ArkLoading;
