/* eslint-disable */
import React,{ useState, useEffect } from "react";
import Loading from "../../../Loading";
import { Row, Col, Form, Card, Button, Modal } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import FileInputComponent from "react-file-input-previews-base64";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import MagicStick from "../../../../assets/asset-upload/Vector.png"
import Toast from "../../../shared/Toast";
import $ from "jquery";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAssetSelectTypeList,
  setBreadcrumbStatus,
  setBaseUploadData,
  autoEmailMainData,
  setAutoemailStatus,
  setAutoEmailAccessPath,
  setCurrentAssetDetailNull,
  setTitleStatus,
  setFilesAction,
  uploadFile,
  removeBroucher,
} from "../../../../redux/actions/actions";
import FileUploadComponent from "./FileUploadComponent";

const AddingData = (props) => {
  const authToken = props.authToken;

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [theArray, setTheArray] = useState([]);
  const [actualArray, setActualArray] = useState([]);
  const [arrayCount, setArrayCount] = useState(0);
  const [errorData, setErrorData] = useState(["","",""]);
  const uploadProgress = useSelector((state) => state.assetUpload.uploadProgress);
  // ref
  const inputRef = React.useRef(null);
  const [uploadedData, setUploadedData] = useState({});
  const [callingStatus, setCallingStatus] = useState(false);
  const [emailOption, setEmailOption] = useState("");
  const [confirmationFlag, setConfirmationFlag] = useState(false);
  const [videoUrlsInput, setVideoUrlsInput] = useState([{ video_url: "" }]);
  const [show, setShow] = useState(false);
  const isLoading = useSelector((state) => state.assetUpload.loading);
  const selectedAssetType = useSelector(
    (state) => state.assetUpload.selectedAssetType
  );

  useEffect(() => {
    dispatch(fetchAssetSelectTypeList(authToken));
    dispatch(autoEmailMainData(null));
    dispatch(setCurrentAssetDetailNull(null));
    setConfirmationFlag(false);
    if (_.isEmpty(selectedAssetType)) {
      navigate("/new-asset");
    }
  }, [authToken, dispatch]);

  const handleClose = () => {
    setConfirmationFlag(false);
    setShow(false);
  }
  
  useEffect(() => {
    if (selectedAssetType.type === "Video" && uploadedData.length > 0) {
      if (uploadedData.length > 1) {
        Toast("Please upload single file only.", "E");
        setUploadedData({});
      } else if (!(uploadedData[0].name).includes(".mp4")) {
        Toast("Please upload .mp4 format file only.", "E");
        setUploadedData({});
      } else if (uploadedData[0].size > 15360) {
        setUploadedData({});
        Toast("Maximum 15MB file size accepted.", "E");
      }
    }
    // setGifFlag(false);
    // if ((selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image") && uploadedData?.length > 0) {
    //   uploadedData.map((item,index) => {
    //     return item.type === "image/gif" ? gifValidation(index) : "";
    //   })
    // }
    //  let video = $("#uploadedVideo"); //JQuery selector
    //   console.log("video[0]",video[0]);
    //   console.log("video[0].videoWidth", video[0].videoWidth);
    //   console.log("video[0].videoHeight", video[0].videoHeight); 
    //console.log("uploadedData",uploadedData);
  }, [uploadedData]);

  props.pageTypeHandler("Global Fields");
  
  // const gifValidation = (num) => {
  //   Toast(`GIF format is not supported`, "E");
  //   setGifFlag(true);
  // }

  const videoUrlInputsHandler = (i, e) => {
    let newVideoUrlsInput = [...videoUrlsInput];
    newVideoUrlsInput[i][e.target.name] = e.target.value;
    setVideoUrlsInput(newVideoUrlsInput);
    //setUploadedData(newVideoUrlsInput);
  };

  const addVideoUrlFields = () => {
    setVideoUrlsInput([...videoUrlsInput, { video_url: "" }]);
    //setUploadedData([...videoUrlsInput, { video_url: "" }]);
  };

  const removeVideoUrlFields = (i) => {
    let newVideoUrlsInput = [...videoUrlsInput];
    newVideoUrlsInput.splice(i, 1);
    setVideoUrlsInput(newVideoUrlsInput);
    //setUploadedData(newVideoUrlsInput);
  };

  //console.log("videoUrlsInput", videoUrlsInput);

  const handleFile = (val) => {
    console.log("file", val);
    var reader = new FileReader();
    //var fileType = val[0].file.type;
    //console.log("type", fileType);
    reader.addEventListener("load", function () {
      var dataUrl = reader.result;
      var videoId = "videoMain";
      const videoEl = document.createElement('video');
      videoEl.id = videoId;
      videoEl.src = dataUrl;
      var videoTagRef = videoEl;
      videoTagRef.addEventListener('loadedmetadata', function (e) {
        // console.log("file height", videoTagRef.videoHeight);
        // console.log("file width", videoTagRef.videoWidth);
        // console.log("file duration", videoTagRef.duration);

        val.height = videoTagRef.videoHeight;
        val.width = videoTagRef.videoWidth;

        setUploadedData([val]);
      });

    }, false);

    if (val.file) {
      reader.readAsDataURL(val.file);
    }
  }
  
  const checkUploadCategories = () => {
    const allUploadsComplete = uploadProgress?.every((progress) => progress != false);
    return allUploadsComplete;
  }

  const checkUploadStatus = () => {
    const allUploadsComplete = uploadProgress?.every((progress) => (progress == "complete" || progress == "error"));
    return allUploadsComplete;
  }

  const checkButtonDisable = () => {
    const allUploadsComplete = uploadProgress?.every((progress) => progress == true);
    return allUploadsComplete;
  }
  
  useEffect(() => {
    if (confirmationFlag != false) {
      baseFormatHandler();
    }
  }, [confirmationFlag])
  
  
  const baseFormatEmailHandler = (option) => {
    let uploadBaseFormat;
    uploadBaseFormat = null
    dispatch(setTitleStatus(""));
    dispatch(setAutoEmailAccessPath(0));
    dispatch(setAutoemailStatus(option));
    dispatch(setBreadcrumbStatus(2));
    dispatch(setBaseUploadData(uploadBaseFormat));
    setTimeout(() => {
      navigate(`/new-asset/upload-email-asset`);
    }, 1000);
  }
  
  const handleBroucher = (file_arr) => {
    setUploadedData(file_arr);
  }

  const baseFormatHandler = () => {
    let sum = uploadedData.reduce((accumulator, currentObj) => accumulator + currentObj.file.size, 0);
    console.log("pdfSize",sum);
    if (sum > 10240000 && ( selectedAssetType.type === "Brochure" || selectedAssetType.type === "Infographics" || selectedAssetType.type === "Infographic" )) {
      Toast("Total size of the files must be less than 10 MB.", "E");
      return
    }
    const allUploadsComplete = uploadProgress?.every((progress) => progress === "complete");
    if (confirmationFlag === false && !allUploadsComplete && ( selectedAssetType.type === "Brochure" || selectedAssetType.type === "Infographics" || selectedAssetType.type === "Infographic" )) {
      setShow(true);
      return;
    }
    setConfirmationFlag(false);
    let uploadBaseFormat;
    if (selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image") {
      uploadBaseFormat = {
        data: [
          uploadedData.map((data) => {
            return {
              Base: {
                thumbImg: {},
                post_image: {
                  base64: data.base64,
                  file: {
                    name: data.name.replace(/\s/g, '_'),
                    size: data.size,
                    type: data.type,
                  },
                },
              },
              //Variance: [],
            };
          }),
        ],
      };
    } else if (selectedAssetType.type === "Emailer") {
      uploadBaseFormat = null
      dispatch(setAutoEmailAccessPath(0));
      dispatch(setAutoemailStatus(emailOption));
    } else if (
      selectedAssetType.type === "Brochure" ||
      selectedAssetType.type === "Infographics" ||
      selectedAssetType.type === "Infographic"
    ) {
      uploadBaseFormat = {
        data: [
          uploadedData.map((data) => {
            return {
              Base: {
                thumbImg: {},
                document: {
                  base64: data.base64,
                  file: {
                    name: data.file?.name.replace(/\s/g, '_'),
                    size: data.file?.size,
                    type: data.file?.type,
                  },
                },
              },
            };
          }),
        ],
      };
    } else if (selectedAssetType.type === "Video") {
      if (videoUrlsInput[0].video_url !== "") {
        uploadBaseFormat = {
          data: [
            videoUrlsInput.map((data) => {
              //console.log("data", data);
              return {
                Base: {
                  thumbImg: {},
                  video_url: data.video_url,
                },
              };
            }),
          ],
        };
      } else {
        uploadBaseFormat = {
          data: [
            uploadedData.map((data) => {
              return {
                Base: {
                  thumbImg: {},
                  video: {
                    base64: data.base64,
                    file: {
                      name: data.name.replace(/\s/g, '_'),
                      size: data.size,
                      type: data.type,
                      height: data.height,
                      width: data.width
                    },
                  },
                },
              };
            }),
          ],
        };
      }
    } else if (selectedAssetType.type === "Banner") {
      uploadBaseFormat = {
        data: [
          uploadedData.map((data) => {
            return {
              Base: {
                thumbImg: {},
                banner_image: {
                  base64: data.base64,
                  file: {
                    name: data.name.replace(/\s/g, '_'),
                    size: data.size,
                    type: data.type,
                  },
                },
              },
            };
          }),
        ],
      };
    }
    dispatch(setBreadcrumbStatus(2));
    dispatch(setBaseUploadData(uploadBaseFormat));
    setTimeout(() => {
      navigate(`/new-asset/${selectedAssetType.type !== "Emailer" ? "global-asset-details": emailOption != 2 ? "upload-email-asset":"pick-template"}`);
    }, 1000);
    //console.log("uploadBaseFormat", uploadBaseFormat);
  };
  
  const fileSizeCalc = (number) => {
    let num = number / 1024;
    if (num < 999) {
      return `${Math.ceil(num)}Kb`;
    } else {
      return `${Math.ceil(num / 1024)}Mb`;
    }
  }
  
  useEffect(() => {
    setCallingStatus(false);
    dispatch({ type: "UPLOAD_LOADING", payload: false});
  }, [])
    
  const handleErrorData = (data, index) => {
    let a = errorData;
    a[index] = data;
    setErrorData(a);
  }

  useEffect(() => {
      handleBroucher(theArray);
      handleBroucher(theArray);
      setActualArray(theArray);
      console.log(theArray);
  }, [theArray])
  
  useEffect(() => {
    dispatch(setFilesAction(theArray));
  }, [arrayCount])

  const removedocument = async (objId) => {
    setCallingStatus(false);
    dispatch(removeBroucher(objId));
    const newArray = [...theArray];
    const filteredArray = await newArray.filter((item, ind) => {
      return ind !== objId
    });
    setTheArray(filteredArray);  
  }
    
    // triggers when file is selected with click
  const handleChange = async function (e) {
        e.preventDefault();
        if (e?.target?.files) {
          let a = e.target.files;
          const objArray = await Object.values(a);
          if (objArray?.length > 5 || (objArray?.length + theArray?.length) > 5) {
            Toast("Maximum 5 Documents Allowed", "E");
            return;
          }
          let arrayData = [];
          objArray.map((file,file_index) => {
              let reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                let doc_array;
                doc_array = {
                  base64: reader.result,
                  file: {
                      name: file.name,
                      size: file.size,
                      type: file.type
                  },
                }
                if (doc_array?.file?.size > 10240000) {
                  Toast(`"${doc_array?.file?.name}" file size should be smaller than 10 MB`,"E");
                  return;
                }
                let name = file.name.toUpperCase();
                let charNum = name.lastIndexOf(".");
                if (name.includes(".PDF")) {
                  setTheArray((prevArr) => [...prevArr, doc_array]);
                  arrayData.push(doc_array);
                  setArrayCount(prev => prev + 1);                  
                } else {
                  Toast(`${name.substring(charNum+1)} file not allowed`,"E");
                }
                return arrayData;
              };
          })
          inputRef.current.value = null;
        }
  };
  
  const handleScaning = () => {
    actualArray.map((file,f_ind) => {
      dispatch(uploadFile(props.authToken, file, f_ind, handleErrorData));
    })
  }
  
  useEffect(() => {
    const allSuccess = uploadProgress.every(item => item !== false && item !== true );
    if (allSuccess == true && callingStatus == true) {
      baseFormatHandler();
      setCallingStatus(false);
    }
  }, [uploadProgress])
  
  
  const handleBroucherAction = async () => {
    setCallingStatus(true);
    const allFalse = uploadProgress.every(item => item === false);
    if (allFalse != false) {
      await handleScaning();
    } else {
      await baseFormatHandler();
    }
  }

  console.log("uploadedData", uploadedData);

  return (
    <div className="new-asset">
      {isLoading && <Loading />}
      <Row className="asset-type ">
        <Col className="col-lg-3  col-md-3 col-12 mb-lg-0 mb-4">
          <img src={selectedAssetType.article_image} alt="img" />
          <h1 className="mt-5">
            Add{selectedAssetType.type === "Emailer" ? "ing":""} <br />
            {(selectedAssetType.type === "Email") && (
              <>
                {selectedAssetType.type}
              </>
            )}
            {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image") && (
              <>
                {selectedAssetType.type}
              </>
            )}
            {selectedAssetType.type === "Brochure" && (
              <>
                {selectedAssetType.type} <br /> & Leaflets
              </>
            )}
            {(selectedAssetType.type === "Infographics" || selectedAssetType.type === "Infographic") && (
              <>
              {selectedAssetType.type}
              </>
            )}
            {selectedAssetType.type === "Video" && (
              <>
                {selectedAssetType.type}
              </>
            )}
            {selectedAssetType.type === "Banner" &&
              <>
                {selectedAssetType.type}
              </>
            }
          </h1>
          <h6 className="selectassest-title">{selectedAssetType.description}</h6>
          <span
            className="change-type"
            onClick={() => {
              dispatch(setBreadcrumbStatus(0));
              setTimeout(() => {
                navigate("/new-asset");
              }, 1000);
            }}
          >
            Change asset type
          </span>
        </Col>
        {selectedAssetType.type === "Emailer" ?
          <Col className="col-md-9 cursor-pointer col-12 mb-lg-0 mb-4">
            <Row className="or d-flex card-holder-custom align-item-center justify-content-center">
            {/* <Col
              // style={{width:"32%"}}    
              className={`card-custom col-md-3 cursor-pointer col-12 mb-lg-0 mb-4 ${emailOption === "2" && "active-tab"}`}
              onClick={()=>{setEmailOption("2")}}
            >
              <div>
                <div className="internal-card">
                    <span>Pick a Template</span>
                    <span><img src={MagicStick} alt="magic-stick" /></span>
                </div>
              </div>
            </Col>
              or */}
            <Col
              // style={{width:"32%"}}    
              className={`card-custom col-md-3 cursor-pointer col-12 mb-lg-0 mb-4 ${emailOption === "1" && "active-tab"}`}
              onClick={() => {
                setEmailOption("1")
                baseFormatEmailHandler("1");
              }}
            >
              <div>
                <div className="internal-card">
                    <span>Upload HTML</span>
                    <span><img src={MagicStick} alt="magic-stick" /></span>
                </div>
              </div>
            </Col>
            or
            <Col
              // style={{width:"32%"}}    
              className={`card-custom col-md-3 cursor-pointer col-12 mb-lg-0 mb-4 ${emailOption === "0" && "active-tab"}`}
              onClick={() => {
                setEmailOption("0")
                baseFormatEmailHandler("0");
              }}
            >
              <div>
                <div className="internal-card">
                    <span>Text Email</span>
                    <span><img src={MagicStick} alt="magic-stick" /></span>
                </div>
              </div>
            </Col> 
            </Row>
          </Col>
        :
        <Col
          className={
            selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" || 
              selectedAssetType.type === "Banner"
              ? ""
              : selectedAssetType.type === "Video"
                ? "col-lg-4  col-md-4 col-12 mb-lg-0 mb-4"
                : ""
          }
        >
          <Card className="bulk-upload">
            <Card.Body>
              <Card.Title>
                {/* {selectedAssetType.article_type === "Video"
                  ? "Single File Upload"
                  : "Bulk Upload"}
                <br /> */}
                <Icon.Upload />
              </Card.Title>
              {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image") && (
                <Card.Text>Upload creatives for WhatsApp, Facebook, Twitter and LinkedIn</Card.Text>
              )}
              {(selectedAssetType.type === "Banner") && (
                <Card.Text>Upload microsite banners related to different products and services</Card.Text>
              )}
              {(selectedAssetType.type === "Brochure") && (
                <Card.Text>Upload all product-related brochures in bulk</Card.Text>
              )}
              {(selectedAssetType.type === "Infographics" || selectedAssetType.type === "Infographic") && (
                <Card.Text>Upload content in PDF format </Card.Text>
              )}
              {selectedAssetType.type === "Video" && (
                <Card.Text>Upload a video in mp4 or YouTube link format<br/>
                <strong className="fst-italic" >Note: Please upload a single file only</strong></Card.Text>
              )}
              <div>
                {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                  selectedAssetType.type === "Banner") && (
                    <div className="files-upload-wrapper pdf">
                      <FileInputComponent
                        labelText=""
                        multiple={true}
                        callbackFunction={(file_arr) => {
                          setUploadedData(file_arr);
                        }}
                        // accept="image/*,.pdf,video/*"
                        accept="image/*"
                      />
                    </div>
                  )}
                {(selectedAssetType.type === "Brochure" ||
                  selectedAssetType.type === "Infographics" ||
                  selectedAssetType.type === "Infographic") && (
                    <div className="files-upload-wrapper pdf">
                      {/* <FileInputComponent
                        labelText=""
                        multiple={true}
                        callbackFunction={(file_arr) => {
                          setUploadedData(file_arr);
                        }}
                        // accept="image/*,.pdf,video/*"
                        accept=".pdf"
                      /> */}
                      {/* <FileUploadComponent authToken={authToken} handleBroucher={ handleBroucher } /> */}
                      <>
                        {actualArray && actualArray?.length > 0 && <div className={'w-100 row d-flex justify-content-center'}>
                          <label htmlFor="input-file-upload" className='file-upload' />
                          {actualArray?.length > 0 && actualArray.map((item, index) => {
                            return (
                              <div className='position-relative my-div-bg box col-2 my-0 mx-2 customize-card'>
                                {uploadProgress?.length > 0 && uploadProgress[index] == true ? <p className='overlay-broucher'></p>:""}
                                {uploadProgress?.length > 0 && uploadProgress[index] == true ? <span class="spinner-border text-info overlay-spinner" role="status">
                                </span>:""}
                                <Icon.XLg className='position-absolute cross-pdf cursor-pointer' onClick={(e) => { removedocument(index) }} color='#ffffff' />
                                {(uploadProgress?.length > 0 && (uploadProgress[index] === "complete" || uploadProgress[index] === "error")) && <span title={uploadProgress[index] != "complete" ? errorData[index]:""} className={`${uploadProgress[index] != "complete" ? "folder-tag":"folder-tag-2"} enhance-tag`}></span>}
                                
                                {/* <img className='w-[30px]' title={item.title + " " + fileSizeCalc(item?.doc_url?.file?.size)} src={imageSelector(item.doc_url?.file?.name)} alt="doc" /> */}
                                  <p className='mt-4 px-1'>{item.file?.name?.slice(0, 35) + "..."}</p>
                                  <p className=''>Size:{fileSizeCalc(item?.file?.size)}</p>
                              </div>
                            )
                          })}
                        </div>
                        }  
                              
                        <form id="form-file-upload" className={'file-upload-parent'} onSubmit={(e) => e.preventDefault()}>
                        <input ref={inputRef} accept=".pdf" type="file" id="input-file-upload" className='d-none' multiple={true} onChange={handleChange} />
                          <div>
                            <div className='d-flex'>
                                <label htmlFor="input-file-upload" className='mx-2'>
                                <span className="attach-button">Attach</span>
                                </label>
                                {/* <button
                                  disabled={!(uploadProgress?.length > 0)}
                                  onClick={() => {
                                    handleScaning();
                                  }}
                                  className={`${uploadProgress?.length == 0 && "disable_scan" } attach-button mx-2`}
                                >Scan</button> */}
                            </div>
                          </div> 
                        </form>
                        </>
                    </div>
                  )}
                {selectedAssetType.type === "Video" && (
                  <div className="files-upload-wrapper video">
                    <FileInputComponent
                      className="video-format"
                      labelText=""
                      multiple={false}
                      callbackFunction={(file_arr) => {
                        //setUploadedData(file_arr);
                        handleFile(file_arr);
                      }}
                      // accept="image/*,.pdf,video/*"
                      accept=".mp4"
                    />
                    {/* <video id="uploadedVideo">
                      <source 
                      src={_.isEmpty(uploadedData) ? "" : uploadedData[0].base64}
                      type="video/mp4" />
                    </video> */}
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
        }
        {/* {(selectedAssetType.article_type === "Social Post" ||
          selectedAssetType.article_type === "Banner") && (
          <>
            <Col className="col-1">
              <span>or</span>
            </Col>
            <Col className="col-4">
              <Card className="image-gallery">
                <Card.Body>
                  <Card.Text>Select Images</Card.Text>
                  <Row>
                    <Col>
                      <Form>
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="Search library"
                          className="search-field"
                        />
                        <div className="search-icon">
                          <Icon.Search />
                        </div>
                      </Form>
                    </Col>
                    <Col>
                      <Form>
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="Search Unsplash"
                          className="search-field"
                        />
                        <div className="search-icon">
                          <Icon.Search />
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </>
        )} */}
        {selectedAssetType.type === "Video" && (
          <>
            <Col className="col-lg-1  col-md-6 col-12 mb-lg-0 mb-4 text-center">
              <span className="text-center">or</span>
            </Col>
            <Col className="col-lg-4  col-md-6 col-12 mb-lg-0 mb-4">
              <Card className="video-gallery">
                <Card.Body>
                  <Card.Text>Enter Video URLs</Card.Text>
                  {videoUrlsInput.map((element, index) => (
                    <Row>
                      <Col>
                        <Form>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="video_url"
                            className="video-url-field mb-3"
                            placeholder="Video URL"
                            value={element.video_url || ""}
                            onChange={(e) => videoUrlInputsHandler(index, e)}
                          />
                        </Form>
                        {index ? (
                          <span
                            className="remove-url-field"
                            onClick={() => removeVideoUrlFields(index)}
                          >
                            <Icon.XCircleFill />
                          </span>
                        ) : null}
                      </Col>
                    </Row>
                    // <div className="form-inline" key={index}>
                    //   <label>Name</label>
                    //   <input
                    //     type="text"
                    //     name="name"
                    //     value={element.name || ""}
                    //     onChange={(e) => handleChange(index, e)}
                    //   />
                    //   <label>Email</label>
                    //   <input
                    //     type="text"
                    //     name="email"
                    //     value={element.email || ""}
                    //     onChange={(e) => handleChange(index, e)}
                    //   />
                    //   {index ? (
                    //     <button
                    //       type="button"
                    //       className="button remove"
                    //       onClick={() => removeFormFields(index)}
                    //     >
                    //       Remove
                    //     </button>
                    //   ) : null}
                    // </div>
                  ))}
                  <Row>
                    <Col>
                      <Button
                        variant="info"
                        className="add-more-video"
                        onClick={addVideoUrlFields}
                      >
                        <span>
                          <Icon.Plus />
                        </span>
                        Add More
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col className="col-1">
              <span>or</span>
            </Col>
            <Col className="col-3">
              <Card className="video-gallery">
                <Card.Body>
                  <Card.Text>Select Videos</Card.Text>
                  <Row>
                    <Col>
                      <Form>
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="Search video library"
                          className="search-field"
                        />
                        <div className="search-icon">
                          <Icon.Search />
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col> */}
          </>
        )}
      </Row>
      {(selectedAssetType.type !=="Emailer" && (selectedAssetType.type !== "Brochure" && selectedAssetType.type !== "Infographics" && selectedAssetType.type !== "Infographic")) && <Row className="next-btn custom-row g-0 m-lg-0 mb-4 mx-2">
        <Col className="my-4">
          <Button className="m-lg-0 mx-2 " variant="info" onClick={baseFormatHandler}
            disabled={((_.isEmpty(uploadedData) && (videoUrlsInput[0].video_url === "" && selectedAssetType.type !=="Emailer")) || emailOption == 2 ? true : false)}
          >
            Next
            <span>
              <Icon.ChevronRight />
            </span>
          </Button>
        </Col>
      </Row>}
      
      {(selectedAssetType.type === "Brochure" || selectedAssetType.type === "Infographics" || selectedAssetType.type === "Infographic") && <Row className="next-btn custom-row g-0 m-lg-0 mb-4 mx-2">
        <Col className="my-4">
          <Button className="m-lg-0 mx-2 " variant="info" onClick={handleBroucherAction}
            disabled={checkButtonDisable()}
          >
            Next
            <span>
              <Icon.ChevronRight />
            </span>
          </Button>
        </Col>
      </Row>}
      
      <Modal show={show} onHide={handleClose} size="md" animation="true" centered>
          <Modal.Header closeButton>
              <Modal.Title className='modal-heading-custom'>Alert!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Some of the uploaded PDF(s) contain errors. Would you still like to proceed?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button onClick={()=>{setConfirmationFlag(true)}} className='ml-auto mr-0 common-setup-button w-fit' variant="primary">
              Yes
            </Button>
          </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddingData;
